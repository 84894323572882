import store from "@/store/index";
import axios from "axios";

function add(players, team, matches, training) {
  let jsonData = {
    url: store.state.login.siteurl,
    players: players,
    team: team,
    matches,
    training,
  };

  axios
    .post("api/team_assignment/add", jsonData, {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      // let data = response.data;
      return response.success;
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
}
export default add;
