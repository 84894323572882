import axios from "axios";
import store from "@/store/index";

function load_venues() {
  let jsonData = {
    url: store.state.login.siteurl,
  };
  axios
    .post("api/load_venues", jsonData, {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      let venues = response.data.venues;
      venues.sort(function (a, b) {
        if (a["1"] < b["1"]) {
          return -1;
        }
        if (a["1"] > b["1"]) {
          return 1;
        }

        return 0;
      });

      venues.unshift({ id: "", 1: "" });

      store.state.venues = venues.map((venue) => {
        return { id: venue["id"], name: venue["1"] };
      });
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
}
export default load_venues;
