import "@/assets/colors.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowsRotate,
  faArrowsUpDownLeftRight,
  faCalendarDays,
  faCalendarPlus,
  faCheck,
  faCheckDouble,
  faCircleCheck,
  faCircleQuestion,
  faCircleXmark,
  faClockRotateLeft,
  faCog,
  faCopy,
  faCreditCard,
  faDownload,
  faFilter,
  faPaste,
  faPersonCirclePlus,
  faPlus,
  faRepeat,
  faRightFromBracket,
  faRotateLeft,
  faToggleOff,
  faToggleOn,
  faTrashCan,
  faUpload,
  faUserGroup,
  faUsers,
  faUsersRectangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { createApp } from "vue";
import Vue3Lottie from "vue3-lottie";
import App from "./App.vue";
import router from "./router";
import store from "./store";

library.add(faArrowsUpDownLeftRight);
library.add(faCopy);
library.add(faTrashCan);
library.add(faRotateLeft);
library.add(faUserGroup);
library.add(faRepeat);
library.add(faCheck);
library.add(faCheckDouble);
library.add(faCircleCheck);
library.add(faFilter);
library.add(faPlus);
library.add(faPaste);
library.add(faUpload);
library.add(faDownload);
library.add(faPersonCirclePlus);
library.add(faCalendarPlus);
library.add(faCalendarDays);
library.add(faCreditCard);
library.add(faUsers);
library.add(faUsersRectangle);
library.add(faRightFromBracket);
library.add(faClockRotateLeft);
library.add(faCircleQuestion);
library.add(faCircleXmark);
library.add(faArrowsRotate);
library.add(faToggleOn);
library.add(faToggleOff);
library.add(faCog);

createApp(App)
  .use(store)
  .use(router)

  .use(Vue3Lottie)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
