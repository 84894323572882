<template>
  <div v-if="this.$store.state.uploading" class="uploading">
    <div>
      <Vue3Lottie
        style="height: 150px"
        :animationData="spinner"
        :height="20"
        :width="20"
      />
      <h2>Importing {{ text }}...</h2>
      <h4 v-if="this.$store.state.uploading_percentage != -1">
        {{ this.$store.state.uploading_percentage }}%
      </h4>
    </div>
  </div>
</template>
<script>
import spinner from "@/lotties/bw-spinner.json";

export default {
  name: "UploadingBox",
  props: ["text"],
  data() {
    return {
      spinner,
    };
  },
};
</script>

<style scoped>
.uploading {
  position: absolute;
  top: 0;
  left: 0;
  background: #f4f4f49d;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploading > div {
  background: var(--blue);
  color: white;
  padding: 40px;
  border-radius: 10px;
  opacity: 1;
}

.uploading > div > h2 {
  font-size: larger;
  font-weight: 600;
}
</style>