<template>
  <router-view />
</template>
<script>
import store from "@/store/index";

export default {
  setup() {
    store.dispatch("signInStore");
  },
};
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
body {
  padding: 0;
  margin: 0;
}
#app {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.help a {
  text-decoration: underline;
}

/* visited link */
.help a:visited,
.help a:link {
  color: var(--blue);
  font-weight: 600;
}
</style>
