import md5 from "md5";
import { ref } from "vue";
import { createStore } from "vuex";
export default createStore({
  state: {
    allowedRoles: {
      match: [
        "clubbuzzadmin",
        "fullaccess",
        "clubadministrator",
        "scheduler",
        "selector",
      ],
      team: [
        "clubbuzzadmin",
        "fullaccess",
        "clubadministrator",
        "scheduler",
        "selector",
      ],
      charges: ["clubbuzzadmin", "fullaccess"],
      members: ["clubbuzzadmin", "fullaccess", "clubadministrator"],
      categories: ["clubbuzzadmin", "fullaccess", "clubadministrator"],
    },
    login: {
      logged: false,
      userid: "",
      role: "",
      siteid: "",
      siteurl: "",
      memberArray: [],
      memberSelected: "",
    },
    loginText: "Login",
    one_filter: {
      active: false,
      ageFilter: true,
      age: { from: "0", to: "100" },
      dob: {
        from: "1900-01-01",
        to: `${new Date().getFullYear()}-${String(
          new Date().getMonth() + 1
        ).padStart(2, "0")}-01`,
      },
      gender: "Any",
    },
    two_filter: {
      active: false,
      ageFilter: true,
      age: { from: "0", to: "100" },
      dob: {
        from: "1900-01-01",
        to: `${new Date().getFullYear()}-${String(
          new Date().getMonth() + 1
        ).padStart(2, "0")}-01`,
      },
      gender: "Any",
    },
    member_filter: {
      active: false,
      ageFilter: true,
      age: { from: "0", to: "100" },
      dob: {
        from: "1900-01-01",
        to: `${new Date().getFullYear()}-${String(
          new Date().getMonth() + 1
        ).padStart(2, "0")}-01`,
      },
      gender: "Any",
      category: "No",
    },

    members: [],
    team1_info: {},
    team2_info: {},
    teams: [],
    venues: [],
    finance_categories: [],
    competitions: [],
    results: [
      "",
      "Win",
      "Draw",
      "Loss",
      "Abandoned",
      "Cancelled",
      "Postponed",
      "Walkover",
      "Unreported",
    ],

    // Match
    uploading: false,
    uploading_percentage: 0,
    assigning: false,
    showNew: false,
    showDuplicate: false,

    amountNew: "1",
    matchNew: {
      team: "",
      opp: "",
      date: ref(""),
      time: "",
      location: "",
      venue: { id: "", name: "" },
      comp: "",
    },
    trainingNew: {
      team: "",
      date: ref(""),
      start_time: "",
      end_time: "",
      venue: { id: "", name: "" },
      notes: "",
    },
    // Team
    showTeamSettings: false,
    teamSettings: {
      addMatches: false,
      addTraining: false,
      deleteMatches: false,
      deleteTraining: false,
    },
    // Categories
    categories: [],
    category1_info: {},
    category2_info: {},
    // Members
    memberNew: {
      first: "",
      last: "",
      username: "",
      email: "",
      gender: "",
      dob: ref(""),
    },
    customFields: [],
    // Charge Credit
    chargeNew: {
      members: [],
      type: "",
      amount: "",
      category: "",
      notes: "",
    },
    timeRegex: `(0?[1-9]|1[0-2]):([0-5]\\d)\\s((?:A|P|a|p)\\.?(M|m)\\.?)`,
  },
  getters: {},
  mutations: {
    updateUploadingPercentage(state, percentage) {
      state.uploading_percentage = percentage;
    },
    updateMemberSelected(state, obj) {
      state.login.memberSelected = {
        name: obj.name,
        id: obj.id,
      };
    },

    signOut(state) {
      state.login = {
        logged: false,
        userid: "",
        role: "",
        siteid: "",
        siteurl: "",
        memberArray: [],
        memberSelected: "",
      };
    },

    signIn(state, login) {
      state.login = login;
    },
  },
  actions: {
    updateUploadingPercentage({ commit }, obj) {
      let percentage = (obj.current / obj.total) * 100;
      commit("updateUploadingPercentage", Math.round(percentage));
    },
    updateMemberSelected({ commit, state }, obj) {
      commit("updateMemberSelected", obj);
      localStorage.setItem("login", JSON.stringify(state.login));

      // Create MD5 Hash
      try {
        let confirmation_token = md5(
          JSON.stringify(state.login) + "clubbuzz2-import-md5-salt"
        );
        localStorage.setItem("confirmation_token", confirmation_token);
      } catch (err) {
        console.log("Unable to create session token");
      }

      // localStorage.setItem("confirmation_token", state.token);
    },

    signInStore({ commit, dispatch }) {
      let loginString = localStorage.getItem("login");
      let stored_confirmation_token =
        localStorage.getItem("confirmation_token");
      if (loginString && stored_confirmation_token) {
        let login = JSON.parse(loginString);

        let confirmation_token = md5(loginString + "clubbuzz2-import-md5-salt");

        try {
          // If login.logged, and confirmation token matches
          if (login.logged && confirmation_token == stored_confirmation_token) {
            commit("signIn", login);
            dispatch("updateMemberSelected", {
              id: login.memberSelected.id,
              name: login.memberSelected.name,
            });
          }
        } catch (error) {
          console.log("Unable to sign in");
        }
      }
    },

    signOut({ commit }) {
      commit("signOut");
      localStorage.removeItem("login");
    },
  },
  modules: {},
});
