import axios from "axios";
import store from "@/store/index";

async function uploadMatches(matches) {

    try {
        matches.forEach((match, i) => {
            setTimeout(async() => {
                store.dispatch("updateUploadingPercentage", {
                    current: i,
                    total: matches.length,
                });
                let jsonData = {
                    url: store.state.login.siteurl,
                    match,
                };
                await axios.post("api/matches/add", jsonData, {
                    headers: { "Content-Type": "application/json" },
                });

                if (i == matches.length - 1) {
                    return "Done";
                }
            }, i * 1000);
        });
        return "Done";
    } catch (err) {
        // Handle Error Here
        console.error(err);
        return "Error";
    }
}
export default uploadMatches;