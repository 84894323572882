<template>
  <div id="new_box" v-if="this.$store.state.showNew" class="grid-container">
    <div class="grid-1">
      <h4>Team</h4>
      <div class="teamList">
        <div v-for="(team, i) in this.$store.state.teams" :key="i">
          <input
            type="radio"
            :id="team.id"
            name="fav_language"
            :value="team.id"
            @click="setTeam(team.id)"
          />
          <label :for="team.id">{{ team.name }}</label>
        </div>
      </div>
    </div>
    <div class="grid-2">
      <h4>Date</h4>
      <input
        type="date"
        placeholder="DD/MM/YYYY"
        v-model="this.$store.state.trainingNew.date"
      />
      <h4>Start Time</h4>
      <input
        type="text"
        pattern="(0?[1-9]|1[0-2]):([0-5]\d)\s?((?:A|P)\.?M\.?)"
        placeholder="HH:MM AM/PM"
        v-model="this.$store.state.trainingNew.start_time"
      />
      <h4>End Time</h4>
      <input
        type="text"
        pattern="(0?[1-9]|1[0-2]):([0-5]\d)\s?((?:A|P)\.?M\.?)"
        placeholder="HH:MM AM/PM"
        v-model="this.$store.state.trainingNew.end_time"
      />
    </div>

    <div class="grid-3">
      <h4>Venue</h4>
      <select v-model="this.$store.state.trainingNew.venue">
        <option
          :value="venue"
          v-for="(venue, i) in this.$store.state.venues"
          :key="i"
        >
          {{ venue.name }}
        </option>
      </select>

      <h4>Notes</h4>
      <input
        type="text"
        placeholder="Notes"
        v-model="this.$store.state.trainingNew.notes"
      />



      <h4>Amount of Sessions</h4>
      <input
        type="number"
        name=""
        id=""
        v-model="this.$store.state.amountNew"
        min="1"
        max="100"
      />
    </div>
    <div class="grid-4">
      <div class="buttons">
        <button class="create" @click="handleNewCreate">Create</button>
        <button class="cancel" @click="handleNew">Cancel</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NewTrainingBox",
  props: ["handleNew", "handleNewCreate"],
  data() {
    return {};
  },
  methods: {
    setTeam(id) {
      this.$store.state.trainingNew.team = id;
    },
  },
};
</script>

<style scoped>
#new_box h4 {
  text-align: left;
  font-size: large;
  font-weight: bold;
  margin: 10px 0px 5px;
}

#new_box .buttons {
  width: 100%;
  padding: 10px 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.buttons button {
  width: 100px;
  height: 40px;
  color: white;
  border-radius: 5px;
  font-size: large;
  margin: 10px;
}

.buttons .create {
  background: var(--blue);
}

.buttons .cancel {
  background: #c9c9c9;
}

.grid-1 {
  grid-area: left;
}

.teamList {
  max-height: 300px;
  text-align: left;
  overflow-y: scroll;
}
.grid-2 {
  grid-area: middle;
}
.grid-3 {
  grid-area: right;
}
.grid-4 {
  grid-area: footer;
  display: flex;
}

.grid-container {
  position: absolute;
  z-index: 100;
  display: grid;
  grid-template-areas:
    "left middle right"
    "footer footer footer";
  gap: 10px;
  background-color: var(--grey);
  border-radius: 5px;
  text-align: left;
  padding: 10px;
  margin: 60px 30px;
}

.grid-container > div {
  text-align: center;
  padding: 0px 5px;
}

.grid-container input[type="text"],
.grid-container input[type="number"],
.grid-container input[type="date"],
.grid-container select {
  -webkit-appearance: none;
  width: 230px;
  /* height: 30px; */
  border-radius: 5px;
  border: 1px solid rgb(209 213 219);
  padding: 0.5rem;
  font-size: 0.875rem;
}
</style>