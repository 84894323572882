<template>
  <div id="new_box" v-if="this.$store.state.showNew" class="grid-container">
    <div class="grid-1">
      <h4>First Name</h4>
      <input type="text" v-model="this.$store.state.memberNew.first" />
      <h4>Last Name</h4>
      <input type="text" v-model="this.$store.state.memberNew.last" />
    </div>
    <div class="grid-2">
      <h4>Username</h4>
      <input type="text" v-model="this.$store.state.memberNew.username" />
      <h4>Email</h4>
      <input type="text" v-model="this.$store.state.memberNew.email" />
    </div>

    <div class="grid-3">
      <h4>Gender</h4>
      <select v-model="this.$store.state.memberNew.gender">
        <option value=""></option>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
        <option value="Other">Other</option>
      </select>
      <h4>Date of Birth</h4>
      <input
        type="date"
        placeholder="DD/MM/YYYY"
        v-model="this.$store.state.memberNew.dob"
      />
    </div>
    <div class="grid-4">
      <div class="buttons">
        <button class="create" @click="handleNewCreate">Create</button>
        <button class="cancel" @click="handleNew">Cancel</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NewMemberBox",
  props: ["handleNew", "handleNewCreate"],
  data() {
    return {};
  },
};
</script>

<style scoped>
#new_box h4 {
  text-align: left;
  font-size: large;
  font-weight: bold;
  margin: 10px 0px 5px;
}

#new_box .buttons {
  width: 100%;
  padding: 10px 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.buttons button {
  width: 100px;
  height: 40px;
  color: white;
  border-radius: 5px;
  font-size: large;
  margin: 10px;
}

.buttons .create {
  background: var(--blue);
}

.buttons .cancel {
  background: #c9c9c9;
}

.grid-1 {
  grid-area: left;
}

.teamList {
  max-height: 300px;
  text-align: left;
  overflow-y: scroll;
}
.grid-2 {
  grid-area: middle;
}
.grid-3 {
  grid-area: right;
}
.grid-4 {
  grid-area: footer;
  display: flex;
}

.grid-container {
  position: absolute;
  z-index: 100;
  display: grid;
  grid-template-areas:
    "left middle right"
    "footer footer footer";
  gap: 10px;
  background-color: var(--grey);
  border-radius: 5px;
  text-align: left;
  padding: 10px;
  margin: 60px 30px;
}

.grid-container > div {
  text-align: center;
  padding: 0px 5px;
}

.grid-container input[type="text"],
.grid-container input[type="number"],
.grid-container input[type="date"],
.grid-container select {
  -webkit-appearance: none;
  width: 230px;
  /* height: 30px; */
  border-radius: 5px;
  border: 1px solid rgb(209 213 219);
  padding: 0.5rem;
  font-size: 0.875rem;
}
</style>