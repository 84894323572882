<template>
  <main>
    <div class="contain">
      <div
        class="select-user"
        v-if="
          this.$store.state.login.memberArray.length > 1 &&
          !this.$store.state.login.memberSelected.id
        "
      >
        <div>
          <img src="@/assets/logo.png" alt="" />
          <p>Which member would you like to login as?</p>
        </div>
        <div class="choose">
          <div
            v-for="member in memberArray"
            :key="member.id"
            @click="choose(member.id, member.name)"
          >
            {{ member.name }}
          </div>
        </div>
      </div>
      <div
        class="select-site"
        v-else-if="
          typeof this.$store.state.login.siteid == 'object' &&
          this.$store.state.login.siteid.length > 1
        "
      >
        <div>
          <img src="@/assets/logo.png" alt="" />
          <p>Which site would you like to login to?</p>
        </div>
        <div class="choose">
          <div
            v-for="site in siteArray()"
            :key="site.id"
            @click="chooseSite(site.siteurl, site.userblog_id, site.role)"
          >
            {{ site.blogname }}
          </div>
        </div>
      </div>
      <div v-else>
        <div class="welcome">
          <img src="@/assets/logo.png" alt="" />
          <p>Welcome to ClubBuzz.</p>
        </div>
        <form @submit.prevent="login()">
          <input
            type="text"
            placeholder="Username"
            @keypress="reset()"
            v-model="username"
          />
          <input
            type="password"
            placeholder="Password"
            @keypress="reset()"
            v-model="password"
          />
          <button type="submit">{{ this.$store.state.loginText }}</button>
        </form>
      </div>
    </div>
  </main>
</template>
<script>
import loginAttempt from "@/login";
import loginToSite from "@/loginSpecificSite";

import router from "@/router/index";

import { onBeforeMount } from "vue";
import checkHash from "@/checkHash";

export default {
  name: "LoginView",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  computed: {
    memberArray() {
      return this.$store.state.login.memberArray;
    },
  },
  methods: {
    siteArray() {
      if (typeof this.$store.state.login.siteid == "object") {
        return this.$store.state.login.siteid;
      }
      return [{ id: "yo", name: "yo" }];
    },
    reset() {
      if (this.$store.state.loginText != "Login") {
        this.$store.state.loginText = "Login";
      }
    },
    login() {
      if (this.username.trim().length < 4) {
        this.$store.state.loginText = "Incorrect username or password";

        return;
      }
      if (this.password.trim().length < 2) {
        this.$store.state.loginText = "Incorrect username or password";
        return;
      }

      loginAttempt(this.username, this.password);
    },
    choose(id, name) {
      this.$store.dispatch("updateMemberSelected", {
        name: name,
        id: id,
      });
      this.$store.state.login.logged = true;

      router.push("/");
    },
    chooseSite(siteurl, blogid, role) {
      loginToSite(siteurl, this.$store.state.login.userid);
      this.$store.state.login.role = role;
      this.$store.state.login.siteid = blogid;
      this.$store.state.login.siteurl = siteurl;
    },
  },
  created() {
    onBeforeMount(async () => {
      let query = this.$route.query;
      if (!this.$store.state.login.logged) {
        router.replace("/login");
        if (query.cb && query.hash && query.userid && query.siteurl) {
          this.predictedArray = "Loading";
          let object = await checkHash(query.hash, query.userid, query.siteurl);
          if (object.signin) {
            this.predictedArray = object.predictedArray;
            // We can show the sign in button to the user now. Fill store with Predict user, does not require password.
          }
        }
      } else {
        router.replace("/");
      }
    });
  },
};
</script>
<style scoped>
main {
  height: 100vh;
  width: 100vw;
  background: #00000015;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.contain {
  background: white;
  margin-top: -5%;
  padding: 40px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
.welcome img {
  width: 100px;
}
.welcome p {
  font-size: 1.9em;
  color: #3e5293;
  font-weight: 600;
  width: 300px;
  text-align: center;
  line-height: 1.2em;
}

main form {
  display: flex;
  flex-direction: column;
}
form input {
  border: 0;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.02);
  margin: 5px;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 600;
}

form button {
  cursor: pointer;
  border: 0;
  padding: 10px 20px;
  background: #3e5293;
  margin: 5px;
  border-radius: 5px;
  font-size: 1em;
  color: white;
  font-weight: 600;
}

form button:hover {
  opacity: 0.7;
}

.select-user > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
.select-user img {
  width: 90px;
  padding-bottom: 10px;
}
.select-user p {
  font-size: 1.5em;
  color: #3e5293;
  font-weight: 600;
  width: 300px;
  text-align: center;
  line-height: 1.2em;
}
.select-site > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
.select-site img {
  width: 90px;
  padding-bottom: 10px;
}
.select-site p {
  font-size: 1.5em;
  color: #3e5293;
  font-weight: 600;
  width: 300px;
  text-align: center;
  line-height: 1.2em;
}

.choose {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.choose div {
  color: #3e5293;
  min-width: 250px;
  padding: 5px;
  background: #ebedef;
  margin: 5px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}

.choose div:hover {
  opacity: 0.6;
}
</style>
