<template>
  <div class="contain">
    <div class="menu">
      <router-link to="/">
        <img src="@/assets/logo.png" alt="" />
      </router-link>
      <div
        class="icon"
        :class="{ activated: checkedPlayers.length > 0 }"
        @click="handleInitialMove"
      >
        {{ this.moveText }}
        <font-awesome-icon
          style="margin-left: 4px"
          icon="arrows-up-down-left-right"
        />
      </div>
      <div
        class="icon"
        :class="{ activated: checkedPlayers.length > 0 }"
        @click="handleInitialCopy"
      >
        {{ this.copyText }}
        <font-awesome-icon style="margin-left: 4px" icon="copy" />
      </div>
      <div
        class="icon"
        :class="{
          activated: showDelete || checkedPlayers.length > 0,
          hovering: hoverDelete,
        }"
        @dragenter="handleDragEnter_delete"
        @dragleave="handleDragLeave_delete"
        @dragover="handleDragOver"
        @drop="handleDrop_delete"
        @click="handleDelete"
      >
        Delete
        <font-awesome-icon style="margin-left: 4px" icon="trash-can" />
      </div>
      <div
        class="icon"
        :class="{ activated: lastAction.players.length != 0 }"
        @click="handleUndo"
      >
        Undo
        <font-awesome-icon style="margin-left: 4px" icon="rotate-left" />
      </div>
      <div
        class="icon members"
        :class="{ activated: showMembers }"
        @click="handleMembers"
      >
        Members
        <font-awesome-icon icon="user-group" />
      </div>

      <div
        class="icon selection"
        :class="{ activated: this.checkedPlayers.length > 0 }"
        @click="uncheckAll"
      >
        <span id="select">{{ this.checkedPlayers.length }} Selected</span>
        <span id="deselect">Deselect all</span>
        <font-awesome-icon icon="check" />
      </div>


      <div
        class="icon activated"
        :class="{on: this.anySettingOn}"
        @click="handleSettings"
      >
        <font-awesome-icon icon="cog" />
      </div>

    </div>
    <TeamSettingsBox :handleSettings="handleSettings" />
    <div class="team-bar">
      <div
        class="team"
        :class="{
          activated:
            this.$store.state.team1_info.name == team.name ||
            this.$store.state.team2_info.name == team.name,
          pick: moveText != 'Move' || copyText != 'Copy',
        }"
        v-for="(team, i) in this.$store.state.teams"
        :key="i"
        @click="handleTeamClick(team)"
      >
        {{ team.name }}
      </div>
    </div>

    <UploadingBox text="Team Assignments" />

    <div class="canvas">
      <div
        class="empty"
        v-if="
          !this.$store.state.team1_info.name &&
          !this.$store.state.team2_info.name &&
          !showMembers
        "
      >
        Welcome to Team Assignment <br />
        <span>Select a team from the team bar at the top</span>
      </div>
      <div
        class="team"
        :id="this.$store.state.team1_info.id"
        @dragenter="handleDragEnter"
        @dragleave="handleDragLeave"
        @dragover="handleDragOver"
        @drop="handleDrop"
        v-if="this.$store.state.team1_info.name"
      >
        <div class="action_bar">
          <h2>{{ this.$store.state.team1_info.name }}</h2>
          <div class="actions">
            <font-awesome-icon
              style="margin-left: 10px"
              icon="circle-check"
              size="lg"
              @click="selectAll(this.$store.state.team1_info.id)"
            />
            <font-awesome-icon
              style="margin-left: 10px"
              icon="filter"
              size="lg"
              :class="{
                on: team1Filter,
              }"
              @click="handleFilter('1')"
            />
            <FilterBox v-if="show1Filter" type="1" />
          </div>
        </div>

        <div class="players">
          <p v-if="this.$store.state.team1_info.players.length == 0">
            This team has no players
          </p>
          <div
            class="player"
            v-for="(player, i) in this.$store.state.team1_info.players"
            :key="i"
            :id="player.id"
            draggable="true"
            @dragstart="handleDragStart"
            @dragend="handleDragEnd"
            @dragover="handleDragOver"
          >
            <label
              class="container"
              :class="{ hide: filter_match(player, '1') }"
              >{{ player.name }}
              <input type="checkbox" @change="singleCheck($event)" />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <p class="amount">
          {{ this.$store.state.team1_info.players.length }} players
        </p>
      </div>

      <div
        class="team"
        @dragenter="handleDragEnter"
        @dragleave="handleDragLeave"
        @dragover="handleDragOver"
        @drop="handleDrop"
        :id="this.$store.state.team2_info.id"
        v-if="this.$store.state.team2_info.name"
      >
        <div class="action_bar">
          <h2>{{ this.$store.state.team2_info.name }}</h2>
          <div class="actions">
            <font-awesome-icon
              style="margin-left: 10px"
              icon="circle-check"
              size="lg"
              @click="selectAll(this.$store.state.team2_info.id)"
            />
            <font-awesome-icon
              style="margin-left: 10px"
              icon="filter"
              size="lg"
              :class="{ on: team2Filter }"
              @click="handleFilter('2')"
            />
            <FilterBox v-if="show2Filter" type="2" />
          </div>
        </div>
        <div class="players">
          <p v-if="this.$store.state.team2_info.players.length == 0">
            This team has no players
          </p>
          <div
            class="player"
            v-for="(player, i) in this.$store.state.team2_info.players"
            :key="i"
            :id="player.id"
            draggable="true"
            @dragstart="handleDragStart"
            @dragend="handleDragEnd"
          >
            <label
              class="container"
              :class="{ hide: filter_match(player, '2') }"
              >{{ player.name }}
              <input type="checkbox" @change="singleCheck($event)" />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <p class="amount">
          {{ this.$store.state.team2_info.players.length }} players
        </p>
      </div>

      <div class="team" id="members" v-if="showMembers">
        <div class="action_bar">
          <h2>Members</h2>
          <div class="actions">
            <font-awesome-icon
              style="margin-left: 10px"
              icon="circle-check"
              size="lg"
              @click="selectAll('members')"
            />
            <font-awesome-icon
              style="margin-left: 10px"
              icon="filter"
              size="lg"
              :class="{ on: memberFilter }"
              @click="handleFilter('members')"
            />
            <FilterBox v-if="showMemberFilter" type="members" />
          </div>
        </div>
        <div class="players">
          <div
            class="player"
            v-for="(player, i) in this.$store.state.members"
            :key="i"
            :id="player.id"
            draggable="true"
            @dragstart="handleDragStart"
            @dragend="handleDragEnd"
          >
            <label
              class="container"
              :class="{ hide: filter_match(player, 'member') }"
              >{{ player.name }}
              <input type="checkbox" @change="singleCheck($event)" />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FilterBox from "@/components/FilterBox.vue";
import TeamSettingsBox from "@/components/TeamSettingsBox.vue";
import UploadingBox from "@/components/UploadingBox.vue";

import load_members from "@/requests/load_members";
import load_teams from "@/requests/load_teams";

import add from "@/requests/team_assignment/AddToTeam";
import remove from "@/requests/team_assignment/RemoveFromTeam";

import router from "@/router/index";
import { onBeforeMount } from "vue";

export default {
  name: "TeamAssignmentView",
  components: {
    FilterBox,
    TeamSettingsBox,
    UploadingBox,
  },
  data() {
    return {
      member_filter: {
        change: 0,
      },
      teamDragging: "",
      source: "",
      showMembers: false,
      showDelete: false,
      hoverDelete: false,
      lastAction: { type: "", players: [], from: "", to: "" },
      checkedPlayers: [],
      moveText: "Move",
      copyText: "Copy",
      showMemberFilter: false,
      show1Filter: false,
      show2Filter: false,
      addToMatches: false,
      addToTraining: false,
    };
  },
  computed: {
    anySettingOn() {
      return this.$store.state.teamSettings.addMatches || this.$store.state.teamSettings.addTraining || this.$store.state.teamSettings.deleteMatches || this.$store.state.teamSettings.deleteTraining;
    },
    memberFilter() {
      return this.$store.state.member_filter.active;
    },
    team1Filter() {
      return this.$store.state.one_filter.active;
    },
    team2Filter() {
      return this.$store.state.two_filter.active;
    },
    team1_players() {
      if (this.$store.state.team1_info.name) {
        return this.$store.state.team1_info.players.length;
      } else {
        return 0;
      }
    },
    team2_players() {
      if (this.$store.state.team2_info.name) {
        return this.$store.state.team2_info.players.length;
      } else {
        return 0;
      }
    },
  },
  methods: {
    handleSettings() {
      this.$store.state.showTeamSettings = !this.$store.state.showTeamSettings;
    },
    toggleAddToMatches() {
      this.addToMatches = !this.addToMatches
    },
    toggleAddToTraining() {
      this.addToTraining = !this.addToTraining
    },
    filter_match(player, team) {
      // Check Gender

      var filter;
      if (team == "1") {
        filter = this.$store.state.one_filter;
      }

      if (team == "2") {
        filter = this.$store.state.two_filter;
      }

      if (team == "member") {
        filter = this.$store.state.member_filter;
      }

      if (filter.active) {
        if (filter.gender != "Any") {
          if (filter.gender != player.gender) {
            return true;
          }
        }

 
        // Get age from dob
        var dob;
        if (player.dob.includes("/")) {
          var dateParts = player.dob.split("/");
          // month is 0-based, that's why we need dataParts[1] - 1
          dob = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        } else {
          dob = new Date(player.dob);
        }
       if (filter.ageFilter) {
         var diff_ms = Date.now() - dob;
         let age_dt = new Date(diff_ms);
         let age = Math.abs(age_dt.getUTCFullYear() - 1970);
         // Check if age is in range
         if (age < filter.age.from || age > filter.age.to) {
           return true;
         }
       } else {
          // Dob Filter
        if (isNaN(dob.getTime())) {
          return true;
        }

         if (dob < new Date(filter.dob.from) || dob > new Date(filter.dob.to)) {
           return true;
         } 
        }
      }

      return false;
    },
    singleCheck(e) {
      let target = e.target;
      let player_id = target.closest(".player").id.trim();
      let player = this.$store.state.members.find(
        (member) => member.id == player_id
      );

      let player_obj = {
        id: player_id,
        name: player.name,
        dob: player.dob,
        gender: player.gender,
      };

      let team = target.closest(".team").id.trim();
      if (target.checked) {
        this.checkedPlayers.push({
          team: team,
          player: player_obj,
        });
      } else {
        let index = this.checkedPlayers.findIndex(
          (value) => player_obj == value.player && team == value.team
        );
        this.checkedPlayers.splice(index, 1);
      }
    },
    logLastAction(type, players) {
      this.lastAction = { type: type, players: players };
    },
    selectAll(id) {
      document
        .getElementById(id)
        .querySelectorAll(`.player .container:not(.hide)`)
        .forEach((container) => {
          let checkbox = container.parentElement.querySelector(
            "input[type='checkbox']"
          );
          if (checkbox) {
            checkbox.checked = true;
          }
        });

      document
        .getElementById(id)
        .querySelectorAll(`.player .container:not(.hide)`)
        .forEach((container) => {
          let player_id = container.parentElement.id.trim();
          let player = this.$store.state.members.find(
            (member) => member.id == player_id
          );

          let player_obj = {
            id: player_id,
            name: player.name,
            dob: player.dob,
            gender: player.gender,
          };

          if (
            !this.checkedPlayers.some(
              ({ team, player }) => player.id == player_id && team == id
            )
          ) {
            this.checkedPlayers.push({
              team: id,
              player: player_obj,
            });
          }
        });
    },
    uncheckAll() {
      this.checkedPlayers = [];
      document
        .querySelectorAll("input[type=checkbox]")
        .forEach((el) => (el.checked = false));
    },
    handleFilter(box) {
      switch (box) {
        case "1":
          this.show1Filter = !this.show1Filter;
          break;
        case "2":
          this.show2Filter = !this.show2Filter;
          break;
        default:
          this.showMemberFilter = !this.showMemberFilter;
          break;
      }
      // Show Filter Box
    },
    handleInitialMove() {
      // Select a team
      if (this.checkedPlayers.length == 0) return;
      // Make all teams a new color
      // Change move text
      this.copyText = "Copy";
      this.moveText = "Pick a team";
    },
    handleMove(team) {
      this.moveText = "Move";
      this.$store.state.uploading = true;
      let index = this.$store.state.teams.findIndex(
        (search) => team.id == search.id
      );
      this.checkedPlayers.forEach(
        this.delayLoop(
          (value) => {
            if (
              !this.$store.state.teams[index].players.some(
                ({ id }) => id == value.player.id
              )
            ) {
              add([value.player.id], this.$store.state.teams[index], this.$store.state.teamSettings.addMatches, this.$store.state.teamSettings.addTraining);
              this.$store.state.teams[index].players.push(value.player);

              if (value.team != "members") {
                let originalIndex = this.$store.state.teams.findIndex(
                  (search) => value.team == search.id
                );

                remove(
                  [value.player.id],
                  this.$store.state.teams[originalIndex].id, this.$store.state.teamSettings.deleteMatches, this.$store.state.teamSettings.deleteTraining
                );
                this.$store.state.teams[originalIndex].players =
                  this.$store.state.teams[originalIndex].players.filter(
                    (filterValue) => filterValue.id != value.player.id
                  );
              }
            }
          },
          1000,
          this.checkedPlayers.length
        )
      );

      let logPlayers = this.checkedPlayers.map((x) => ({
        player: x.player,
        from: x.team,
        to: team.id,
      }));

      this.logLastAction("move", logPlayers);

      this.uncheckAll();
    },
    handleInitialCopy() {
      // Select a team
      if (this.checkedPlayers.length == 0) return;
      // Make all teams a new color
      // Change move text
      this.moveText = "Move";
      this.copyText = "Pick a team";
    },
    handleCopy(team) {
      this.copyText = "Copy";
      this.$store.state.uploading = true;

      let index = this.$store.state.teams.findIndex(
        (search) => team.id == search.id
      );
      this.checkedPlayers.forEach(
        this.delayLoop(
          (value) => {
            if (
              !this.$store.state.teams[index].players.some(
                ({ id }) => id == value.player.id
              )
            ) {
              add([value.player.id], this.$store.state.teams[index], this.$store.state.teamSettings.addMatches, this.$store.state.teamSettings.addTraining);
              this.$store.state.teams[index].players.push(value.player);
            }
          },
          1000,
          this.checkedPlayers.length
        )
      );

      let logPlayers = this.checkedPlayers.map((x) => ({
        player: x.player,
        from: x.team,
        to: team.id,
      }));

      this.logLastAction("copy", logPlayers);

      this.uncheckAll();
    },
    handleDelete() {
      this.$store.state.uploading = true;

      this.checkedPlayers.forEach(
        this.delayLoop(
          (value) => {
            if (value.team != "members") {
              let originalIndex = this.$store.state.teams.findIndex(
                (search) => value.team == search.id
              );

              remove(
                [value.player.id],
                this.$store.state.teams[originalIndex].id, this.$store.state.teamSettings.deleteMatches, this.$store.state.teamSettings.deleteTraining
              );
              this.$store.state.teams[originalIndex].players =
                this.$store.state.teams[originalIndex].players.filter(
                  (filterValue) => filterValue.id != value.player.id
                );
            }
          },
          1000,
          this.checkedPlayers.length
        )
      );

      let logPlayers = this.checkedPlayers.map((x) => ({
        player: x.player,
        from: x.team,
        to: "",
      }));
      this.logLastAction("delete", logPlayers);

      this.uncheckAll();
    },
    handleUndo() {
      switch (this.lastAction.type) {
        case "move":
          this.$store.state.uploading = true;
          this.lastAction.players.forEach(
            this.delayLoop(
              (player) => {
                if (player.from != "members") {
                  var ogIndex = this.$store.state.teams.findIndex(
                    (team) => team.id == player.from
                  );

                  add([player.player.id], this.$store.state.teams[ogIndex], this.$store.state.teamSettings.addMatches, this.$store.state.teamSettings.addTraining);
                  this.$store.state.teams[ogIndex].players.push(player.player);
                }

                var teamIndex = this.$store.state.teams.findIndex(
                  (team) => team.id == player.to
                );

                let index = this.$store.state.teams[
                  teamIndex
                ].players.findIndex((item) => player.player == item);

                if (index != -1) {
                  remove(
                    [this.$store.state.teams[teamIndex].players[index].id],
                    this.$store.state.teams[teamIndex].id, this.$store.state.teamSettings.deleteMatches, this.$store.state.teamSettings.deleteTraining
                  );
                  this.$store.state.teams[teamIndex].players.splice(index, 1);
                }
              },
              1000,
              this.lastAction.players.length
            )
          );

          this.lastAction = { type: "", players: [], from: "", to: "" };
          break;
        case "copy":
          this.$store.state.uploading = true;
          this.lastAction.players.forEach(
            this.delayLoop(
              (player) => {
                var teamIndex = this.$store.state.teams.findIndex(
                  (team) => team.id == player.to
                );

                let index = this.$store.state.teams[
                  teamIndex
                ].players.findIndex((item) => player.player == item);

                if (index != -1) {
                  remove(
                    [this.$store.state.teams[teamIndex].players[index].id],
                    this.$store.state.teams[teamIndex].id, this.$store.state.teamSettings.deleteMatches, this.$store.state.teamSettings.deleteTraining
                  );
                  this.$store.state.teams[teamIndex].players.splice(index, 1);
                }
              },
              1000,
              this.lastAction.players.length
            )
          );

          this.lastAction = { type: "", players: [], from: "", to: "" };
          break;
        case "delete":
          this.$store.state.uploading = true;
          this.lastAction.players.forEach(
            this.delayLoop(
              (player) => {
                var deletedTeamIndex = this.$store.state.teams.findIndex(
                  (team) => team.id == player.from
                );
                add(
                  [player.player.id],
                  this.$store.state.teams[deletedTeamIndex],
                  this.$store.state.teamSettings.addMatches, this.$store.state.teamSettings.addTraining
                );
                this.$store.state.teams[deletedTeamIndex].players.push(
                  player.player
                );
              },
              1000,
              this.lastAction.players.length
            )
          );
          this.lastAction = { type: "", players: [], from: "", to: "" };

          break;
        default:
          break;
      }
    },
    handleMembers() {
      this.showMembers = !this.showMembers;
    },
    handleTeamClick(team) {
      if (this.moveText != "Move") {
        this.handleMove(team);
      } else if (this.copyText != "Copy") {
        this.handleCopy(team);
      } else {
        this.activateTeam(team);
      }
    },
    // Activate Team
    activateTeam(team) {
      if (this.$store.state.team1_info == team) {
        this.$store.state.team1_info = {};
        return;
      }

      if (this.$store.state.team2_info == team) {
        this.$store.state.team2_info = {};
        return;
      }

      if (this.$store.state.team1_info.name) {
        this.$store.state.team2_info = team;
        return;
      } else {
        this.$store.state.team1_info = team;
      }
    },
    // Drag and Drop
    handleDragStart(e) {
      // var dragIcon = new Image();
      // switch (this.checkedPlayers.length) {
      //   case 0:
      //     dragIcon.src = require("@/assets/Untitled-1.png");
      //     break;
      //   case 1:
      //     dragIcon.src = require("@/assets/Untitled-1.png");
      //     break;
      //   case 2:
      //     dragIcon.src = require("@/assets/Untitled-2.png");
      //     break;
      //   case 3:
      //     dragIcon.src = require("@/assets/Untitled-3.png");
      //     break;
      //   case 4:
      //     dragIcon.src = require("@/assets/Untitled-4.png");
      //     break;
      //   case 5:
      //     dragIcon.src = require("@/assets/Untitled-5.png");
      //     break;
      //   default:
      //     dragIcon.src = require("@/assets/Untitled-6.png");
      //     break;
      // }

      // dragIcon.src = require("@/assets/Untitled-1.png");
      // e.dataTransfer.setDragImage(dragIcon, 50, 50);

      let source = e.srcElement;
      source.style.opacity = 0.4;
      this.teamDragging = source.closest(".team").id;
      if (this.teamDragging != "members") {
        this.showDelete = true;
      }

      let player = this.$store.state.members.find(
        (member) => member.id == source.id
      );

      let player_obj = {
        id: source.id,
        name: player.name,
        dob: player.dob,
        gender: player.gender,
      };

      this.source = player_obj;
      e.dataTransfer.effectAllowed = "move";
    },

    handleDragEnd(e) {
      let source = e.srcElement;
      source.style.opacity = 1;

      this.showDelete = false;
      this.hoverDelete = false;
      for (let elem of document.getElementsByClassName("over")) {
        elem.classList.remove("over");
      }
    },

    handleDragOver(e) {
      e.preventDefault();
      return false;
    },

    handleDragEnter(e) {
      let target = e.target;
      let parentWithClass = target.closest(".team");
      if (parentWithClass.id != this.teamDragging) {
        parentWithClass.classList.add("over");
      }
    },

    handleDragLeave(e) {
      let target = e.target;
      target.classList.remove("over");
    },
    handleDrop(e) {
      e.stopPropagation(); // stops the browser from redirecting.
      let target = e.target;

      let parentWithClass = target.closest(".team");
      if (parentWithClass.id != this.teamDragging) {
        if (parentWithClass.id == this.$store.state.team1_info.id) {
          if (
            !this.$store.state.team1_info.players.some(
              ({ id }) => id == this.source.id
            )
          ) {
            add([this.source.id], this.$store.state.team1_info, this.$store.state.teamSettings.addMatches, this.$store.state.teamSettings.addTraining);
            this.$store.state.team1_info.players.push(this.source);
          }

          if (this.teamDragging != "members") {
            remove([this.source.id], this.$store.state.team2_info.id, this.$store.state.teamSettings.deleteMatches, this.$store.state.teamSettings.deleteTraining);
            this.$store.state.team2_info.players =
              this.$store.state.team2_info.players.filter(
                (player) => player.id.trim() != this.source.id.trim()
              );
          }
        } else {
          if (
            !this.$store.state.team2_info.players.some(
              ({ id }) => id == this.source.id
            )
          ) {
            add([this.source.id], this.$store.state.team2_info, this.$store.state.teamSettings.addMatches, this.$store.state.teamSettings.addTraining);
            this.$store.state.team2_info.players.push(this.source);
          }

          if (this.teamDragging != "members") {
            remove([this.source.id], this.$store.state.team1_info.id, this.$store.state.teamSettings.deleteMatches, this.$store.state.teamSettings.deleteTraining);
            this.$store.state.team1_info.players =
              this.$store.state.team1_info.players.filter(
                (player) => player.id.trim() != this.source.id.trim()
              );
          }
        }

        this.logLastAction("move", [
          {
            player: this.source,
            from: this.teamDragging,
            to: parentWithClass.id,
          },
        ]);
        this.uncheckAll();
      }

      return false;
    },

    // Drag to Delete
    handleDragEnter_delete() {
      this.hoverDelete = true;
    },

    handleDragLeave_delete() {
      this.hoverDelete = false;
    },
    handleDrop_delete(e) {
      e.stopPropagation(); // stops the browser from redirecting.

      // Delete
      remove([this.source.id], this.teamDragging, this.$store.state.teamSettings.deleteMatches, this.$store.state.teamSettings.deleteTraining);

      if (this.teamDragging == this.$store.state.team1_info.id) {
        this.$store.state.team1_info.players =
          this.$store.state.team1_info.players.filter(
            (player) => player.id.trim() != this.source.id.trim()
          );
      } else {
        this.$store.state.team2_info.players =
          this.$store.state.team2_info.players.filter(
            (player) => player.id.trim() != this.source.id.trim()
          );
      }

      this.logLastAction("delete", [
        {
          player: this.source,
          from: this.teamDragging,
          to: "",
        },
      ]);

      return false;
    },
    sortPlayers(team) {
      var players;
      if (team == 1) {
        players = this.$store.state.team1_info.players;
      } else if (team == 2) {
        players = this.$store.state.team2_info.players;
      }

      if (!players) {
        return;
      }

      players.sort(function (a, b) {
        let player1 = a["name"].split(" ");
        let player2 = b["name"].split(" ");
        if (player1.length > 1 && player2.length > 1) {
          if (player1[1] < player2[1]) {
            return -1;
          }
          if (player1[1] > player2[1]) {
            return 1;
          }

          return 0;
        }
        return 0;
      });
    },
    delayLoop(fn, delay, length) {
      return (x, i) => {
        setTimeout(() => {
          this.$store.dispatch("updateUploadingPercentage", {
            current: i,
            total: length,
          });
          fn(x);
          if (i == length - 1) {
            this.$store.state.uploading = false;
          }
        }, i * delay);
      };
    },
  },
  mounted() {
    load_members();
    load_teams();
  },
  created() {
    onBeforeMount(async () => {
      if (!this.$store.state.login.logged) {
        router.replace("/login");
      }
      if (
        !this.$store.state.allowedRoles.team.includes(
          this.$store.state.login.role
        )
      ) {
        router.replace("/");
      }
    });
  },
  watch: {
    team1_players() {
      this.sortPlayers(1);
    },
    team2_players() {
      this.sortPlayers(2);
    },
  },
};
</script>
<style scoped>
.contain {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.team-bar {
  background: var(--grey);
  display: flex;
  width: 100%;
  height: 50px;
  padding: 0px 10px;
  overflow-x: auto;
  overflow-y: hidden;
}

.team-bar .team {
  background: var(--blue);
  opacity: 0.5;
  color: white;
  width: fit-content;
  padding: 5px 10px;
  margin: 10px 5px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  white-space: nowrap;
}

.hide {
  display: none !important;
}

.menu {
  background: var(--grey);
  display: flex;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #dedede;
  align-items: center;
}

.menu .icon {
  margin: 5px 5px;
  align-items: center;
  padding: 5px 15px;
  background: rgb(232, 232, 232);
  opacity: 0.4;
  border-radius: 5px;
}

.menu .members {
  cursor: pointer;
}

.menu img {
  margin: 0 10px;
  width: 35px;
  height: 35px;
}
.activated {
  opacity: 1 !important;
  cursor: pointer;
}
.activated.on {
  color: var(--blue);
  border: 2px solid var(--blue)
}

.menu .selection {
  cursor: pointer;
}
.menu .toggle {
  cursor: pointer;
  display: flex;
  gap: 4px;
  user-select: none;

}
.menu .selection #select {
  display: inline-block;
  margin-right: 4px;
}

.menu .selection:hover #select {
  display: none;
}

.menu .selection #deselect {
  display: none;
}

.menu .selection:hover #deselect {
  display: inline-block;
  margin-right: 4px;
}

.hovering {
  background: red !important;
  color: white !important;
}

.pick {
  background: red !important;
  opacity: 1 !important;
}

.empty {
  margin-top: -15%;
  font-size: larger;
}
.empty span {
  opacity: 0.4;
  font-size: medium;
}
.canvas {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.canvas .team {
  background: var(--grey);
  width: 100%;
  border-radius: 5px;
  height: 85%;
  margin: 0px 10px;
  margin-top: -100px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.team .amount {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 10px;
  margin-bottom: 5px;
  font-size: smaller;
  opacity: 0.6;
  font-weight: bold;
}

.canvas .team h2 {
  display: flex;
  text-align: left;
  color: black;
}

.canvas .team .players {
  overflow-y: scroll;
  /* margin-bottom: 25px; */
}

.team .action_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 10px 10px;
  position: relative;
}
.team .action_bar p,
.team .action_bar h2 {
  margin: 0;
}

.team .action_bar .actions {
  display: flex;
}

.team .action_bar .actions > :not(.filter_box) {
  cursor: pointer;
  opacity: 0.7;
}

.team .action_bar .actions > *:hover {
  opacity: 1;
}

.on {
  opacity: 1;
  color: var(--blue);
}

.players .player {
  margin: 5px 10px;
  background: white;
  border-radius: 3px;
  /*  */
  text-align: left;
  cursor: move;
}

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding: 5px 0px 5px 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Drag CSS */
.over {
  border: 3px solid var(--blue);
}

/* Checkbox CSS */
/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #eee;
  border-radius: 3px;
  margin-top: 7px;
  margin-left: 6px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: var(--blue);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
