import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import TeamAssignmentView from "../views/TeamAssignmentView.vue";
import LoginView from "../views/LoginView.vue";
import MatchViewVue from "@/views/MatchView.vue";
import CategoryViewVue from "@/views/CategoryView.vue";
import ChargeViewVue from "@/views/ChargeView.vue";
import MemberViewVue from "@/views/MemberView.vue";
import NotFoundViewVue from "@/views/NotFoundView.vue";
import TrainingViewVue from "@/views/TrainingView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/teams",
    name: "Team Assignment",
    component: TeamAssignmentView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/matches",
    name: "Matches",
    component: MatchViewVue,
  },
  {
    path: "/training",
    name: "Training",
    component: TrainingViewVue,
  },
  {
    path: "/categories",
    name: "Categories",
    component: CategoryViewVue,
  },
  {
    path: "/members",
    name: "Members",
    component: MemberViewVue,
  },
  {
    path: "/charge",
    name: "Charge",
    component: ChargeViewVue,
  },
  { path: "/404", component: NotFoundViewVue },
  { path: "/:catchAll(.*)", redirect: "/404" },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
