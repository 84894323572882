import axios from "axios";
import store from "@/store/index";
import router from "@/router/index";

function loginAttempt(username, password) {
    let jsonData = {
        username,
        password,
    };

    axios
        .post("api/login-to-wp", jsonData, {
            headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
            let data = response.data;
            if (data.result == "success") {
                if (data.sites) {
                    // Belongs to more than 1 blog
                    store.state.login.logged = data.logged;
                    store.state.login.siteid = data.sites;
                    store.state.login.userid = data.id;
                } else {
                    store.state.login.logged = data.logged;
                    store.state.login.role = data.role;
                    store.state.login.siteid = data.siteid;
                    store.state.login.siteurl = data.siteurl;
                    store.state.login.userid = data.userid;
                    store.state.login.memberArray = data.memberArray;
                    store.dispatch("updateMemberSelected", {
                        name: data.memberSelected.name,
                        id: data.memberSelected.id,
                    });
                }
                if (store.state.login.logged) {
                    router.push("/");
                }
            } else {
                store.state.loginText = "Incorrect username or password";
            }
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
}
export default loginAttempt;