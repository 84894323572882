<template>
  <div id="new_box" v-if="this.$store.state.showNew" class="grid-container">
    <div class="grid-1">
      <h4>Members</h4>
      <div class="memberList">
        <input
          type="text"
          v-model="memberSearch"
          id="memberSearch"
          placeholder="Search Members"
        />
        <div v-for="(member, i) in this.$store.state.members" :key="i">
          <div
            v-if="
              member.name.toLowerCase().includes(memberSearch.toLowerCase())
            "
          >
            <input
              type="checkbox"
              name="memberCheck"
              :id="member.id"
              :value="member.id"
              @change="setMembers()"
            />
            <label :for="member.id">{{ member.name }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="grid-2">
      <h4>Type</h4>
      <select v-model="this.$store.state.chargeNew.type">
        <option value=""></option>
        <option value="Charge">Charge</option>
        <option value="Credit">Credit</option>
      </select>
      <h4>Amount</h4>
      <input
        type="number"
        step="0.01"
        min="0"
        v-model="this.$store.state.chargeNew.amount"
      />
    </div>

    <div class="grid-3">
      <h4>Finance Category</h4>
      <select v-model="this.$store.state.chargeNew.category">
        <option
          v-for="(fc, i) in this.$store.state.finance_categories"
          :key="i"
          :value="fc.name"
        >
          {{ fc.name }}
        </option>
      </select>
      <h4>Notes</h4>
      <input type="text" v-model="this.$store.state.chargeNew.notes" />
    </div>
    <div class="grid-4">
      <div class="buttons">
        <button class="create" @click="handleNewCreate">Create</button>
        <button class="cancel" @click="handleNew">Cancel</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NewChargeBox",
  props: ["handleNew", "handleNewCreate"],
  data() {
    return {
      memberSearch: "",
    };
  },
  methods: {
    setMembers() {
      this.$store.state.chargeNew.members = [];
      document
        .querySelectorAll(`input[name=memberCheck]:checked`)
        .forEach((e) => {
          this.$store.state.chargeNew.members.push(e.id);
          // let index = this.rows.findIndex((row) => row.id == e.id);
          // this.rows.splice(index, 1);
        });
    },
  },
};
</script>

<style scoped>
#memberSearch {
  height: 30px;
  margin: 5px 0px;
  width: 90%;
}

#new_box h4 {
  text-align: left;
  font-size: large;
  font-weight: bold;
  margin: 10px 0px 5px;
}

#new_box .buttons {
  width: 100%;
  padding: 10px 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.buttons button {
  width: 100px;
  height: 40px;
  color: white;
  border-radius: 5px;
  font-size: large;
  margin: 10px;
}

.buttons .create {
  background: var(--blue);
}

.buttons .cancel {
  background: #c9c9c9;
}

.grid-1 {
  grid-area: left;
}

.memberList {
  max-height: 400px;
  text-align: left;
  overflow-y: scroll;
}
.grid-2 {
  grid-area: middle;
}
.grid-3 {
  grid-area: right;
}
.grid-4 {
  grid-area: footer;
  display: flex;
}

.grid-container {
  position: absolute;
  z-index: 100;
  display: grid;
  grid-template-areas:
    "left middle right"
    "footer footer footer";
  gap: 10px;
  background-color: var(--grey);
  border-radius: 5px;
  text-align: left;
  padding: 10px;
  margin: 60px 30px;
}

.grid-container > div {
  text-align: center;
  padding: 0px 5px;
}

.grid-container input[type="text"],
.grid-container input[type="number"],
.grid-container input[type="date"],
.grid-container select {
  -webkit-appearance: none;
  width: 230px;
  /* height: 30px; */
  border-radius: 5px;
  border: 1px solid rgb(209 213 219);
  padding: 0.5rem;
  font-size: 0.875rem;
}
</style>