<template>
  <div class="contain">
    <div v-if="this.assigning" class="overlay">
      <div class="assigning">
        <div class="bar">
          <h3>Assign Columns</h3>
          <div class="buttons">
            <button class="add" @click="handleAdd">Add</button>
            <button class="exit" @click="handleExit">Cancel</button>
          </div>
        </div>
        <div class="columns">
          <div class="column-a">
            <h4>Column 1</h4>
            <select
              class="input"
              v-model="column.a.selected"
              :class="{
                error: this.columnDuplicates.has(column.a.selected),
              }"
            >
              <option v-for="(column, i) in columns" :value="column" :key="i">
                {{ column }}
              </option>
            </select>
            <div class="data-preview">
              <div v-for="(text, i) in column.a.preview" :key="i">
                {{ text }}
              </div>
            </div>
          </div>
          <div class="column-b">
            <h4>Column 2</h4>
            <select
              class="input"
              v-model="column.b.selected"
              :class="{
                error: this.columnDuplicates.has(column.b.selected),
              }"
            >
              <option v-for="(column, i) in columns" :value="column" :key="i">
                {{ column }}
              </option>
            </select>
            <div class="data-preview">
              <div v-for="(text, i) in column.b.preview" :key="i">
                {{ text }}
              </div>
            </div>
          </div>
          <div class="column-c">
            <h4>Column 3</h4>
            <select
              class="input"
              v-model="column.c.selected"
              :class="{
                error: this.columnDuplicates.has(column.c.selected),
              }"
            >
              <option v-for="(column, i) in columns" :value="column" :key="i">
                {{ column }}
              </option>
            </select>
            <div class="data-preview">
              <div v-for="(text, i) in column.c.preview" :key="i">
                {{ text }}
              </div>
            </div>
          </div>
          <div class="column-d">
            <h4>Column 4</h4>
            <select
              class="input"
              v-model="column.d.selected"
              :class="{
                error: this.columnDuplicates.has(column.d.selected),
              }"
            >
              <option v-for="(column, i) in columns" :value="column" :key="i">
                {{ column }}
              </option>
            </select>
            <div class="data-preview">
              <div v-for="(text, i) in column.d.preview" :key="i">
                {{ text }}
              </div>
            </div>
          </div>
          <div class="column-e">
            <h4>Column 5</h4>
            <select
              class="input"
              v-model="column.e.selected"
              :class="{
                error: this.columnDuplicates.has(column.e.selected),
              }"
            >
              <option v-for="(column, i) in columns" :value="column" :key="i">
                {{ column }}
              </option>
            </select>
            <div class="data-preview">
              <div v-for="(text, i) in column.e.preview" :key="i">
                {{ text }}
              </div>
            </div>
          </div>
          <div class="column-f">
            <h4>Column 6</h4>
            <select
              class="input"
              v-model="column.f.selected"
              :class="{
                error: this.columnDuplicates.has(column.f.selected),
              }"
            >
              <option v-for="(column, i) in columns" :value="column" :key="i">
                {{ column }}
              </option>
            </select>
            <div class="data-preview">
              <div v-for="(text, i) in column.f.preview" :key="i">
                {{ text }}
              </div>
            </div>
          </div>
          <div class="column-g">
            <h4>Column 7</h4>
            <select
              class="input"
              v-model="column.g.selected"
              :class="{
                error: this.columnDuplicates.has(column.g.selected),
              }"
            >
              <option v-for="(column, i) in columns" :value="column" :key="i">
                {{ column }}
              </option>
            </select>
            <div class="data-preview">
              <div v-for="(text, i) in column.g.preview" :key="i">
                {{ text }}
              </div>
            </div>
          </div>
          <div class="column-h">
            <h4>Column 8</h4>
            <select
              class="input"
              v-model="column.h.selected"
              :class="{
                error: this.columnDuplicates.has(column.h.selected),
              }"
            >
              <option v-for="(column, i) in columns" :value="column" :key="i">
                {{ column }}
              </option>
            </select>
            <div class="data-preview">
              <div v-for="(text, i) in column.h.preview" :key="i">
                {{ text }}
              </div>
            </div>
          </div>
          <div class="column-i">
            <h4>Column 9</h4>
            <select
              class="input"
              v-model="column.i.selected"
              :class="{
                error: this.columnDuplicates.has(column.i.selected),
              }"
            >
              <option v-for="(column, i) in columns" :value="column" :key="i">
                {{ column }}
              </option>
            </select>
            <div class="data-preview">
              <div v-for="(text, i) in column.i.preview" :key="i">
                {{ text }}
              </div>
            </div>
          </div>
          <div class="column-j">
            <h4>Column 10</h4>
            <select
              class="input"
              v-model="column.j.selected"
              :class="{
                error: this.columnDuplicates.has(column.j.selected),
              }"
            >
              <option v-for="(column, i) in columns" :value="column" :key="i">
                {{ column }}
              </option>
            </select>
            <div class="data-preview">
              <div v-for="(text, i) in column.j.preview" :key="i">
                {{ text }}
              </div>
            </div>
          </div>
          <div class="column-k">
            <h4>Column 11</h4>
            <select
              class="input"
              v-model="column.k.selected"
              :class="{
                error: this.columnDuplicates.has(column.k.selected),
              }"
            >
              <option v-for="(column, i) in columns" :value="column" :key="i">
                {{ column }}
              </option>
            </select>
            <div class="data-preview">
              <div v-for="(text, i) in column.k.preview" :key="i">
                {{ text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UploadingBox text="Fixtures" />
    <div class="menu">
      <div class="left">
        <router-link to="/">
          <img src="@/assets/logo.png" alt="" />
        </router-link>
        <div class="icon new" @click="handleNew">
          New
          <font-awesome-icon style="margin-left: 4px" icon="plus" />
        </div>
        <div
          class="icon duplicate"
          @click="handleDuplicate"
          :class="{ activated: selected > 0 }"
        >
          Duplicate
          <font-awesome-icon style="margin-left: 4px" icon="copy" />
        </div>

        <div
          class="icon"
          @click="handleDelete"
          :class="{ activated: selected > 0 }"
        >
          Delete
          <font-awesome-icon style="margin-left: 4px" icon="trash-can" />
        </div>
        <div
          class="icon selection"
          :class="{ activated: selected > 0 }"
          @click="handleSelect"
        >
          <span id="select">{{ selected }} Selected</span>
          <span id="deselect">Deselect all</span>
          <font-awesome-icon icon="check" />
        </div>
        <div
          class="icon paste"
          @click="handlePaste"
          :class="{ activated: true }"
        >
          Paste
          <font-awesome-icon style="margin-left: 4px" icon="paste" />
        </div>

        <label for="upload-file">
          <div class="icon paste" :class="{ activated: true }">
            Upload CSV
            <font-awesome-icon style="margin-left: 4px" icon="upload" />
          </div>
        </label>
        <input
          type="file"
          name="image"
          id="upload-file"
          @change="handleCSV"
          ref="myFiles"
          accept=".csv"
        />
      </div>
      <div class="right">
        <div
          class="import"
          @click="handleImport"
          :class="{ activated: this.rows.length > 0 }"
        >
          Import Fixtures
          <font-awesome-icon style="margin-left: 4px" icon="upload" />
        </div>
      </div>
    </div>
    <NewMatchBox :handleNew="handleNew" :handleNewCreate="handleNewCreate" />
    <DuplicateBox
      :createRow="this.createRow"
      :handleDuplicate="this.handleDuplicate"
      :handleSelect="this.handleSelect"
      :rows="this.rows"
    />
    <table class="table">
      <thead>
        <th>
          <font-awesome-icon
            style="margin: 4px; cursor: pointer"
            icon="check-circle"
            @click="selectAll"
          />
        </th>
        <th class="col-1">Team</th>
        <th class="col-2">Opponent</th>
        <th class="col-3">Date</th>
        <th class="col-4">Time</th>
        <th class="col-5">Location</th>
        <th class="col-6">Venue</th>
        <th class="col-7">Competition</th>
        <th class="col-8">Result</th>
        <th class="col-9">Team Score</th>
        <th class="col-10">Opponent Score</th>
        <th class="col-11">Points</th>
      </thead>
      <tbody>
        <tr v-for="(row, i) in this.rows" :key="i">
          <td @click="check($event, row.id)">
            <input
              type="checkbox"
              name="rowCheck"
              :id="row.id"
              @change="singleCheck()"
            />
          </td>
          <td
            class="cell col-1"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 1),
            }"
          >
            <div class="team">
              <select
                name=""
                id=""
                v-model="row.teamid"
                class="input"
                @change="changeTeam(row.teamid, i)"
              >
                <option
                  :value="team.id"
                  v-for="(team, i) in this.$store.state.teams"
                  :key="i"
                >
                  {{ team.name }}
                </option>
              </select>
            </div>
          </td>
          <td
            class="cell col-2"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 2),
            }"
          >
            <div>
              <input
                class="input"
                type="text"
                name=""
                id=""
                v-model="row.opp"
              />
            </div>
          </td>
          <td
            class="cell col-3"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 3),
            }"
          >
            <div class="date">
              <input
                class="input time"
                type="date"
                v-model="row.date"
                name=""
                id=""
              />
            </div>
          </td>
          <td
            class="cell col-4"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 4),
            }"
          >
            <div class="">
              <input
                class="input time"
                type="text"
                placeholder="HH:MM AM/PM"
                v-model="row.time"
                name=""
                id=""
              />
            </div>
          </td>
          <td
            class="cell col-5"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 5),
            }"
          >
            <div class="">
              <select class="input" name="" id="" v-model="row.location">
                <option value=""></option>
                <option value="Home">Home</option>
                <option value="Away">Away</option>
              </select>
            </div>
          </td>
          <td
            class="cell col-6"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 6),
            }"
          >
            <div>
              <select class="input" v-model="row.venue">
                <option
                  :value="venue"
                  v-for="(venue, i) in this.$store.state.venues"
                  :key="i"
                >
                  {{ venue.name }}
                </option>
              </select>
            </div>
          </td>
          <td
            class="cell col-7"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 7),
            }"
          >
            <div>
              <select class="input" v-model="row.comp">
                <option
                  :value="competition"
                  v-for="(competition, i) in this.$store.state.competitions"
                  :key="i"
                >
                  {{ competition }}
                </option>
              </select>
            </div>
          </td>
          <td
            class="cell col-8"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 8),
            }"
          >
            <div>
              <select class="input" name="" id="" v-model="row.result">
                <option value=""></option>
                <option value="Win">Win</option>
                <option value="Draw">Draw</option>
                <option value="Loss">Loss</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Unreported">Unreported</option>
              </select>
            </div>
          </td>
          <td
            class="cell col-9"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 9),
            }"
          >
            <div>
              <input
                class="input"
                type="number"
                name=""
                id=""
                v-model="row.tscore"
              />
            </div>
          </td>
          <td
            class="cell col-10"
            :class="{
              error: this.errors.some(
                ({ line, col }) => line == i && col == 10
              ),
            }"
          >
            <div>
              <input
                class="input"
                type="number"
                name=""
                id=""
                v-model="row.oscore"
              />
            </div>
          </td>
          <td
            class="cell col-11"
            :class="{
              error: this.errors.some(
                ({ line, col }) => line == i && col == 11
              ),
            }"
          >
            <div>
              <input
                class="input"
                type="number"
                name=""
                id=""
                v-model="row.points"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref } from "vue";
// import { initial_load } from "@/requests/load.js";
import load_members from "@/requests/load_members.js";
import load_teams from "@/requests/load_teams";
import load_venues from "@/requests/load_venues";
import load_comps from "@/requests/load_comps";

import { onBeforeMount } from "vue";
import router from "@/router/index";

import similarity from "@/compareStrings";
import uploadMatches from "@/requests/matches/UploadMatches";
import UploadingBox from "@/components/UploadingBox.vue";
import DuplicateBox from "../components/DuplicateBox.vue";
import NewMatchBox from "@/components/NewMatchBox.vue";
import csvToArray from "@/csvToArray";

export default {
  name: "MatchView",
  setup() {
    const formatter = ref({
      date: "DD/MM/YYYY",
      month: "MMM",
    });

    return {
      formatter,
    };
  },
  components: {
    UploadingBox,
    DuplicateBox,
    NewMatchBox,
  },
  data() {
    return {
      selected: 0,
      rows: [
        {
          id: "default",
          opp: "",
          venue: {
            id: "",
            name: "",
          },
          location: "",
          comp: "",
          result: "",
          time: "",
        },
      ],
      columns: [
        "Team",
        "Opponent",
        "Date",
        "Time",
        "Location",
        "Venue",
        "Competition",
        "Result",
        "Team Score",
        "Opponent Score",
        "Points",
      ],
      column: {
        a: { selected: "Team", preview: [] },
        b: { selected: "Opponent", preview: [] },
        c: { selected: "Date", preview: [] },
        d: { selected: "Time", preview: [] },
        e: { selected: "Location", preview: [] },
        f: { selected: "Venue", preview: [] },
        g: { selected: "Competition", preview: [] },
        h: { selected: "Result", preview: [] },
        i: { selected: "Team Score", preview: [] },
        j: { selected: "Opponent Score", preview: [] },
        k: { selected: "Points", preview: [] },
      },
      errors: [],
      assigning: false,
      convertedRows: [],
      columnDuplicates: new Set(),
    };
  },

  computed: {},
  methods: {
    changeTeam(teamid, i) {
      let team = this.$store.state.teams.find((team) => team.id == teamid);
      this.rows[i].team = team.name;
    },
    handleCSV() {
      if (this.$refs.myFiles) {
        let file = this.$refs.myFiles.files[0];

        const reader = new FileReader();
        reader.onload = (e) => {
          let converted = [];
          let str = e.target.result;
          const result = csvToArray(str);

          result.forEach((row) => {


            for (let index = 0; row.length < 11; index++) {
              row.push("");
            }

            if (JSON.stringify(row) != '["","","","","","","","","","",""]') {
              converted.push(row);
            }
          });

          this.handleAssign(converted);
        };
        reader.readAsText(file);
      }

      document.getElementById("upload-file").value = null;
    },
    processRow(convertedRow) {
      var compare = {
        team: { id: "", name: "", value: 0 },
        location: { name: "", value: 0 },
        venue: { id: "", name: "", value: 0 },
        comp: { name: "", value: 0 },
        result: { name: "", value: 0 },
      };

      if (convertedRow.length > 4) {
        if (
          convertedRow[0].value == "teamname" &&
          convertedRow[1].value == "opponent" &&
          convertedRow[2].value == "date" &&
          convertedRow[3].value == "time" &&
          convertedRow[4].value == "homeoraway"
        ) {
          return;
        }
      }

      if (convertedRow.length > 0) {
        var team = convertedRow[0].value;
        this.$store.state.teams.forEach((t) => {
          if (similarity(t.name, team) > compare.team.value) {
            compare.team = {
              id: t.id,
              name: t.name,
              value: similarity(t.name, team),
            };
          }
        });
      }
      if (convertedRow.length > 1) {
        var opp = convertedRow[1].value;
      }
      if (convertedRow.length > 2) {
        try {
          var parts = convertedRow[2].value.split("/");
          var d1 = new Date(
            Number(parts[2]),
            Number(parts[1]) - 1,
            Number(parts[0])
          );

          if (d1.getFullYear() < 1970) {
            d1.setFullYear(d1.getFullYear() + 100);
          }
          var z = (n) => ("0" + n).slice(-2);

          var date =
            d1.getFullYear() +
            "-" +
            z(d1.getMonth() + 1) +
            "-" +
            z(d1.getDate());
        } catch (error) {
          date = "";
        }
      }
      if (convertedRow.length > 3) {
        var time = this.processTime(convertedRow[3].value)
      }
      if (convertedRow.length > 4) {
        var location = convertedRow[4].value;
        ["", "Home", "Away"].forEach((l) => {
          if (similarity(l, location) > compare.location.value) {
            compare.location = {
              name: l,
              value: similarity(l, location),
            };
          }
        });
      }
      if (convertedRow.length > 5) {
        var venue = convertedRow[5].value;
        this.$store.state.venues.forEach((v) => {
          if (similarity(v.name, venue) > compare.venue.value) {
            compare.venue = {
              id: v.id,
              name: v.name,
              value: similarity(v.name, venue),
            };
          }
        });
      }
      if (convertedRow.length > 6) {
        var comp = convertedRow[6].value;
        this.$store.state.competitions.forEach((c) => {
          if (similarity(c, comp) > compare.comp.value) {
            compare.comp = {
              name: c,
              value: similarity(c, comp),
            };
          }
        });
      }
      if (convertedRow.length > 7) {
        var result = convertedRow[7].value;
        this.$store.state.results.forEach((r) => {
          if (similarity(r, result) > compare.result.value) {
            compare.result = {
              name: r,
              value: similarity(r, result),
            };
          }
        });
      }
      if (convertedRow.length > 8) {
        var tscore = convertedRow[8].value;
      }
      if (convertedRow.length > 9) {
        var oscore = convertedRow[9].value;
      }
      if (convertedRow.length > 10) {
        var points = convertedRow[10].value;
      }

      let object = {
        teamid: compare.team.id,
        team: compare.team.name,
        opp: opp,
        date: ref(date),
        time: time ? time : "",
        location: compare.location.name,
        venue: { name: compare.venue.name, id: compare.venue.id },
        comp: compare.comp.name,
        result: compare.result.name,
        tscore: tscore ? tscore : "",
        oscore: oscore ? oscore : "",
        points: points ? points : "",
      };
      this.createRow(object);
    },
    async handleImport() {
      // Time Regex
      const timeRegex = new RegExp(this.$store.state.timeRegex);

      this.errors = [];

      // For Each Row
      this.rows.forEach((row, i) => {
        // Check team

        if (!this.$store.state.teams.some(({ id }) => id == row.teamid)) {
          this.errors.push({
            line: i,
            col: 1,
            message: `Please reselect the team on line ${i}`,
          });
        }
        // Check Opponent
        if (row.opp.trim().length < 2) {
          this.errors.push({
            line: i,
            col: 2,
            message: `Please specify an opponent on line ${i}`,
          });
        }

        // Check Date

        if (!row.date || row.date.length == 0) {
          this.errors.push({
            line: i,
            col: 3,
            message: `Please select a date on line ${i}`,
          });
        }

        // Check Time
        if (row.time != "" && !timeRegex.test(row.time)) {
          this.errors.push({
            line: i,
            col: 4,
            message: `You have entered the time in an incorrect format, please use HH:MM AM/PM on line ${i}`,
          });
        }

        // Check Location
        if (
          row.location != "" &&
          row.location != "Home" &&
          row.location != "Away"
        ) {
          this.errors.push({
            line: i,
            col: 5,
            message: `Please reselect the location on line ${i}`,
          });
        }

        // Check Venue
        if (!this.$store.state.venues.some(({ id }) => id == row.venue.id)) {
          this.errors.push({
            line: i,
            col: 6,
            message: `Please reselect the venue on line ${i}`,
          });
        }
        // Check Competition
        if (!this.$store.state.competitions.includes(row.comp)) {
          this.errors.push({
            line: i,
            col: 7,
            message: `Please reselect the competition on line ${i}`,
          });
        }

        // Check Result
        if (!this.$store.state.results.includes(row.result)) {
          this.errors.push({
            line: i,
            col: 8,
            message: `Please reselect the result on line ${i}`,
          });
        }
      });

      if (this.errors.length == 0) {
        this.$store.state.uploading = true;
        setTimeout(() => {
          this.$store.state.uploading = false;
          this.rows = [];
          this.rows = [
            {
              id: "default",
              date: ref(""),
              opp: "",
              venue: {
                id: "",
                name: "",
              },
              location: "",
              comp: "",
              result: "",
              time: "",
            },
          ];
        }, this.rows.length * 1000);
        await uploadMatches(this.rows);
      }
    },
    async handlePaste() {
      try {
        const clipboardContents = await navigator.clipboard.read();
        for (const item of clipboardContents) {
          if (!item.types.includes("text/html")) {
            alert("Paste only supports Copying from Excel");
            throw new Error("Clipboard contains non-image data.");
          }
          const blob = await item.getType("text/html");
          const str = await blob.text();

          var parser = new DOMParser();
          var doc = parser.parseFromString(str, "text/html");
          let table = doc.body;
          var rows = table.getElementsByTagName("table")[0].rows;
          // var last = rows[rows.length - 1];
          let converted = [];

          for (let r of rows) {
            let cells = r.getElementsByTagName("td");
            let convertedRow = [];
            for (let c of cells) {
              if (c.innerText) {
                convertedRow.push(c.innerText);
              } else {
                convertedRow.push("");
              }
            }

            for (let index = 0; convertedRow.length < 11; index++) {
              convertedRow.push("");
            }
            converted.push(convertedRow);
          }

          this.handleAssign(converted);
        }
      } catch (error) {
        if (error.message == "navigator.clipboard.read is not a function") {
          alert("This browser does not support Paste.");
        } else {
          console.log(error);
          console.error(error.message);
        }
      }
    },
    // Handle Paste/Upload > Send Rows to Handle Assign > Add puts them in table
    handleAssign(convertedRows) {
      let top = convertedRows.slice(0, 5);
      top.forEach((row) => {
        if (row[0]) {
          this.column.a.preview.push(row[0]);
        } else {
          this.column.a.preview.push("-");
        }
        if (row[1]) {
          this.column.b.preview.push(row[1]);
        } else {
          this.column.b.preview.push("-");
        }
        if (row[2]) {
          this.column.c.preview.push(row[2]);
        } else {
          this.column.c.preview.push("-");
        }
        if (row[3]) {
          this.column.d.preview.push(row[3]);
        } else {
          this.column.d.preview.push("-");
        }
        if (row[4]) {
          this.column.e.preview.push(row[4]);
        } else {
          this.column.e.preview.push("-");
        }
        if (row[5]) {
          this.column.f.preview.push(row[5]);
        } else {
          this.column.f.preview.push("-");
        }
        if (row[6]) {
          this.column.g.preview.push(row[6]);
        } else {
          this.column.g.preview.push("-");
        }
        if (row[7]) {
          this.column.h.preview.push(row[7]);
        } else {
          this.column.h.preview.push("-");
        }
        if (row[8]) {
          this.column.i.preview.push(row[8]);
        } else {
          this.column.i.preview.push("-");
        }
        if (row[9]) {
          this.column.j.preview.push(row[9]);
        } else {
          this.column.j.preview.push("-");
        }
        if (row[10]) {
          this.column.k.preview.push(row[10]);
        } else {
          this.column.k.preview.push("-");
        }
      });

      this.assigning = true;
      this.convertedRows = convertedRows;
    },
    handleAdd() {
      this.columnDuplicates = new Set();
      // Make sure all columns are unique
      for (const key in this.column) {
        for (const secondaryKey in this.column) {
          if (
            key != secondaryKey &&
            this.column[key].selected == this.column[secondaryKey].selected
          ) {
            this.columnDuplicates.add(this.column[key].selected);
          }
        }
      }

      if (this.columnDuplicates.size > 0) {
        alert("All columns must be unique");
        return;
      }

      // Get Order Values for the row
      let arrayWithOrderValues = this.convertedRows.map((row) => {
        row.forEach((value, index) => {
          let order = this.getOrderValue(index);

          row[index] = {
            value,
            order,
          };
        });
        return row;
      });

      // Sort rows on the order values
      arrayWithOrderValues.forEach((row) => {
        row.sort(function (x, y) {
          if (x.order < y.order) {
            return -1;
          }
          if (x.order > y.order) {
            return 1;
          }
          return 0;
        });
      });

      arrayWithOrderValues.forEach((row) => this.processRow(row));
      this.assigning = false;
      this.convertedRows = [];
      this.column = {
        a: { selected: "Team", preview: [] },
        b: { selected: "Opponent", preview: [] },
        c: { selected: "Date", preview: [] },
        d: { selected: "Time", preview: [] },
        e: { selected: "Location", preview: [] },
        f: { selected: "Venue", preview: [] },
        g: { selected: "Competition", preview: [] },
        h: { selected: "Result", preview: [] },
        i: { selected: "Team Score", preview: [] },
        j: { selected: "Opponent Score", preview: [] },
        k: { selected: "Points", preview: [] },
      };
    },
    handleExit() {
      this.assigning = false;
      this.convertedRows = [];
      this.column = {
        a: { selected: "Team", preview: [] },
        b: { selected: "Opponent", preview: [] },
        c: { selected: "Date", preview: [] },
        d: { selected: "Time", preview: [] },
        e: { selected: "Location", preview: [] },
        f: { selected: "Venue", preview: [] },
        g: { selected: "Competition", preview: [] },
        h: { selected: "Result", preview: [] },
        i: { selected: "Team Score", preview: [] },
        j: { selected: "Opponent Score", preview: [] },
        k: { selected: "Points", preview: [] },
      };
    },
    getOrderValue(index) {
      switch (index) {
        case 0:
          return this.getColumnValue(this.column.a.selected);
        case 1:
          return this.getColumnValue(this.column.b.selected);
        case 2:
          return this.getColumnValue(this.column.c.selected);
        case 3:
          return this.getColumnValue(this.column.d.selected);
        case 4:
          return this.getColumnValue(this.column.e.selected);
        case 5:
          return this.getColumnValue(this.column.f.selected);
        case 6:
          return this.getColumnValue(this.column.g.selected);
        case 7:
          return this.getColumnValue(this.column.h.selected);
        case 8:
          return this.getColumnValue(this.column.i.selected);
        case 9:
          return this.getColumnValue(this.column.j.selected);
        case 10:
          return this.getColumnValue(this.column.k.selected);
        default:
          return -1;
      }
    },
    getColumnValue(selected) {
      switch (selected) {
        case "Team":
          return 0;
        case "Opponent":
          return 1;
        case "Date":
          return 2;
        case "Time":
          return 3;
        case "Location":
          return 4;
        case "Venue":
          return 5;
        case "Competition":
          return 6;
        case "Result":
          return 7;
        case "Team Score":
          return 8;
        case "Opponent Score":
          return 9;
        case "Points":
          return 10;
        default:
          return -1;
      }
    },
    handleNew() {
      this.$store.state.showDuplicate = false;
      this.$store.state.matchNew.team = null;
      this.$store.state.showNew = !this.$store.state.showNew;
    },
    handleDuplicate() {
      if (this.selected == 0) {
        this.$store.state.showDuplicate = false;
        return;
      }
      this.$store.state.showNew = false;
      this.$store.state.showDuplicate = !this.$store.state.showDuplicate;
    },
    handleDelete() {
      document.querySelectorAll(`input[name=rowCheck]:checked`).forEach((e) => {
        let index = this.rows.findIndex((row) => row.id == e.id);
        this.rows.splice(index, 1);
      });

      this.handleSelect();
    },
    handleSelect() {
      document
        .querySelectorAll(`input[name=rowCheck]`)
        .forEach((el) => (el.checked = false));

      this.selected = 0;
    },
    selectAll() {
      document
        .querySelectorAll(`input[name=rowCheck]`)
        .forEach((el) => (el.checked = true));
      var checkedBoxes = document.querySelectorAll(
        "input[name=rowCheck]:checked"
      );
      this.selected = checkedBoxes.length;
    },
    handleNewCreate() {
      if (!this.$store.state.matchNew.team) {
        alert("You must select a team");

        return;
      }
      if (this.$store.state.amountNew > 200) {
        alert("You can only create 200 matches at a time");

        return;
      }

      const timeRegex = new RegExp(this.$store.state.timeRegex);
      if (
        this.$store.state.matchNew.time != "" &&
        !timeRegex.test(this.$store.state.matchNew.time)
      ) {
        alert(
          "You have entered the time in an incorrect format, please use HH:MM AM/PM"
        );

        return;
      }

      // Get Team
      let team = this.$store.state.teams.find(
        (team) => team.id == this.$store.state.matchNew.team
      );
      // Get Amount
      // Create rows with that team and amount
      let object = {
        teamid: this.$store.state.matchNew.team,
        team: team.name,
        opp: this.$store.state.matchNew.opp.trim(),
        date: this.$store.state.matchNew.date.trim(),
        time: this.$store.state.matchNew.time.trim(),
        location: this.$store.state.matchNew.location.trim(),
        venue: {
          name: this.$store.state.matchNew.venue.name.trim(),
          id: this.$store.state.matchNew.venue.id,
        },

        comp: this.$store.state.matchNew.comp.trim(),
        result: "",
        tscore: "",
        oscore: "",
        points: "",
      };

      for (let index = 0; index < this.$store.state.amountNew; index++) {
        setTimeout(() => {
          this.createRow(object);
        }, index * 1);
      }

      this.$store.state.matchNew = {
        teamid: "",
        team: "",
        opp: "",
        date: "",
        time: "",
        location: "",
        venue: { name: "", id: "" },
        comp: "",
        result: "",
        tscore: "",
        oscore: "",
        points: "",
      };
      this.$store.state.amountNew = 1;

      this.handleNew();
    },
    createRow(object) {
      if (this.rows.length == 1 && this.rows[0].id == "default") {
        this.rows = [];
      }

      var uuid = Date.now().toString(36) + Math.random().toString(36);
      this.rows.push({
        id: uuid,
        teamid: object.teamid,
        team: object.team,
        opp: object.opp,
        date: object.date,
        time: object.time,
        location: object.location,
        venue: object.venue,
        comp: object.comp,
        result: object.result,
        tscore: object.tscore,
        oscore: object.oscore,
        points: object.points,
      });
    },
    check(event, id) {
      if (event.target instanceof HTMLInputElement) return;
      document.getElementById(id).checked =
        !document.getElementById(id).checked;
      var checkedBoxes = document.querySelectorAll(
        "input[name=rowCheck]:checked"
      );
      this.selected = checkedBoxes.length;
    },
    singleCheck() {
      var checkedBoxes = document.querySelectorAll(
        "input[name=rowCheck]:checked"
      );
      this.selected = checkedBoxes.length;
    },
    processTime(time) {
      if (time.toLowerCase().includes("am")) {
        return time.split(":")[0] + ":" + time.split(":")[1].slice(0,2) + " AM"
      } else if (time.toLowerCase().includes("pm")) {
        return time.split(":")[0] + ":" + time.split(":")[1].slice(0,2) + " PM"
      } else {
        let d = new Date("1970-01-01 " + time)
        if (d == "Invalid Date") {
          return ""
        }
        let hours = d.getHours();
        let minutes = d.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        return hours + ':' + minutes + ' ' + ampm;
      }
    }
  },
  mounted() {
    load_members();
    load_teams();
    load_venues();
    load_comps();
  },
  created() {
    onBeforeMount(async () => {
      if (!this.$store.state.login.logged) {
        router.replace("/login");
      }

      if (
        !this.$store.state.allowedRoles.match.includes(
          this.$store.state.login.role
        )
      ) {
        router.replace("/");
      }
    });
  },
};
</script>

<style scoped>
input:invalid,
.empty {
  outline: 2px solid red;
}
.error {
  border: 2px solid rgba(255, 0, 0, 0.6) !important;
  color: red;
}
.error input:focus {
  outline: 0px solid black;
}
input:focus:invalid {
  color: red;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.contain {
  font-family: "Roboto", sans-serif;
  width: 100vw;
  height: 100vh;
  overflow-x: auto;
}

.hide {
  display: none !important;
}

.menu {
  background: #f4f4f4;
  display: flex;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #dedede;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 5000;
  padding: 0px 10px;
  overflow-x: auto;
  overflow-y: hidden;
}

.menu .left {
  display: flex;
  align-items: center;
}

.menu .import {
  margin: 5px 5px;
  align-items: center;
  padding: 5px 15px;
  background: var(--blue);
  color: white;
  opacity: 0.4;
  border-radius: 5px;
  height: 35px;
  display: flex;
  justify-content: center;
  white-space: nowrap;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.menu .icon {
  margin: 5px 5px;
  align-items: center;
  padding: 5px 15px;
  background: rgb(232, 232, 232);
  opacity: 0.4;
  border-radius: 5px;
  height: 35px;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.menu .new {
  cursor: pointer;
  opacity: 1;
}

.menu img {
  width: 35px;
  height: 35px;
}
.activated {
  opacity: 1 !important;
  cursor: pointer;
}

.menu .selection {
  cursor: pointer;
}
.menu .selection #select {
  display: inline-block;
  margin-right: 4px;
}

.menu .selection:hover #select {
  display: none;
}

.menu .selection #deselect {
  display: none;
}

.menu .selection:hover #deselect {
  display: inline-block;
  margin-right: 4px;
}

.hovering {
  background: red !important;
  color: white !important;
}

.pick {
  background: red !important;
  opacity: 1 !important;
}

.table {
  margin-top: 50px;
  width: 100%;
  overflow-y: scroll;
}

td div {
  margin: auto;
}

.col-1 {
}
.col-2 {
}
.col-3 {
}
.col-4 {
}
.col-5 {
}
.col-6 {
}
.col-7 {
}
.col-8 {
}
.col-9 {
}
.col-10 {
}
.col-11 {
}

.table-header {
  font-size: 18px;
  align-items: center;
  display: flex;
  height: 43px;
  justify-content: flex-start;
}

th {
  text-transform: uppercase;
  opacity: 0.4;
  font-weight: bold;
  padding: 5px;
  letter-spacing: -0.08em;
}

table,
th,
td {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  border-collapse: collapse;
}

tr {
  height: 43px;
}

tr:nth-child(odd) {
  background: #f9f9f9;
}

.cell div {
  width: fit-content;
  padding: 5px 10px;
}

input[type="checkbox"] {
  margin: 5px;
}

.buttons button {
  width: 100px;
  height: 40px;
  color: white;
  border-radius: 5px;
  font-size: large;
  margin: 10px;
}

.buttons .create {
  background: var(--blue);
}

.buttons .cancel {
  background: #c9c9c9;
}

td /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
td input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
td input[type="number"] {
  width: 50px;
  -moz-appearance: textfield;
}

td select {
  border-radius: 3px;
  padding: 2px 5px;
}

.input {
  border-radius: 5px;
  border: 1px solid rgb(209 213 219);
  padding: 0.5rem;
  font-size: 0.875rem;
}

select {
  /* reset */
  padding-right: 40px !important;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
}

.time {
  width: 120px;
}

.date {
  width: 155px !important;
  padding: 0px 0px !important;
}

#upload-file {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: #f4f4f49d;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.assigning {
  position: relative;
  padding: 30px 20px;
  border-radius: 5px;
  background: var(--blue);
  margin: 0px 10px;
  overflow: hidden;
}

.assigning .bar {
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}

.assigning .bar h3 {
  font-size: x-large;
  font-weight: bold;
}

.assigning .bar .buttons {
  display: flex;
}

.assigning .bar .buttons button {
  width: fit-content;
  padding: 0 10px;
  font-weight: 600;
}

.assigning .bar .buttons .add {
  background: white;
  color: var(--blue);
}
.assigning .bar .buttons .exit {
  border: 2px solid white;
}

.assigning .columns {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 15px;
  opacity: 0.98;
}

.assigning .columns h4 {
  color: white;
  font-size: larger;
  font-weight: 600;
  text-align: left;
  margin-bottom: 10px;
}

.assigning .columns select {
  margin: 0px 2px;
  font-size: 1em;
  font-weight: 600;
}

.assigning .columns .data-preview {
  color: #f4f4f4;
  font-weight: 600;
  text-align: left;
  margin-top: 10px;
  margin-left: 5px;
  font-size: 1em;
  border-right: 1px solid var(--grey);
  height: 125px;
  width: 180px;
  overflow: hidden;
}
</style>
