import axios from "axios";
import store from "@/store/index";

function load_members() {
    let jsonData = {
        url: store.state.login.siteurl,
    };
    axios
        .post("api/load_members", jsonData, {
            headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
            // let data = response.data;
            let members = response.data.members;
            members.sort(function(a, b) {
                if (a["2.6"].toLowerCase() < b["2.6"].toLowerCase()) {
                    return -1;
                }
                if (a["2.6"].toLowerCase() > b["2.6"].toLowerCase()) {
                    return 1;
                }

                return 0;
            });

            store.state.members = members.map((member) => {
                return {
                    id: member["id"],
                    name: member["2.3"] + " " + member["2.6"],
                    dob: member["3"],
                    gender: member["4"],
                    mc: member["48"].toLowerCase(),
                    mc_fc: member["48"],
                    email: member["11"],
                    username: member["26"],
                    userid: member["created_by"]

                };
            });
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
}
export default load_members;