import axios from "axios";
import store from "@/store/index";

function load_teams() {
    let jsonData = {
        url: store.state.login.siteurl,
    };
    axios
        .post("api/load_teams", jsonData, {
            headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
            let teams = response.data.teams;
            teams.sort(function(a, b) {
                if (a["1"] < b["1"]) {
                    return -1;
                }
                if (a["1"] > b["1"]) {
                    return 1;
                }

                return 0;
            });

            store.state.teams = teams.map((team) => {
                let players = team["players"].map((player) => {
                    let d = new Date(player["dob"]);
                    if (d != "Invalid Date") {
                        d = d.toLocaleDateString('en-GB')
                    } else {
                        d = ""
                    }
                    return {
                        id: player["id"],
                        name: player["name"],
                        dob: d,
                        gender: player["gender"],
                    };
                });
                players.sort(function(a, b) {
                    let player1 = a["name"].split(" ")
                    let player2 = b["name"].split(" ")
                    if (player1.length > 1 && player2.length > 1) {

                        if (player1[1] < player2[1]) {
                            return -1;
                        }
                        if (player1[1] > player2[1]) {
                            return 1;
                        }

                        return 0;
                    }
                    return 0;
                })
                return { id: team["id"], name: team["1"], players: players };
            });

            // Team Format - {id: "4",name: "Team 4", players: [{ id: "37", name: "4 Eye", dob: "08/08/1997", gender: "Male" }]}
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
}
export default load_teams;