import axios from "axios";
import store from "@/store/index";

async function load_cf() {

    let jsonData = {
        url: store.state.login.siteurl,
    };

    try {
        const resp = await axios.post("api/members/load_cf", jsonData, {
            headers: { "Content-Type": "application/json" },
        });
        store.state.customFields = resp.data.fields
        return resp.data.fields.length
    } catch (error) {
        console.log(error)
        return 0
    }


}
export default load_cf;