import axios from "axios";
import store from "@/store/index";
import router from "@/router/index";

function loginToSite(siteurl, id) {
  let jsonData = {
    siteurl,
    id,
  };

  axios
    .post("api/login-to-site", jsonData, {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      let data = response.data;
      store.state.login.logged = data.logged;
      store.state.login.memberArray = data.memberArray;
      if (data.memberArray.length == 1) {
        store.dispatch("updateMemberSelected", {
          name: data.memberSelected.name,
          id: data.memberSelected.id,
        });
      }

      if (store.state.login.logged) {
        router.push("/");
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
}
export default loginToSite;
