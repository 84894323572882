import store from "@/store/index";
import axios from "axios";

async function uploadMembers(members) {
  try {
    members.sort(function (x, y) {
      return x.family.editable === y.family.editable
        ? 0
        : x.family.editable
        ? -1
        : 1;
    });

    let jsonData = {
      siteid: store.state.login.siteid,
      url: store.state.login.siteurl,
      members,
    };
    store.dispatch("updateUploadingPercentage", {
      current: 0,
      total: 1,
    });
    let response = await axios.post("api/members/add", jsonData, {
      headers: { "Content-Type": "application/json" },
      maxBodyLength: 100000000,
      maxContentLength: 100000000,
    });
    store.dispatch("updateUploadingPercentage", {
      current: 1,
      total: 1,
    });
    if (response.data.error) {
      return { error: true, message: response.data.message };
    } else {
      return { error: false };
    }

    // members.forEach((member, i) => {
    //   setTimeout(async () => {
    //     store.dispatch("updateUploadingPercentage", {
    //       current: i,
    //       total: members.length,
    //     });
    //     let jsonData = {
    //       siteid: store.state.login.siteid,
    //       url: store.state.login.siteurl,
    //       member,
    //     };
    //     await axios.post("api/members/add", jsonData, {
    //       headers: { "Content-Type": "application/json" },
    //     });

    //     if (i == members.length - 1) {
    //       return "Done";
    //     }
    //   }, i * 500);
    // });
  } catch (err) {
    // Handle Error Here
    console.error(err);
    return { error: true, message: "Please try again later" };
  }
}
export default uploadMembers;
