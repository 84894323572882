import axios from "axios";
import store from "@/store/index";

function load_fc() {
    let jsonData = {
        url: store.state.login.siteurl,
    };
    axios
        .post("api/load_fc", jsonData, {
            headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
            let fcs = response.data.fcs;

            fcs.sort(function(a, b) {
                if (a["1"] < b["1"]) {
                    return -1;
                }
                if (a["1"] > b["1"]) {
                    return 1;
                }

                return 0;
            });

            fcs.unshift({ id: "", 1: "" });

            store.state.finance_categories = fcs.map((fc) => {
                return { id: fc["id"], name: fc["1"] };
            });
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
}
export default load_fc;