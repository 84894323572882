<template>
  <div class="filter_box">
    <div  class="age filter">
      <label @click="toggleAgeFilter" style="cursor: pointer;user-select: none;" for="">{{ this.ageFilter ? 'Age' : 'DOB'}}          <font-awesome-icon
            size="xs"
            icon="arrows-rotate"
            style="cursor: pointer"
          />
        </label>
      <div class="control" v-if="ageFilter">
        <div class="age-input">
          <div class="field from">
            <span>From</span>
            <input
              type="number"
              class="input-min"
              :value="from_value"
              @keyup="updateFrom($event)"
              min="0"
              max="100"
            />
          </div>
          <div class="field to">
            <span>To</span>
            <input
              type="number"
              class="input-max"
              :value="to_value"
              @keyup="updateTo($event)"
              min="1"
              max="100"
            />
          </div>
        </div>
      </div>
      <div class="control" v-else>
        <div class="age-input">
          <div class="field from">
            <span>From</span>
            <input
              type="date"
              class="input-min"
              style="width: 140px"
              :value="from_value_dob"
              @change="updateFromDob"
            />
          </div>
          <div class="field to">
            <span>To</span>
            <input
              type="date"
              class="input-max"
              style="width: 140px"
              :value="to_value_dob"
              @change="updateToDob"
            />
          </div>
  
      </div>
    </div>

    </div>
    <div class="gender filter">
      <label for="">Gender</label>
      <div class="control">
        <select
          name=""
          id="gender"
          :value="gender_value"
          @change="genderChange($event)"
        >
          <option value="Any" selected>Any</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Other">Other</option>
        </select>
      </div>
    </div>
    <div class="gender filter" v-if="type == 'members' && root == 'category'">
      <label for="">No Member Category Only</label>
      <div class="control">
        <select
          name=""
          id="gender"
          :value="category_value"
          @change="categoryChange($event)"
        >
          <option value="Yes">Yes</option>
          <option value="No" selected>No</option>
        </select>
      </div>
    </div>
    <div @click="toggleFilter(false)" class="reset" v-if="active_value">
      <p>Reset Filter</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "FilterBox",
  props: ["show", "type", "root"],
  data() {
    return {
      filtertest: "0",
    };
  },
  computed: {
    active_value() {
      switch (this.type) {
        case "1":
          return this.$store.state.one_filter.active;
        case "2":
          return this.$store.state.two_filter.active;
        default:
          return this.$store.state.member_filter.active;
      }
    },
    gender_value() {
      switch (this.type) {
        case "1":
          return this.$store.state.one_filter.gender;
        case "2":
          return this.$store.state.two_filter.gender;
        default:
          return this.$store.state.member_filter.gender;
      }
    },
    category_value() {
      return this.$store.state.member_filter.category;
    },
    ageFilter() {
      switch (this.type) {
        case "1":
          return this.$store.state.one_filter.ageFilter;
        case "2":
          return this.$store.state.two_filter.ageFilter;
        default:
          return this.$store.state.member_filter.ageFilter;
      }
    },
    from_value() {
      switch (this.type) {
        case "1":
          return this.$store.state.one_filter.age.from;
        case "2":
          return this.$store.state.two_filter.age.from;
        default:
          return this.$store.state.member_filter.age.from;
      }
    },
    to_value() {
      switch (this.type) {
        case "1":
          return this.$store.state.one_filter.age.to;
        case "2":
          return this.$store.state.two_filter.age.to;
        default:
          return this.$store.state.member_filter.age.to;
      }
    },
    from_value_dob() {
      switch (this.type) {
        case "1":
          return this.$store.state.one_filter.dob.from;
        case "2":
          return this.$store.state.two_filter.dob.from;
        default:
          return this.$store.state.member_filter.dob.from;
      }
    },
    to_value_dob() {
      switch (this.type) {
        case "1":
          return this.$store.state.one_filter.dob.to;
        case "2":
          return this.$store.state.two_filter.dob.to;
        default:
          return this.$store.state.member_filter.dob.to;
      }
    },
  },

  methods: {
    toggleFilter(setting) {
      switch (this.type) {
        case "1":
          this.$store.state.one_filter.active = setting;
          if (!setting) {
            this.$store.state.one_filter.dob = {from: "1900-01-01", to: `${new Date().getFullYear()}-${String(new Date().getMonth()+1).padStart(2, '0')}-01`}
            this.$store.state.one_filter.age = { from: "0", to: "100" };
            this.$store.state.one_filter.gender = "Any";
          }
          break;
        case "2":
          this.$store.state.two_filter.active = setting;
          if (!setting) {
            this.$store.state.one_filter.dob = {from: "1900-01-01", to: `${new Date().getFullYear()}-${String(new Date().getMonth()+1).padStart(2, '0')}-01`}
            this.$store.state.two_filter.age = { from: "0", to: "100" };
            this.$store.state.two_filter.gender = "Any";
          }
          break;
        default:
          this.$store.state.member_filter.active = setting;
          if (!setting) {
            this.$store.state.member_filter.dob = {from: "1900-01-01", to: `${new Date().getFullYear()}-${String(new Date().getMonth()+1).padStart(2, '0')}-01`}
            this.$store.state.member_filter.age = { from: "0", to: "100" };
            this.$store.state.member_filter.gender = "Any";
            this.$store.state.member_filter.category = "No";
          }
          break;
      }
    },
    toggleAgeFilter() {
      switch (this.type) {
        case "1":
          this.$store.state.one_filter.ageFilter = !this.$store.state.one_filter.ageFilter 
          break;
        case "2":
          this.$store.state.two_filter.ageFilter = !this.$store.state.two_filter.ageFilter 
          break;
        default:
          this.$store.state.member_filter.ageFilter = !this.$store.state.member_filter.ageFilter
          break;
        }
    },
    updateFrom(e) {
      if (e.target.value) {
        this.toggleFilter(true);
        switch (this.type) {
          case "1":
            this.$store.state.one_filter.age.from = e.target.value;
            break;
          case "2":
            this.$store.state.two_filter.age.from = e.target.value;
            break;
          default:
            this.$store.state.member_filter.age.from = e.target.value;
            break;
        }
      } else {
        e.target.value = "";
      }
    },
    updateTo(e) {
      this.toggleFilter(true);

      if (e.target.value) {
        switch (this.type) {
          case "1":
            this.$store.state.one_filter.age.to = e.target.value;
            break;
          case "2":
            this.$store.state.two_filter.age.to = e.target.value;
            break;
          default:
            this.$store.state.member_filter.age.to = e.target.value;
            break;
        }
      } else {
        e.target.value = "";
      }
    },
    changedDob() {

    },
    updateFromDob(e) {
      if (e.target.value) {
        this.toggleFilter(true);
        switch (this.type) {
          case "1":
            this.$store.state.one_filter.dob.from = e.target.value;
            break;
          case "2":
            this.$store.state.two_filter.dob.from = e.target.value;
            break;
          default:
            this.$store.state.member_filter.dob.from = e.target.value;
            break;
        }
      } else {
        e.target.value = "";
      }
    },
    updateToDob(e) {
      this.toggleFilter(true);

      if (e.target.value) {
        switch (this.type) {
          case "1":
            this.$store.state.one_filter.dob.to = e.target.value;
            break;
          case "2":
            this.$store.state.two_filter.dob.to = e.target.value;
            break;
          default:
            this.$store.state.member_filter.dob.to = e.target.value;
            break;
        }
      } else {
        e.target.value = "";
      }
    },
    genderChange(e) {
      this.toggleFilter(true);

      switch (this.type) {
        case "1":
          this.$store.state.one_filter.gender = e.target.value;
          break;
        case "2":
          this.$store.state.two_filter.gender = e.target.value;
          break;
        default:
          this.$store.state.member_filter.gender = e.target.value;
          break;
      }
    },
    categoryChange(e) {
      this.toggleFilter(true);
      this.$store.state.member_filter.category = e.target.value;
    },
  },
  watch: {
  to_value_dob() {
    console.log("Changed!");
  }
}
};
</script>
<style scoped>
.filter_box {
  position: absolute;
  background: white;
  padding: 10px;
  border-radius: 8px;
  right: 0;
  top: 0;
  margin-top: 30px;
  border: 1px rgb(230, 230, 230) solid;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
  z-index: 1000;
}
.filter_box > .filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
  margin: 10px 0px;
}

.filter_box > .filter label {
  font-size: large;
  font-weight: bold;
  color: var(--blue);
  opacity: 0.7;
  border-radius: 4px;
}
.filter_box .control {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 0px;
  align-items: center;
}

.filter_box .control > span {
  background: #f4f4f4;
  padding: 10px;
}

.filter_box .control select {
  width: 100%;
  border: none;
  padding: 5px;
  border-radius: 5px;
}

.age-input {
  width: 100%;
  display: flex;
  margin: 0 10px;
}
.age-input .field {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.from {
  margin-right: 5px;
}
.to {
  margin-left: 5px;
}

.field span {
  width: 100%;
  text-align: left;
  opacity: 0.4;
  font-size: small;
  margin: 2px 0px;
}

.field input {
  opacity: 0.9;
  width: 100px;
  outline: none;  
  font-size: large;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #999;
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.reset {
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.reset p {
  background: var(--blue);
  opacity: 0.7;
  margin: 0;
  padding: 8px;
  border-radius: 5px;
  color: white;
}
.reset p:hover {
  opacity: 1;
}


</style>
