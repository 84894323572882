<template>
  <div class="contain">
    <div v-if="showHelp" class="help">
      <div class="box">
        <div class="header">
          <div class="title">CSV Help</div>
          <font-awesome-icon
            size="lg"
            icon="circle-xmark"
            style="cursor: pointer"
            @click="handleHelp"
          />
        </div>
        <div class="text">
          Follow the steps below to use the CSV tool to change Member Category
          Assignments
          <ol>
            <li>- Download the template CSV</li>
            <li>
              - Edit the CSV
              <ul>
                <li>
                  - To change a member category, write the exact name of the
                  category in column D
                </li>
                <li>- To remove a member category, leave column D blank</li>
                <li>
                  - To ignore a member, either delete the row from the CSV or
                  write IGNORE in column D
                </li>
              </ul>
            </li>
            <li>- Upload the CSV</li>
            <li>- Confirm all changes are correct and click Confirm</li>
          </ol>
          <a
            target="_blank"
            href="https://help.clubbuzz2.co.uk/member-categories-bulk"
            >Watch our video help guide here</a
          >
        </div>
      </div>
    </div>
    <div v-if="showConfirm" class="confirm">
      <div class="box">
        <div class="types">
          <div class="type change">
            <div class="header">
              <div class="info">
                <h3>Changes</h3>
                <p>The following members categories will be changed</p>
              </div>
              <font-awesome-icon
                size="lg"
                icon="circle-check"
                color="#26C281"
              />
            </div>
            <div>
              <div v-for="(row, i) in changes" :key="i">
                <div class="group">
                  <p class="name">{{ row.name }}</p>
                  <p class="category">{{ row.category }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="type delete">
            <div class="header">
              <div class="info">
                <h3>Deleting</h3>
                <p>The following member's categories will be removed.</p>
              </div>
              <font-awesome-icon
                size="lg"
                icon="circle-xmark"
                color="#C91F37"
              />
            </div>
            <div>
              <div v-for="(row, i) in deleting" :key="i">
                <div class="group">
                  <p class="name">{{ row.name }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="type unsure" v-if="errors.length > 0">
            <div class="header">
              <div class="info">
                <h3>Errors</h3>
                <p>We were unable to find these categories</p>
              </div>
              <font-awesome-icon
                size="lg"
                icon="circle-question"
                color="#FFA400"
              />
            </div>
            <div>
              <div v-for="(row, i) in errors" :key="i">
                <div class="group">
                  <p class="name">{{ row.name }}</p>
                  <p class="category">{{ row.category }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="buttons">
          <button class="create" @click="handleConfirm">Confirm</button>
          <button class="cancel" @click="handleCancel">Cancel</button>
        </div>
      </div>
    </div>
    <div class="menu">
      <div class="left">
        <router-link to="/">
          <img src="@/assets/logo.png" alt="" />
        </router-link>

        <div
          class="icon"
          :class="{ activated: checkedmembers.length > 0 }"
          @click="handleInitialMove"
        >
          {{ this.moveText }}
          <font-awesome-icon
            style="margin-left: 4px"
            icon="arrows-up-down-left-right"
          />
        </div>
        <div
          class="icon"
          :class="{
            activated: showDelete || checkedmembers.length > 0,
            hovering: hoverDelete,
          }"
          @dragenter="handleDragEnter_delete"
          @dragleave="handleDragLeave_delete"
          @dragover="handleDragOver"
          @drop="handleDrop_delete"
          @click="handleDelete"
        >
          Delete
          <font-awesome-icon style="margin-left: 4px" icon="trash-can" />
        </div>
        <div
          class="icon"
          :class="{ activated: lastAction.members.length != 0 }"
          @click="handleUndo"
        >
          Undo
          <font-awesome-icon style="margin-left: 4px" icon="rotate-left" />
        </div>
        <div
          class="icon members"
          :class="{ activated: showMembers }"
          @click="handleMembers"
        >
          Members
          <font-awesome-icon icon="user-group" />
        </div>

        <div
          class="icon selection"
          :class="{ activated: this.checkedmembers.length > 0 }"
          @click="uncheckAll"
        >
          <span id="select">{{ this.checkedmembers.length }} Selected</span>
          <span id="deselect">Deselect all</span>
          <font-awesome-icon icon="check" />
        </div>
      </div>
      <div class="right">
        <div class="icon activated" @click="handleDownload">
          Download Template
          <font-awesome-icon style="margin-left: 4px" icon="download" />
        </div>

        <label for="upload-file">
          <div class="icon activated">
            Upload CSV
            <font-awesome-icon style="margin-left: 4px" icon="upload" />
          </div>
        </label>
        <input
          type="file"
          name="image"
          id="upload-file"
          @change="handleCSV"
          ref="myFiles"
          accept=".csv"
        />

        <div class="icon activated" @click="handleHelp">
          Help
          <font-awesome-icon style="margin-left: 4px" icon="circle-question" />
        </div>
      </div>
    </div>

    <div class="category-bar">
      <div
        class="category"
        :class="{
          activated:
            this.$store.state.category1_info.name == category.name ||
            this.$store.state.category2_info.name == category.name,
          pick: moveText != 'Move',
        }"
        v-for="(category, i) in this.$store.state.categories"
        :key="i"
        @click="handleCatClick(category)"
      >
        {{ category.name }}
      </div>
    </div>

    <UploadingBox text="Categories" />

    <div class="canvas">
      <div
        class="empty"
        v-if="
          !this.$store.state.category1_info.name &&
          !this.$store.state.category2_info.name &&
          !showMembers
        "
      >
        Welcome to Member Category Assignment <br />
        <span>Select a member category from the category bar at the top</span>
      </div>
      <div
        class="category"
        :id="this.$store.state.category1_info.id"
        @dragenter="handleDragEnter"
        @dragleave="handleDragLeave"
        @dragover="handleDragOver"
        @drop="handleDrop"
        v-if="this.$store.state.category1_info.name"
      >
        <div class="action_bar">
          <h2>{{ this.$store.state.category1_info.name }}</h2>
          <div class="actions">
            <font-awesome-icon
              style="margin-left: 10px"
              icon="circle-check"
              size="lg"
              @click="selectAll(this.$store.state.category1_info.id)"
            />
            <font-awesome-icon
              style="margin-left: 10px"
              icon="filter"
              size="lg"
              :class="{
                on: category1Filter,
              }"
              @click="handleFilter('1')"
            />
            <FilterBox v-if="show1Filter" type="1" root="category" />
          </div>
        </div>

        <div class="members">
          <p v-if="this.$store.state.category1_info.members.length == 0">
            This category has no members
          </p>
          <div
            class="member"
            v-for="(member, i) in this.$store.state.category1_info.members"
            :key="i"
            :id="member.id"
            draggable="true"
            @dragstart="handleDragStart"
            @dragend="handleDragEnd"
            @dragover="handleDragOver"
          >
            <label
              class="container"
              :class="{ hide: filter_match(member, '1') }"
              >{{ member.name }}
              <input type="checkbox" @change="singleCheck($event)" />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <p class="amount">
          {{ this.$store.state.category1_info.members.length }} members
        </p>
      </div>

      <div
        class="category"
        @dragenter="handleDragEnter"
        @dragleave="handleDragLeave"
        @dragover="handleDragOver"
        @drop="handleDrop"
        :id="this.$store.state.category2_info.id"
        v-if="this.$store.state.category2_info.name"
      >
        <div class="action_bar">
          <h2>{{ this.$store.state.category2_info.name }}</h2>
          <div class="actions">
            <font-awesome-icon
              style="margin-left: 10px"
              icon="circle-check"
              size="lg"
              @click="selectAll(this.$store.state.category2_info.id)"
            />
            <font-awesome-icon
              style="margin-left: 10px"
              icon="filter"
              size="lg"
              :class="{ on: category2Filter }"
              @click="handleFilter('2')"
            />
            <FilterBox v-if="show2Filter" type="2" root="category" />
          </div>
        </div>
        <div class="members">
          <p v-if="this.$store.state.category2_info.members.length == 0">
            This category has no members
          </p>
          <div
            class="member"
            v-for="(member, i) in this.$store.state.category2_info.members"
            :key="i"
            :id="member.id"
            draggable="true"
            @dragstart="handleDragStart"
            @dragend="handleDragEnd"
          >
            <label
              class="container"
              :class="{ hide: filter_match(member, '2') }"
              >{{ member.name }}
              <input type="checkbox" @change="singleCheck($event)" />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <p class="amount">
          {{ this.$store.state.category2_info.members.length }} members
        </p>
      </div>

      <div class="category" id="members" v-if="showMembers">
        <div class="action_bar">
          <h2>Members</h2>
          <div class="actions">
            <font-awesome-icon
              style="margin-left: 10px"
              icon="circle-check"
              size="lg"
              @click="selectAll('members')"
            />
            <font-awesome-icon
              style="margin-left: 10px"
              icon="filter"
              size="lg"
              :class="{ on: memberFilter }"
              @click="handleFilter('members')"
            />
            <FilterBox v-if="showMemberFilter" type="members" root="category" />
          </div>
        </div>
        <div class="members">
          <div
            class="member"
            v-for="(member, i) in this.$store.state.members"
            :key="i"
            :id="member.id"
            draggable="true"
            @dragstart="handleDragStart"
            @dragend="handleDragEnd"
          >
            <label
              class="container"
              :class="{ hide: filter_match(member, 'member') }"
              >{{ member.name }}
              <input type="checkbox" @change="singleCheck($event)" />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FilterBox from "@/components/FilterBox.vue";
import load_members from "@/requests/load_members";

import UploadingBox from "@/components/UploadingBox.vue";
import change from "@/requests/category_assignment/ChangeCategory";

import load_categories from "@/requests/load_categories";
import router from "@/router/index";
import { onBeforeMount } from "vue";

import similarity from "@/compareStrings";
import { saveAs } from "file-saver";

export default {
  name: "CategoryAssignmentView",
  components: {
    FilterBox,
    UploadingBox,
  },
  data() {
    return {
      member_filter: {
        change: 0,
      },
      categoryDragging: "",
      source: "",
      showMembers: false,
      showDelete: false,
      hoverDelete: false,
      lastAction: { type: "", members: [], from: "", to: "" },
      checkedmembers: [],
      moveText: "Move",
      showMemberFilter: false,
      show1Filter: false,
      show2Filter: false,
      showHelp: false,
      showConfirm: false,
      changes: [],
      deleting: [],
      errors: [],
    };
  },
  computed: {
    memberFilter() {
      return this.$store.state.member_filter.active;
    },
    category1Filter() {
      return this.$store.state.one_filter.active;
    },
    category2Filter() {
      return this.$store.state.two_filter.active;
    },
  },
  methods: {
    delayLoop(fn, delay) {
      return (x, i) => {
        setTimeout(() => {
          fn(x);
        }, i * delay);
      };
    },
    handleDownload() {
      // Get all members Entry, Name, DOB, Member Cat
      // Empty array for storing the values
      let csvRows = [];

      // Header Row
      let headers = ["Entry ID,Name,DOB,Member Category"];
      csvRows.push(headers.join(","));

      // CSV Values
      this.$store.state.members.forEach((member) => {
        const values = `${member.id},${member.name},${member.dob},${member.mc_fc}`;
        csvRows.push(values);
      });
      // Join the array by newline
      csvRows = csvRows.join("\n");

      // Create CSV and Send to download
      const blob = new Blob([csvRows], { type: "text/csv" });
      saveAs(blob, "TemplateMembers.csv");
    },
    handleCSV() {
      this.showConfirm = false;
      this.changes = [];
      this.deleting = [];
      this.errors = [];
      if (this.$refs.myFiles) {
        let file = this.$refs.myFiles.files[0];

        const reader = new FileReader();
        reader.onload = (e) => {
          let converted = [];
          let str = e.target.result;
          const result = str.split(/\r?\n/);
          result.forEach((line) => {
            let row = line.split(",");

            for (let index = 0; row.length < 4; index++) {
              row.push("");
            }

            if (
              JSON.stringify(row) != '["","","",""]' &&
              row[3].toLowerCase() != "ignore"
            ) {
              converted.push(row);
            }
          });

          if (converted.length > 0) {
            if (converted[0][0].toLowerCase().includes("id")) {
              converted.shift();
            }
          }
          this.handleReassign(converted);
          // converted.forEach((row) => this.processRow(row, order));
        };
        reader.readAsText(file);
      }

      document.getElementById("upload-file").value = null;
    },
    handleReassign(rows) {
      // Split into blanks and changes

      let changes = [];

      rows.forEach((row) => {
        let member = this.$store.state.members.find(
          (member) => member.id == row[0]
        );

        if (row[3].trim() == "") {
          // Check if Category is different to existing MC

          if (member && member.mc != "") {
            this.deleting.push({
              id: row[0],
              name: row[1],
            });
          }
        } else {
          // Check if Category is different to existing MC

          if (member && member.mc != row[3].toLowerCase()) {
            changes.push({
              id: row[0],
              name: row[1],
              category: row[3],
            });
          }
        }
      });

      let categoryNames = this.$store.state.categories.map((category) => {
        return {
          name: category.name,
          name_lc: category.name.toLowerCase(),
        };
      });

      // Check Changes.
      changes.forEach((change) => {
        // Looking for match
        if (categoryNames.some(({ name }) => name == change.category)) {
          this.changes.push({
            id: change.id,
            name: change.name,
            category: change.category,
          });
        } else {
          let mostSimilar = {
            category: "",
            value: 0,
          };

          categoryNames.forEach((cn) => {
            if (
              similarity(cn.name_lc, change.category.toLowerCase()) >
              mostSimilar.value
            ) {
              mostSimilar.category = cn.name;
              mostSimilar.value = similarity(
                cn.name_lc,
                change.category.toLowerCase()
              );
            }
          });

          if (mostSimilar.value > 0.8) {
            this.changes.push({
              id: change.id,
              name: change.name,
              category: mostSimilar.category,
            });
          } else {
            this.errors.push({
              id: change.id,
              name: change.name,
              category: change.category,
            });
          }
        }
      });

      this.showConfirm = true;
    },
    handleConfirm() {
      // Handle Changes

      this.$store.state.uploading = true;
      this.$store.state.uploading_percentage = -1;
      var longest_wait = 1;
      if (this.changes.length > this.deleting.length) {
        longest_wait = this.changes.length * 750;
      } else {
        longest_wait = this.deleting.length * 750;
      }

      setTimeout(() => {
        this.$store.state.uploading = false;
      }, longest_wait);

      this.changes.forEach(
        this.delayLoop((c) => {
          change([c.id], c.category);
        }, 750)
      );
      // Handle Deletes

      let mappedDeletes = this.deleting.map((d) => d.id);
      change(mappedDeletes, "");

      // Clear Arrays
      this.showConfirm = false;
      this.changes = [];
      this.deleting = [];
      this.errors = [];
    },
    handleCancel() {
      this.showConfirm = false;
      this.changes = [];
      this.deleting = [];
      this.errors = [];
    },
    handleHelp() {
      this.showHelp = !this.showHelp;
    },
    filter_match(member, category) {
      // Check Gender

      var filter;
      if (category == "1") {
        filter = this.$store.state.one_filter;
      }

      if (category == "2") {
        filter = this.$store.state.two_filter;
      }

      if (category == "member") {
        filter = this.$store.state.member_filter;
      }

      if (filter.active) {
        if (filter.gender != "Any") {
          if (filter.gender != member.gender) {
            return true;
          }
        }

        if (filter.category == "Yes") {
          if (member.mc != "") {
            return true;
          }
        }





        

        // Get age from dob
        // month is 0-based, that's why we need dataParts[1] - 1
        var dob = new Date(member.dob);


       if (filter.ageFilter) {
         var diff_ms = Date.now() - dob;
         let age_dt = new Date(diff_ms);
         let age = Math.abs(age_dt.getUTCFullYear() - 1970);
         // Check if age is in range
         if (age < filter.age.from || age > filter.age.to) {
           return true;
         }
       } else {
          // Dob Filter
        if (isNaN(dob.getTime())) {
          return true;
        }

         if (dob < new Date(filter.dob.from) || dob > new Date(filter.dob.to)) {
           return true;
         } 
       }

      }

      return false;
    },
    singleCheck(e) {
      let target = e.target;
      let member_id = target.closest(".member").id.trim();

      let member = this.$store.state.members.find(
        (member) => member.id == member_id
      );

      let member_obj = {
        id: member_id,
        name: member.name,
        dob: member.dob,
        gender: member.gender,
      };

      let category = target.closest(".category").id.trim();
      if (target.checked) {
        this.checkedmembers.push({
          category: category,
          member: member_obj,
        });
      } else {
        let index = this.checkedmembers.findIndex(
          (value) => member_obj == value.member && category == value.category
        );
        this.checkedmembers.splice(index, 1);
      }
    },
    logLastAction(type, members) {
      this.lastAction = { type: type, members: members };
    },
    selectAll(id) {
      document
        .getElementById(id)
        .querySelectorAll(`.member .container:not(.hide)`)
        .forEach((container) => {
          let checkbox = container.parentElement.querySelector(
            "input[type='checkbox']"
          );
          if (checkbox) {
            checkbox.checked = true;
          }
        });



      document
        .getElementById(id)
        .querySelectorAll(`.member .container:not(.hide)`)
        .forEach((e) => {
          let member_id = e.parentElement.id.trim();
          
          let member = this.$store.state.members.find(
            (member) => member.id == member_id
          );

          let member_obj = {
            id: member_id,
            name: member.name,
            dob: member.dob,
            gender: member.gender,
          };

          if (
            !this.checkedmembers.some(
              ({ category, member }) => member.id == member_id && category == id
            )
          ) {
            this.checkedmembers.push({
              category: id,
              member: member_obj,
            });
          }
        });
    },
    uncheckAll() {
      this.checkedmembers = [];
      document
        .querySelectorAll("input[type=checkbox]")
        .forEach((el) => (el.checked = false));
    },
    handleFilter(box) {
      this.uncheckAll();
      switch (box) {
        case "1":
          this.show1Filter = !this.show1Filter;
          break;
        case "2":
          this.show2Filter = !this.show2Filter;
          break;
        default:
          this.showMemberFilter = !this.showMemberFilter;
          break;
      }
      // Show Filter Box
    },
    handleInitialMove() {
      // Select a category
      if (this.checkedmembers.length == 0) return;
      // Make all categories a new color
      // Change move text
      this.moveText = "Pick a category";
    },
    handleMove(category) {
      this.moveText = "Move";

      let index = this.$store.state.categories.findIndex(
        (search) => category.id == search.id
      );
      this.$store.state.uploading = true;
      this.$store.state.uploading_percentage = -1;

      setTimeout(() => {
        this.$store.state.uploading = false;
      }, this.checkedmembers.length * 750);

      this.checkedmembers.forEach(
        this.delayLoop((value) => {
          if (
            !this.$store.state.categories[index].members.some(
              ({ id }) => id == value.member.id
            )
          ) {
            change([value.member.id], this.$store.state.categories[index].name);
            // this.$store.state.categories[index].members.push(value.member);

            if (value.category != "members") {
              let originalIndex = this.$store.state.categories.findIndex(
                (search) => value.category == search.id
              );

              this.$store.state.categories[originalIndex].members =
                this.$store.state.categories[originalIndex].members.filter(
                  (filterValue) => filterValue.id != value.member.id
                );
            }
          }
        }, 750)
      );

      let logmembers = this.checkedmembers.map((x) => ({
        member: x.member,
        from: x.category,
        to: category.id,
      }));

      this.logLastAction("move", logmembers);

      this.uncheckAll();
    },
    handleDelete() {
      this.$store.state.uploading = true;
      this.$store.state.uploading_percentage = -1;

      setTimeout(() => {
        this.$store.state.uploading = false;
      }, this.checkedmembers.length * 750);
      this.checkedmembers.forEach(
        this.delayLoop((value) => {
          if (value.category != "members") {
            let originalIndex = this.$store.state.categories.findIndex(
              (search) => value.category == search.id
            );

            change([value.member.id], "");
            this.$store.state.categories[originalIndex].members =
              this.$store.state.categories[originalIndex].members.filter(
                (filterValue) => filterValue.id != value.member.id
              );
          }
        }, 750)
      );

      let logmembers = this.checkedmembers.map((x) => ({
        member: x.member,
        from: x.category,
        to: "",
      }));
      this.logLastAction("delete", logmembers);

      this.uncheckAll();
    },
    handleUndo() {
      switch (this.lastAction.type) {
        case "move":
          this.$store.state.uploading = true;
          this.$store.state.uploading_percentage = -1;

          setTimeout(() => {
            this.$store.state.uploading = false;
          }, this.lastAction.members.length * 750);

          this.lastAction.members.forEach(
            this.delayLoop((member) => {
              if (member.from != "members") {
                var ogIndex = this.$store.state.categories.findIndex(
                  (category) => category.id == member.from
                );

                change(
                  [member.member.id],
                  this.$store.state.categories[ogIndex].name
                );
                // this.$store.state.categories[ogIndex].members.push(member.member);
              }

              var categoryIndex = this.$store.state.categories.findIndex(
                (category) => category.id == member.to
              );

              let index = this.$store.state.categories[
                categoryIndex
              ].members.findIndex((item) => member.member == item);

              if (index != -1) {
                this.$store.state.categories[categoryIndex].members.splice(
                  index,
                  1
                );
              }
            }, 750)
          );

          this.lastAction = { type: "", members: [], from: "", to: "" };
          break;
        case "delete":
          this.$store.state.uploading = true;
          this.$store.state.uploading_percentage = -1;

          setTimeout(() => {
            this.$store.state.uploading = false;
          }, this.lastAction.members.length * 750);
          this.lastAction.members.forEach(
            this.delayLoop((member) => {
              var deletedCategoryIndex = this.$store.state.categories.findIndex(
                (category) => category.id == member.from
              );

              change(
                [member.member.id],
                this.$store.state.categories[deletedCategoryIndex].name
              );
              // this.$store.state.categories[deletedCategoryIndex].members.push(
              //   member.member
              // );
            }, 750)
          );
          this.lastAction = { type: "", members: [], from: "", to: "" };

          break;
        default:
          break;
      }
    },
    handleMembers() {
      this.showMembers = !this.showMembers;
    },
    handleCatClick(category) {
      if (this.moveText != "Move") {
        this.handleMove(category);
      } else {
        this.activateCategory(category);
      }
    },
    // Activate Category
    activateCategory(category) {
      if (this.$store.state.category1_info == category) {
        this.$store.state.category1_info = {};
        return;
      }

      if (this.$store.state.category2_info == category) {
        this.$store.state.category2_info = {};
        return;
      }

      category.members = this.$store.state.members.filter((member) => {
        return member["mc"] == category.name.toLowerCase();
      });

      if (this.$store.state.category1_info.name) {
        this.$store.state.category2_info = category;
        return;
      } else {
        this.$store.state.category1_info = category;
      }
    },
    // Drag and Drop
    handleDragStart(e) {
      let source = e.srcElement;
      source.style.opacity = 0.4;
      this.categoryDragging = source.closest(".category").id;
      if (this.categoryDragging != "members") {
        this.showDelete = true;
      }
      let member = this.$store.state.members.find(
        (member) => member.id == source.id
      );

      let member_obj = {
        id: source.id,
        name: member.name,
        dob: member.dob,
        gender: member.gender,
      };
      this.source = member_obj;
      e.dataTransfer.effectAllowed = "move";
    },

    handleDragEnd(e) {
      let source = e.srcElement;
      source.style.opacity = 1;

      this.showDelete = false;
      this.hoverDelete = false;
      for (let elem of document.getElementsByClassName("over")) {
        elem.classList.remove("over");
      }
    },

    handleDragOver(e) {
      e.preventDefault();
      return false;
    },

    handleDragEnter(e) {
      let target = e.target;
      let parentWithClass = target.closest(".category");
      if (parentWithClass.id != this.categoryDragging) {
        parentWithClass.classList.add("over");
      }
    },

    handleDragLeave(e) {
      let target = e.target;
      target.classList.remove("over");
    },
    handleDrop(e) {
      e.stopPropagation(); // stops the browser from redirecting.
      let target = e.target;

      let parentWithClass = target.closest(".category");
      if (parentWithClass.id != this.categoryDragging) {
        if (parentWithClass.id == this.$store.state.category1_info.id) {
          // Check Category 1 does not have it already first
          if (
            this.$store.state.category1_info.members.some(
              ({ id }) => id == this.source.id
            )
          ) {
            return;
          }
          change([this.source.id], this.$store.state.category1_info.name);
          // this.$store.state.category1_info.members.push(this.source);
          if (this.categoryDragging != "members") {
            this.$store.state.category2_info.members =
              this.$store.state.category2_info.members.filter(
                (member) => member.id.trim() != this.source.id.trim()
              );
          }
        } else {
          // Check Category 2 does not have it already first
          if (
            this.$store.state.category2_info.members.some(
              ({ id }) => id == this.source.id
            )
          ) {
            return;
          }
          change([this.source.id], this.$store.state.category2_info.name);
          // this.$store.state.category2_info.members.push(this.source);
          if (this.categoryDragging != "members") {
            this.$store.state.category1_info.members =
              this.$store.state.category1_info.members.filter(
                (member) => member.id.trim() != this.source.id.trim()
              );
          }
        }

        this.logLastAction("move", [
          {
            member: this.source,
            from: this.categoryDragging,
            to: parentWithClass.id,
          },
        ]);
        this.uncheckAll();
      }

      return false;
    },

    // Drag to Delete
    handleDragEnter_delete() {
      this.hoverDelete = true;
    },

    handleDragLeave_delete() {
      this.hoverDelete = false;
    },
    handleDrop_delete(e) {
      e.stopPropagation(); // stops the browser from redirecting.

      // Delete
      change([this.source.id], "");

      if (this.categoryDragging == this.$store.state.category1_info.id) {
        this.$store.state.category1_info.members =
          this.$store.state.category1_info.members.filter(
            (member) => member.id.trim() != this.source.id.trim()
          );
      } else {
        this.$store.state.category2_info.members =
          this.$store.state.category2_info.members.filter(
            (member) => member.id.trim() != this.source.id.trim()
          );
      }

      this.logLastAction("delete", [
        {
          member: this.source,
          from: this.categoryDragging,
          to: "",
        },
      ]);

      return false;
    },
  },
  mounted() {
    load_members();
    load_categories();
  },
  created() {
    onBeforeMount(async () => {
      if (!this.$store.state.login.logged) {
        router.replace("/login");
      }
      if (
        !this.$store.state.allowedRoles.categories.includes(
          this.$store.state.login.role
        )
      ) {
        router.replace("/");
      }
    });
  },
};
</script>
<style scoped>
.contain {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.category-bar {
  background: var(--grey);
  display: flex;
  width: 100%;
  height: 50px;
  padding: 0px 10px;
  overflow-x: auto;
  overflow-y: hidden;
}

.category-bar .category {
  background: var(--blue);
  opacity: 0.5;
  color: white;
  width: fit-content;
  padding: 5px 10px;
  margin: 10px 5px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  white-space: nowrap;
}

.hide {
  display: none !important;
}

.menu {
  background: var(--grey);
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #dedede;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
}

.left,
.right {
  display: flex;
  align-items: center;
}

.menu .icon {
  margin: 5px 5px;
  align-items: center;
  padding: 5px 15px;
  background: rgb(232, 232, 232);
  opacity: 0.4;
  border-radius: 5px;
  height: 35px;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.menu .members {
  cursor: pointer;
}

.menu img {
  margin: 0 10px;
  width: 35px;
  height: 35px;
}
.activated {
  opacity: 1 !important;
  cursor: pointer;
}

.menu .selection {
  cursor: pointer;
}
.menu .selection #select {
  display: inline-block;
  margin-right: 4px;
}

.menu .selection:hover #select {
  display: none;
}

.menu .selection #deselect {
  display: none;
}

.menu .selection:hover #deselect {
  display: inline-block;
  margin-right: 4px;
}

.hovering {
  background: red !important;
  color: white !important;
}

.pick {
  background: red !important;
  opacity: 1 !important;
}

.empty {
  margin-top: -15%;
  font-size: larger;
}
.empty span {
  opacity: 0.4;
  font-size: medium;
}
.canvas {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.canvas .category {
  background: var(--grey);
  width: 100%;
  border-radius: 5px;
  height: 85%;
  margin: 0px 10px;
  margin-top: -100px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.category .amount {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 10px;
  margin-bottom: 5px;
  font-size: smaller;
  opacity: 0.6;
  font-weight: bold;
}

.canvas .category h2 {
  display: flex;
  text-align: left;
  color: black;
}

.canvas .category .members {
  overflow-y: scroll;
  /* margin-bottom: 25px; */
}

.category .action_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 10px 10px;
  position: relative;
}
.category .action_bar p,
.category .action_bar h2 {
  margin: 0;
}

.category .action_bar .actions {
  display: flex;
}

.category .action_bar .actions > :not(.filter_box) {
  cursor: pointer;
  opacity: 0.7;
}

.category .action_bar .actions > *:hover {
  opacity: 1;
}

.on {
  opacity: 1;
  color: var(--blue);
}

.members .member {
  margin: 5px 10px;
  background: white;
  border-radius: 3px;
  /*  */
  text-align: left;
  cursor: move;
}

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding: 5px 0px 5px 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Drag CSS */
.over {
  border: 3px solid var(--blue);
}

/* Checkbox CSS */
/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #eee;
  border-radius: 3px;
  margin-top: 7px;
  margin-left: 6px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: var(--blue);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.contain .help {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.help .box {
  position: absolute;
  z-index: 1;
  max-width: 500px;
  background: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 30px;
}
.help .box .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.help .box .title {
  font-size: x-large;
  font-weight: 600;
}

.help .box .text {
  text-align: left;
}
.help .box .text ol {
  margin: 15px 0px;
}
.help .box .text ul {
  margin-left: 30px;
}

#upload-file {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.contain .confirm {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm .box {
  position: absolute;
  z-index: 1;
  background: rgba(255, 255, 255, 1);
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 30px;
  overflow: hidden;
}

.confirm .box .types {
  display: flex;
}

.confirm .type {
  min-width: 25vw;
  max-width: 30vw;
  height: 70vh;
  text-align: left;
  overflow-y: scroll;
  margin: 0px 10px;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}
.type .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.type .header .info {
  line-height: 1.4em;
  padding-right: 20px;
}
.type .header .info p {
  opacity: 0.6;
  font-size: small;
}

.type h3 {
  font-size: x-large;
  font-weight: 600;
}

.group {
  background: WHITE;
  margin: 8px 0px;
  padding: 5px 8px;
  border-radius: 5px;
  line-height: 1em;
}
.group .name {
  font-weight: 500;
}
.group .category {
  opacity: 0.6;
  font-size: smaller;
}

.confirm .delete {
}
.confirm .unsure {
}

.buttons {
  display: flex;
  justify-content: flex-end;
}
.buttons button {
  width: 100px;
  height: 40px;
  color: white;
  border-radius: 5px;
  font-size: large;

  margin: 30px 10px 0px;
  justify-content: flex-end;
}

.buttons .create {
  background: var(--blue);
}

.buttons .cancel {
  background: #c9c9c9;
}
</style>
