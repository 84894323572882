<template>
  <div class="main">
    <header>
      <h1>404 Not Found</h1>
      <h2>The page you're searching for doesn't exist.</h2>
    </header>
    <main></main>
  </div>
</template>
<script>
export default {
  name: "NotFoundView",
};
</script>
<style scoped>
header {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 100vw;
  /* height: 250px; */
  padding: 20px;
  background: var(--blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: white;
}

header h1 {
  font-size: 64px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

header h2 {
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.main {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: #00000015;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
