<template>
  <div>
    <header>
      <h1>
        <img src="@/assets/white-swirl.png" class="cb2swirl" />ClubBuzz
        <span class="transparent">&nbsp;Import</span>
      </h1>
      <div>
        <div class="signOut" @click="signOut">
          <font-awesome-icon
            icon="right-from-bracket"
            @click="signOut()"
            size="lg"
          />
        </div>
      </div>
    </header>
    <main>
      <div class="cards">
        <div v-for="(card, i) in cards" :key="i">
          <router-link
            :to="card.link"
            v-if="card.allowedRoles.includes(this.$store.state.login.role)"
          >
            <div class="card">
              <div class="icon">
                <font-awesome-icon size="2x" color="white" :icon="card.icon" />
              </div>
              <div>
                <div class="title">{{ card.title }}</div>
                <div class="subheading">{{ card.sub }}</div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import router from "@/router/index";
import { onBeforeMount } from "vue";

export default {
  name: "HomeView",
  data() {
    return {
      cards: [
        {
          icon: "person-circle-plus",
          title: "Team Assignments",
          sub: "Change your team’s players quickly and easily",
          link: "teams",
          allowedRoles: this.$store.state.allowedRoles.team,
        },
        {
          icon: "calendar-plus",
          title: "Matches",
          sub: "Bulk import your match day fixtures",
          link: "matches",
          allowedRoles: this.$store.state.allowedRoles.match,
        },
        {
          icon: "calendar-days",
          title: "Training",
          sub: "Bulk import your training sessions",
          link: "training",
          allowedRoles: this.$store.state.allowedRoles.match,
        },
        {
          icon: "credit-card",
          title: "Charges",
          sub: "Bulk charge or credit your members accounts",
          link: "charge",
          allowedRoles: this.$store.state.allowedRoles.charges,
        },
        {
          icon: "users",
          title: "Members",
          sub: "Import multiple members at one time",
          link: "members",
          allowedRoles: this.$store.state.allowedRoles.members,
        },
        {
          icon: "users-rectangle",
          title: "Categories",
          sub: "Handle all your Membership Categories in one place",
          link: "categories",
          allowedRoles: this.$store.state.allowedRoles.categories,
        },
      ],
    };
  },
  methods: {
    signOut() {
      this.$store.dispatch("signOut");
      router.push("/login");
      // this.showMenu = !this.showMenu;
    },
  },
  created() {
    onBeforeMount(async () => {
      if (!this.$store.state.login.logged) {
        router.replace("/login");
      } else {
        router.replace("/");
      }
    });
  },
};
</script>

<style scoped>
header {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 100vw;
  height: 240px;
  background: var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
}

header h1 {
  font-size: 64px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

header img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

header .signOut {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
  cursor: pointer;
}

.transparent {
  opacity: 0.7;
}

.cards {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    justify-content: center;
    grid-template-columns: auto auto auto auto;
    gap: 50px 70px;
    
    margin: 100px 50px;
    display: inline-flex;
    flex-wrap:  wrap;

}

.card {
  background: var(--grey);
  width: 220px;
  height: 275px;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  border-radius: 10px;
  box-shadow: 5px 10px 4px #d4d4d4;
  cursor: pointer;
}
.card:hover {
  background: #e4e4e4;
}

.card .icon {
  background: var(--blue);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 40px;
}

.card .title {
  font-weight: 700;
  font-size: 20px;
}

.card .subheading {
  font-weight: 600;
  font-size: 16px;
  opacity: 0.2;
}

.deactivated {
  opacity: 0.2;
}
</style>
