<template>
  <div class="contain">
    <div v-if="showHelp" class="help">
      <div class="box">
        <div class="header">
          <div class="title">CSV Help</div>
          <font-awesome-icon
            size="lg"
            icon="circle-xmark"
            style="cursor: pointer"
            @click="handleHelp"
          />
        </div>
        <div class="text">
          Follow the steps below to use the CSV tool to add charges in bulk
          <ol>
            <li>- Download the template CSV</li>
            <li>
              - Edit the CSV
              <ul>
                <li>
                  - Column C, write 'Charge' or 'Credit' depending on what you'd
                  like to do.
                </li>
                <li>
                  - Column D, write the amount you'd like to charge. Do not
                  include any currency symbols.
                </li>
                <li>
                  - Column E, write the exact name of the finance category you'd
                  like to add the charge/credit to.
                </li>
                <li>- Column F, write some notes about the transaction.</li>
              </ul>
            </li>
            <li>- Upload the CSV</li>
            <li>- Make sure each column is correctly assigned and click Add</li>
          </ol>
          <h2>Tips</h2>
          <ol>
            <li>
              - To not add a charge to a member, either remove their row or just
              do not edit their row (ie: leave the rest of their colummns blank)
            </li>
            <li>
              - Do not seperate the ID from the Member Name. These 2 must be
              kept together to correctly import a charge
            </li>
          </ol>
          <a
            target="_blank"
            href="https://help.clubbuzz2.co.uk/charge-credit-bulk"
            >Watch our video help guide here</a
          >
        </div>
      </div>
    </div>
    <div v-if="this.assigning" class="overlay">
      <div class="assigning">
        <div class="bar">
          <h3>Assign Columns</h3>
          <div class="buttons">
            <button class="add" @click="handleAdd">Add</button>
            <button class="exit" @click="handleExit">Cancel</button>
          </div>
        </div>
        <div class="columns">
          <div class="column-a">
            <h4>Column 1</h4>
            <select
              class="input"
              v-model="column.a.selected"
              :class="{
                error: this.columnDuplicates.has(column.a.selected),
              }"
            >
              <option value="Entry ID">Entry ID</option>
              <option v-for="(column, i) in columns" :value="column" :key="i">
                {{ column }}
              </option>
            </select>
            <div class="data-preview">
              <div v-for="(text, i) in column.a.preview" :key="i">
                {{ text }}
              </div>
            </div>
          </div>
          <div class="column-b">
            <h4>Column 2</h4>
            <select
              class="input"
              v-model="column.b.selected"
              :class="{
                error: this.columnDuplicates.has(column.b.selected),
              }"
            >
              <option value="Entry ID">Entry ID</option>
              <option v-for="(column, i) in columns" :value="column" :key="i">
                {{ column }}
              </option>
            </select>
            <div class="data-preview">
              <div v-for="(text, i) in column.b.preview" :key="i">
                {{ text }}
              </div>
            </div>
          </div>
          <div class="column-c">
            <h4>Column 3</h4>
            <select
              class="input"
              v-model="column.c.selected"
              :class="{
                error: this.columnDuplicates.has(column.c.selected),
              }"
            >
              <option value="Entry ID">Entry ID</option>
              <option v-for="(column, i) in columns" :value="column" :key="i">
                {{ column }}
              </option>
            </select>
            <div class="data-preview">
              <div v-for="(text, i) in column.c.preview" :key="i">
                {{ text }}
              </div>
            </div>
          </div>
          <div class="column-d">
            <h4>Column 4</h4>
            <select
              class="input"
              v-model="column.d.selected"
              :class="{
                error: this.columnDuplicates.has(column.d.selected),
              }"
            >
              <option value="Entry ID">Entry ID</option>
              <option v-for="(column, i) in columns" :value="column" :key="i">
                {{ column }}
              </option>
            </select>
            <div class="data-preview">
              <div v-for="(text, i) in column.d.preview" :key="i">
                {{ text }}
              </div>
            </div>
          </div>
          <div class="column-e">
            <h4>Column 5</h4>
            <select
              class="input"
              v-model="column.e.selected"
              :class="{
                error: this.columnDuplicates.has(column.e.selected),
              }"
            >
              <option value="Entry ID">Entry ID</option>
              <option v-for="(column, i) in columns" :value="column" :key="i">
                {{ column }}
              </option>
            </select>
            <div class="data-preview">
              <div v-for="(text, i) in column.e.preview" :key="i">
                {{ text }}
              </div>
            </div>
          </div>
          <div class="column-f">
            <h4>Column 6</h4>
            <select
              class="input"
              v-model="column.f.selected"
              :class="{
                error: this.columnDuplicates.has(column.f.selected),
              }"
            >
              <option value="Entry ID">Entry ID</option>
              <option v-for="(column, i) in columns" :value="column" :key="i">
                {{ column }}
              </option>
            </select>
            <div class="data-preview">
              <div v-for="(text, i) in column.f.preview" :key="i">
                {{ text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UploadingBox text="Charges" />
    <div class="menu">
      <div class="left">
        <router-link to="/">
          <img src="@/assets/logo.png" alt="" />
        </router-link>
        <div class="icon new" @click="handleNew">
          New
          <font-awesome-icon style="margin-left: 4px" icon="plus" />
        </div>
        <div
          class="icon duplicate"
          @click="handleDuplicate"
          :class="{ activated: selected > 0 }"
        >
          Duplicate
          <font-awesome-icon style="margin-left: 4px" icon="copy" />
        </div>

        <div
          class="icon"
          @click="handleDelete"
          :class="{ activated: selected > 0 }"
        >
          Delete
          <font-awesome-icon style="margin-left: 4px" icon="trash-can" />
        </div>
        <div
          class="icon selection"
          :class="{ activated: selected > 0 }"
          @click="handleSelect"
        >
          <span id="select">{{ selected }} Selected</span>
          <span id="deselect">Deselect all</span>
          <font-awesome-icon icon="check" />
        </div>
        <div class="icon activated" @click="handleDownload">
          Download CSV
          <font-awesome-icon style="margin-left: 4px" icon="download" />
        </div>
        <label for="upload-file">
          <div class="icon paste" :class="{ activated: true }">
            Upload CSV
            <font-awesome-icon style="margin-left: 4px" icon="upload" />
          </div>
        </label>
        <input
          type="file"
          name="image"
          id="upload-file"
          @change="handleCSV"
          ref="myFiles"
          accept=".csv"
        />
        <div class="icon activated" @click="handleHelp">
          Help
          <font-awesome-icon style="margin-left: 4px" icon="circle-question" />
        </div>
      </div>
      <div class="right">
        <div
          class="import"
          @click="handleImport"
          :class="{ activated: this.rows.length > 0 }"
        >
          Import Charges
          <font-awesome-icon style="margin-left: 4px" icon="upload" />
        </div>
      </div>
    </div>
    <NewChargeBox :handleNew="handleNew" :handleNewCreate="handleNewCreate" />
    <DupChargeBox
      :createRow="this.createRow"
      :handleDuplicate="this.handleDuplicate"
      :handleSelect="this.handleSelect"
      :rows="this.rows"
    />
    <table class="table">
      <thead>
        <th>
          <font-awesome-icon
            style="margin: 4px; cursor: pointer"
            icon="check-circle"
            @click="selectAll"
          />
        </th>

        <th v-for="(column, i) in columns" :key="i" class="col-1">
          {{ column }}
        </th>
      </thead>
      <tbody>
        <tr v-for="(row, i) in this.rows" :key="i">
          <td @click="check($event, row.id)">
            <input
              type="checkbox"
              name="rowCheck"
              :id="row.id"
              @change="singleCheck()"
            />
          </td>
          <td
            class="cell col-1"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 1),
            }"
          >
            <div class="team">
              <select
                v-model="row.member_id"
                class="input"
                @change="changeMember(row.member_id, i)"
              >
                <option
                  :value="member.id"
                  v-for="(member, i) in this.$store.state.members"
                  :key="i"
                >
                  {{ member.name }}
                </option>
              </select>
            </div>
          </td>
          <td
            class="cell col-2"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 2),
            }"
          >
            <div class="">
              <select class="input" name="" id="" v-model="row.type">
                <option value="Charge">Charge</option>
                <option value="Credit">Credit</option>
              </select>
            </div>
          </td>
          <td
            class="cell col-3"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 3),
            }"
          >
            <div class="amount">
              <input
                class="input"
                type="number"
                step="0.01"
                min="0"
                v-model="row.amount"
              />
            </div>
          </td>
          <td
            class="cell col-4"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 4),
            }"
          >
            <div class="">
              <select class="input" name="" id="" v-model="row.category">
                <option
                  v-for="(fc, i) in this.$store.state.finance_categories"
                  :key="i"
                  :value="fc.name"
                >
                  {{ fc.name }}
                </option>
              </select>
            </div>
          </td>
          <td
            class="cell col-5"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 5),
            }"
          >
            <div>
              <input
                class="input"
                type="text"
                name=""
                id=""
                v-model="row.notes"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref } from "vue";
// import { initial_load } from "@/requests/load.js";
import load_members from "@/requests/load_members.js";
import load_fc from "@/requests/charges/load_fc.js";

import { onBeforeMount } from "vue";
import router from "@/router/index";
import { saveAs } from "file-saver";

import similarity from "@/compareStrings";
import uploadCharges from "@/requests/charges/UploadCharges";
import UploadingBox from "@/components/UploadingBox.vue";
import DupChargeBox from "@/components/charge/DupChargeBox.vue";
import NewChargeBox from "@/components/charge/NewChargeBox.vue";

import csvToArray from "@/csvToArray";

export default {
  name: "MatchView",
  setup() {
    const formatter = ref({
      date: "DD/MM/YYYY",
      month: "MMM",
    });

    return {
      formatter,
    };
  },
  components: {
    UploadingBox,
    NewChargeBox,
    DupChargeBox,
  },
  data() {
    return {
      selected: 0,
      rows: [
        {
          id: "default",
          name: "",
          type: "",
          amount: "",
          category: "",
          notes: "",
        },
      ],
      columns: ["Name", "Type", "Amount", "Finance Category", "Notes"],
      column: {
        a: { selected: "Entry ID", preview: [] },
        b: { selected: "Name", preview: [] },
        c: { selected: "Type", preview: [] },
        d: { selected: "Amount", preview: [] },
        e: { selected: "Finance Category", preview: [] },
        f: { selected: "Notes", preview: [] },
      },
      errors: [],
      assigning: false,
      convertedRows: [],
      columnDuplicates: new Set(),
      showHelp: false,
    };
  },

  computed: {},
  methods: {
    handleHelp() {
      this.showHelp = !this.showHelp;
    },
    handleDownload() {
      // Get all members Entry, Name, DOB, Member Cat
      // Empty array for storing the values
      let csvRows = [];

      // Header Row
      let headers = ["Entry ID,Name,Type,Amount,Finance Category,Notes"];
      csvRows.push(headers.join(","));

      // CSV Values
      this.$store.state.members.forEach((member) => {
        const values = `${member.id},${member.name}`;
        csvRows.push(values);
      });
      // Join the array by newline
      csvRows = csvRows.join("\n");

      // Create CSV and Send to download
      const blob = new Blob([csvRows], { type: "text/csv" });
      saveAs(blob, "TemplateChargeMembers.csv");
    },

    handleCSV() {
      if (this.$refs.myFiles) {
        let file = this.$refs.myFiles.files[0];

        const reader = new FileReader();
        reader.onload = (e) => {
          let converted = [];
          let str = e.target.result;
          // let test = '"one","two with escaped """" double quotes""","three, with, commas",four with no quotes,"five with CRLF\r\n"\r\n"2nd line one","two with escaped """" double quotes""","three, with, commas",four with no quotes,"five with CRLF\r\n"';
          const result = csvToArray(str);

          // const result = str.split(/\r?\n/);
          result.forEach((row) => {
            // console.log(line);
            // let row = line.split(",");

            for (let index = 0; row.length < 6; index++) {
              row.push("");
            }

            if (!JSON.stringify(row).endsWith(',"","","",""]')) {
              converted.push(row);
            }
          });
          if (converted.length > 0) {
            if (converted[0][0].toLowerCase().includes("id")) {
              converted.shift();
            }
          }

          this.handleAssign(converted);
          // converted.forEach((row) => this.processRow(row, order));
        };
        reader.readAsText(file);
      }

      document.getElementById("upload-file").value = null;
    },
    processRow(convertedRow) {
      var compare = {
        member: { id: "", name: "" },
        type: { name: "", value: 0 },
        category: { name: "", value: 0 },
      };

      if (convertedRow.length > 1) {
        let member_id = convertedRow[0].value;
        let member_name = convertedRow[1].value;

        // Get Predicted
        let predicted = this.$store.state.members.find((member) => {
          return member.id == member_id;
        });
        // Get Name
        if (predicted) {
          // Check above .9
          if (
            similarity(
              predicted.name.toLowerCase(),
              member_name.toLowerCase()
            ) > 0.8
          ) {
            compare.member.id = predicted.id;
            compare.member.name = predicted.name;
          }
        }
      }

      if (convertedRow.length > 2) {
        var type = convertedRow[2].value;
        ["Charge", "Credit"].forEach((t) => {
          if (similarity(t, type) > compare.type.value) {
            compare.type = {
              name: t,
              value: similarity(t, type),
            };
          }
        });
      }
      if (convertedRow.length > 3) {
        var amount = convertedRow[3].value;
      }
      if (convertedRow.length > 4) {
        var category = convertedRow[4].value;
        this.$store.state.finance_categories.forEach((fc) => {
          if (similarity(fc.name, category) > compare.category.value) {
            compare.category = {
              name: fc.name,
              value: similarity(fc.name, category),
            };
          }
        });
      }
      if (convertedRow.length > 5) {
        var notes = convertedRow[5].value;
      }
      let object = {
        member_id: compare.member.id,
        member_name: compare.member.name,
        type: compare.type.name,
        amount: amount,
        category: compare.category.name,
        notes: notes,
      };
      this.createRow(object);
    },
    async handleImport() {
      this.errors = [];
      // For Each Row
      this.rows.forEach((row, i) => {
        // Check Member based on ID
        if (!this.$store.state.members.some(({ id }) => id == row.member_id)) {
          this.errors.push({
            line: i,
            col: 1,
            message: `Please reselect the member on line ${i}`,
          });
        }
        // Check Type
        if (row.type.trim() != "Charge" && row.type.trim() != "Credit") {
          this.errors.push({
            line: i,
            col: 2,
            message: `Please specify the type on line ${i}`,
          });
        }
        // Check Amount
        if (!row.amount || isNaN(row.amount) || row.amount < 0) {
          this.errors.push({
            line: i,
            col: 3,
            message: `Please write a valid amount on line ${i}`,
          });
        }

        // Check FC
        if (
          !this.$store.state.finance_categories.some(
            ({ name }) => name == row.category
          )
        ) {
          this.errors.push({
            line: i,
            col: 4,
            message: `Please reselect the category on line ${i}`,
          });
        }
        // Check Notes

        if (row.notes == "") {
          this.errors.push({
            line: i,
            col: 5,
            message: `You must write a note on line ${i}`,
          });
        }
      });

      console.log(this.errors);
      if (this.errors.length == 0) {
        this.$store.state.uploading = true;
        setTimeout(() => {
          this.$store.state.uploading = false;
          this.rows = [];
          this.rows = [
            {
              id: "default",
              name: "",
              type: "",
              amount: "",
              category: "",
              notes: "",
            },
          ];
        }, this.rows.length * 1000);
        await uploadCharges(this.rows);
      }
    },
    // Handle Paste/Upload > Send Rows to Handle Assign > Add puts them in table
    handleAssign(convertedRows) {
      let top = convertedRows.slice(0, 5);
      top.forEach((row) => {
        if (row[0]) {
          this.column.a.preview.push(row[0]);
        } else {
          this.column.a.preview.push("-");
        }
        if (row[1]) {
          this.column.b.preview.push(row[1]);
        } else {
          this.column.b.preview.push("-");
        }
        if (row[2]) {
          this.column.c.preview.push(row[2]);
        } else {
          this.column.c.preview.push("-");
        }
        if (row[3]) {
          this.column.d.preview.push(row[3]);
        } else {
          this.column.d.preview.push("-");
        }
        if (row[4]) {
          this.column.e.preview.push(row[4]);
        } else {
          this.column.e.preview.push("-");
        }
        if (row[5]) {
          this.column.f.preview.push(row[5]);
        } else {
          this.column.f.preview.push("-");
        }
      });

      this.assigning = true;
      this.convertedRows = convertedRows;
    },
    handleAdd() {
      this.columnDuplicates = new Set();
      // Make sure all columns are unique
      for (const key in this.column) {
        for (const secondaryKey in this.column) {
          if (
            key != secondaryKey &&
            this.column[key].selected == this.column[secondaryKey].selected
          ) {
            this.columnDuplicates.add(this.column[key].selected);
          }
        }
      }

      if (this.columnDuplicates.size > 0) {
        alert("All columns must be unique");
        return;
      }

      // Get Order Values for the row
      let arrayWithOrderValues = this.convertedRows.map((row) => {
        row.forEach((value, index) => {
          let order = this.getOrderValue(index);

          row[index] = {
            value,
            order,
          };
        });
        return row;
      });

      // Sort rows on the order values
      arrayWithOrderValues.forEach((row) => {
        row.sort(function (x, y) {
          if (x.order < y.order) {
            return -1;
          }
          if (x.order > y.order) {
            return 1;
          }
          return 0;
        });
      });

      arrayWithOrderValues.forEach((row) => this.processRow(row));
      this.assigning = false;
      this.convertedRows = [];
      this.resetColumn();
    },
    handleExit() {
      this.assigning = false;
      this.convertedRows = [];
      this.resetColumn();
    },
    resetColumn() {
      this.column = {
        a: { selected: "Entry ID", preview: [] },
        b: { selected: "Name", preview: [] },
        c: { selected: "Type", preview: [] },
        d: { selected: "Amount", preview: [] },
        e: { selected: "Finance Category", preview: [] },
        f: { selected: "Notes", preview: [] },
      };
    },
    getOrderValue(index) {
      switch (index) {
        case 0:
          return this.getColumnValue(this.column.a.selected);
        case 1:
          return this.getColumnValue(this.column.b.selected);
        case 2:
          return this.getColumnValue(this.column.c.selected);
        case 3:
          return this.getColumnValue(this.column.d.selected);
        case 4:
          return this.getColumnValue(this.column.e.selected);
        case 5:
          return this.getColumnValue(this.column.f.selected);
        default:
          return -1;
      }
    },
    getColumnValue(selected) {
      switch (selected) {
        case "Entry ID":
          return 0;
        case "Name":
          return 1;
        case "Type":
          return 2;
        case "Amount":
          return 3;
        case "Finance Category":
          return 4;
        case "Notes":
          return 5;
        default:
          return -1;
      }
    },
    handleNew() {
      this.$store.state.showDuplicate = false;
      this.$store.state.chargeNew.member_id = null;
      this.$store.state.showNew = !this.$store.state.showNew;
    },
    handleDuplicate() {
      if (this.selected == 0) {
        this.$store.state.showDuplicate = false;
        return;
      }
      this.$store.state.showNew = false;
      this.$store.state.showDuplicate = !this.$store.state.showDuplicate;
    },
    handleDelete() {
      document.querySelectorAll(`input[name=rowCheck]:checked`).forEach((e) => {
        let index = this.rows.findIndex((row) => row.id == e.id);
        this.rows.splice(index, 1);
      });

      this.handleSelect();
    },
    handleSelect() {
      document
        .querySelectorAll(`input[name=rowCheck]`)
        .forEach((el) => (el.checked = false));

      this.selected = 0;
    },
    selectAll() {
      document
        .querySelectorAll(`input[name=rowCheck]`)
        .forEach((el) => (el.checked = true));
      var checkedBoxes = document.querySelectorAll(
        "input[name=rowCheck]:checked"
      );
      this.selected = checkedBoxes.length;
    },
    handleNewCreate() {
      if (this.$store.state.chargeNew.members.length == 0) {
        alert("You must select a member");
        return;
      }
      if (this.$store.state.amountNew > 200) {
        alert("You can only create 200 charges at a time");

        return;
      }

      // Get Amount
      // Create rows with that team and amount
      this.$store.state.chargeNew.members.forEach((memberid) => {
        let member = this.$store.state.members.find((member) => {
          return member.id == memberid;
        });
        let object = {
          member_id: memberid,
          member_name: member.name,
          type: this.$store.state.chargeNew.type.trim(),
          amount: this.$store.state.chargeNew.amount,
          category: this.$store.state.chargeNew.category.trim(),
          notes: this.$store.state.chargeNew.notes.trim(),
        };

        for (let index = 0; index < this.$store.state.amountNew; index++) {
          setTimeout(() => {
            this.createRow(object);
          }, index * 1);
        }
      });

      this.$store.state.chargeNew = {
        members: [],
        type: "",
        amount: "",
        category: "",
        notes: "",
      };
      this.$store.state.amountNew = 1;

      this.handleNew();
    },
    createRow(object) {
      if (
        this.rows.length == 1 &&
        this.rows[0].id == "default" &&
        this.rows[0].amount == ""
      ) {
        this.rows = [];
      }
      var uuid = Date.now().toString(36) + Math.random().toString(36);
      this.rows.push({
        id: uuid,
        member_id: object.member_id,
        member_name: object.member_name,
        type: object.type,
        amount: object.amount,
        category: object.category,
        notes: object.notes,
      });
    },
    check(event, id) {
      if (event.target instanceof HTMLInputElement) return;
      document.getElementById(id).checked =
        !document.getElementById(id).checked;
      var checkedBoxes = document.querySelectorAll(
        "input[name=rowCheck]:checked"
      );
      this.selected = checkedBoxes.length;
    },
    singleCheck() {
      var checkedBoxes = document.querySelectorAll(
        "input[name=rowCheck]:checked"
      );
      this.selected = checkedBoxes.length;
    },
    changeMember(id, index) {
      let member = this.$store.state.members.find((member) => {
        return member.id == id;
      });
      this.rows[index].member_name = member.name;
    },
  },
  mounted() {
    load_members();
    load_fc();
  },
  created() {
    onBeforeMount(async () => {
      if (!this.$store.state.login.logged) {
        router.replace("/login");
      }

      if (
        !this.$store.state.allowedRoles.match.includes(
          this.$store.state.login.role
        )
      ) {
        router.replace("/");
      }
    });
  },
};
</script>

<style scoped>
input:invalid,
.empty {
  outline: 2px solid red;
}
.error {
  border: 2px solid rgba(255, 0, 0, 0.6) !important;
  color: red;
}
.error input:focus {
  outline: 0px solid black;
}
input:focus:invalid {
  color: red;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.contain {
  font-family: "Roboto", sans-serif;
  width: 100vw;
  height: 100vh;
  overflow-x: auto;
}

.hide {
  display: none !important;
}

.menu {
  background: #f4f4f4;
  display: flex;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #dedede;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 5000;
  padding: 0px 10px;
  overflow-x: auto;
  overflow-y: hidden;
}

.menu .left {
  display: flex;
  align-items: center;
}

.menu .import {
  margin: 5px 5px;
  align-items: center;
  padding: 5px 15px;
  background: var(--blue);
  color: white;
  opacity: 0.4;
  border-radius: 5px;
  height: 35px;
  display: flex;
  justify-content: center;
  white-space: nowrap;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.menu .icon {
  margin: 5px 5px;
  align-items: center;
  padding: 5px 15px;
  background: rgb(232, 232, 232);
  opacity: 0.4;
  border-radius: 5px;
  height: 35px;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.menu .new {
  cursor: pointer;
  opacity: 1;
}

.menu img {
  width: 35px;
  height: 35px;
}
.activated {
  opacity: 1 !important;
  cursor: pointer;
}

.menu .selection {
  cursor: pointer;
}
.menu .selection #select {
  display: inline-block;
  margin-right: 4px;
}

.menu .selection:hover #select {
  display: none;
}

.menu .selection #deselect {
  display: none;
}

.menu .selection:hover #deselect {
  display: inline-block;
  margin-right: 4px;
}

.hovering {
  background: red !important;
  color: white !important;
}

.pick {
  background: red !important;
  opacity: 1 !important;
}

.table {
  margin-top: 50px;
  width: 100%;
  overflow-y: scroll;
}

td div {
  margin: auto;
}

.col-1 {
}
.col-2 {
}
.col-3 {
  width: 10%;
}
.col-4 {
}
.col-5 {
}
.col-6 {
}
.col-7 {
}
.col-8 {
}
.col-9 {
}
.col-10 {
}
.col-11 {
}

.table-header {
  font-size: 18px;
  align-items: center;
  display: flex;
  height: 43px;
  justify-content: flex-start;
}

th {
  text-transform: uppercase;
  opacity: 0.4;
  font-weight: bold;
  padding: 5px;
  letter-spacing: -0.08em;
}

table,
th,
td {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  border-collapse: collapse;
}

tr {
  height: 43px;
}

tr:nth-child(odd) {
  background: #f9f9f9;
}

.cell div {
  width: 100%;
  padding: 5px 10px;
}

input[type="checkbox"] {
  margin: 5px;
}

.buttons button {
  width: 100px;
  height: 40px;
  color: white;
  border-radius: 5px;
  font-size: large;
  margin: 10px;
}

.buttons .create {
  background: var(--blue);
}

.buttons .cancel {
  background: #c9c9c9;
}

td /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
td input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
td input[type="number"] {
  width: 100%;
  -moz-appearance: textfield;
}

td select {
  border-radius: 3px;
  padding: 2px 5px;
}

.input {
  border-radius: 5px;
  border: 1px solid rgb(209 213 219);
  padding: 0.5rem;
  font-size: 0.875rem;
  width: 100%;
}

select {
  /* reset */
  padding-right: 40px !important;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
}

.time {
  width: 120px;
}

.date {
  width: 155px !important;
  padding: 0px 0px !important;
}

#upload-file {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: #f4f4f49d;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.assigning {
  position: relative;
  padding: 30px 20px;
  border-radius: 5px;
  background: var(--blue);
  margin: 0px 10px;
  overflow: hidden;
}

.assigning .bar {
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}

.assigning .bar h3 {
  font-size: x-large;
  font-weight: bold;
}

.assigning .bar .buttons {
  display: flex;
}

.assigning .bar .buttons button {
  width: fit-content;
  padding: 0 10px;
  font-weight: 600;
}

.assigning .bar .buttons .add {
  background: white;
  color: var(--blue);
}
.assigning .bar .buttons .exit {
  border: 2px solid white;
}

.assigning .columns {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 15px;
  opacity: 0.98;
}

.assigning .columns h4 {
  color: white;
  font-size: larger;
  font-weight: 600;
  text-align: left;
  margin-bottom: 10px;
}

.assigning .columns select {
  margin: 0px 2px;
  font-size: 1em;
  font-weight: 600;
}

.assigning .columns .data-preview {
  color: #f4f4f4;
  font-weight: 600;
  text-align: left;
  margin-top: 10px;
  margin-left: 5px;
  font-size: 1em;
  border-right: 1px solid var(--grey);
  height: 125px;
  width: 180px;
  overflow: hidden;
}

.contain .help {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.help .box {
  position: absolute;
  z-index: 1;
  max-width: 500px;
  background: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 30px;
}
.help .box .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.help .box .title {
  font-size: x-large;
  font-weight: 600;
}

.help .box .text {
  text-align: left;
}
.help .box .text ol {
  margin: 15px 0px;
}
.help .box .text ul {
  margin-left: 30px;
}
.help h2 {
  font-size: large;
  font-weight: 600;
}
</style>
