import axios from "axios";
import store from "@/store/index";

function load_comps() {
  let jsonData = {
    url: store.state.login.siteurl,
  };
  axios
    .post("api/load_comps", jsonData, {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      let comps = response.data.comps;
      comps.sort(function (a, b) {
        if (a["1"] < b["1"]) {
          return -1;
        }
        if (a["1"] > b["1"]) {
          return 1;
        }

        return 0;
      });

      store.state.competitions = comps.map((comp) => {
        return comp["1"];
      });
      store.state.competitions.unshift("");
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
}
export default load_comps;
