import axios from "axios";
import store from "@/store/index";

async function uploadTraining(training) {
  try {
    training.forEach((t, i) => {
      setTimeout(async () => {
        store.dispatch("updateUploadingPercentage", {
          current: i,
          total: training.length,
        });

        let jsonData = {
          url: store.state.login.siteurl,
          t,
        };
        await axios.post("api/training/add", jsonData, {
          headers: { "Content-Type": "application/json" },
        });

        if (i == training.length - 1) {
          return "Done";
        }
      }, i * 1000);
    });
    return "Done";
  } catch (err) {
    // Handle Error Here
    console.error(err);
    return "Error";
  }
}
export default uploadTraining;
