import axios from "axios";
import store from "@/store/index";

function change(members, category) {

    // Change on a WordPress level fisrt

    members.forEach((member, i) => {
        setTimeout(async() => {
            let jsonData = {
                url: store.state.login.siteurl,
                member,
                category,
            };
            axios
                .post("api/category_assignment/change", jsonData, {
                    headers: { "Content-Type": "application/json" },
                })
                .catch((error) => {
                    console.error("There was an error!", error);
                });

        }, i * 750);
    });


    // Change in the members array.

    members.forEach(m => {
        let index = store.state.members.findIndex((member) => member.id == m)
        if (index >= 0) {
            store.state.members[index]["mc"] = category.toLowerCase()
        }
    });


    if (store.state.category1_info.name) {
        store.state.category1_info.members = store.state.members.filter(
            (member) => member["mc"] == store.state.category1_info.name.toLowerCase()
        );
    }

    if (store.state.category2_info.name) {
        store.state.category2_info.members = store.state.members.filter(
            (member) => member["mc"] == store.state.category2_info.name.toLowerCase()
        );
    }
}
export default change;