import axios from "axios";
// import store from "@/store/index";

async function checkHash(hash, userid, siteurl) {
  let jsonData = {
    hash,
    userid,
    siteurl,
  };

  let response = await axios.post("api/checkHash", jsonData, {
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
}
export default checkHash;
