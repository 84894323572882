<template>
  <div class="contain">
    <div v-if="showHelp" class="help">
      <div class="box">
        <div class="header">
          <div class="title">FAQ</div>
          <font-awesome-icon
            size="lg"
            icon="circle-xmark"
            style="cursor: pointer"
            @click="handleHelp"
          />
        </div>
        <div class="text">
          <ol>
            <li>
              - How do i make a family account?
              <ul>
                <li>
                  If you reuse the same email as someone else in your membership
                  database, or on this page. Then it will automatically create a
                  family account.
                </li>
                <li>
                  To confirm that a family account will be used, the row will
                  turn blue.
                </li>
              </ul>
            </li>
          </ol>
          <a target="_blank" href="https://help.clubbuzz2.co.uk/members-bulk"
            >Watch our video help guide here</a
          >
        </div>
      </div>
    </div>
    <div v-if="this.assigning" class="overlay">
      <div class="assigning">
        <div class="bar">
          <h3>Assign Columns</h3>
          <div class="buttons">
            <button class="add" @click="handleAdd">Add</button>
            <button class="exit" @click="handleExit">Cancel</button>
          </div>
        </div>
        <div class="columns">
          <div class="column-{{i}}" v-for="(c, i) in column" :key="i">
            <h4>Column {{ i.toUpperCase() }}</h4>
            <select
              class="input"
              v-model="c.selected"
              :class="{
                error: this.columnDuplicates.has(c.selected),
              }"
            >
              <option value=""></option>
              <option
                v-for="(column, index) in columns"
                :value="column"
                :key="index"
              >
                {{ column }}
              </option>
              <option
                v-for="(custom_col, i) in $store.state.customFields"
                :value="custom_col.name"
                :key="i"
              >
                {{ custom_col.name }}
              </option>
            </select>
            <div class="data-preview">
              <div v-for="(text, i) in c.preview" :key="i">
                {{ text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UploadingBox text="Members" />
    <div class="menu">
      <div class="left">
        <router-link to="/">
          <img src="@/assets/logo.png" alt="" />
        </router-link>
        <div class="icon new" @click="handleNew">
          New
          <font-awesome-icon style="margin-left: 4px" icon="plus" />
        </div>

        <div
          class="icon"
          @click="handleDelete"
          :class="{ activated: selected > 0 }"
        >
          Delete
          <font-awesome-icon style="margin-left: 4px" icon="trash-can" />
        </div>
        <div
          class="icon selection"
          :class="{ activated: selected > 0 }"
          @click="handleSelect"
        >
          <span id="select">{{ selected }} Selected</span>
          <span id="deselect">Deselect all</span>
          <font-awesome-icon icon="check" />
        </div>
        <div
          class="icon paste"
          @click="handlePaste"
          :class="{ activated: true }"
        >
          Paste
          <font-awesome-icon style="margin-left: 4px" icon="paste" />
        </div>

        <label for="upload-file">
          <div class="icon paste" :class="{ activated: true }">
            Upload CSV
            <font-awesome-icon style="margin-left: 4px" icon="upload" />
          </div>
        </label>
        <input
          type="file"
          name="image"
          id="upload-file"
          @change="handleCSV"
          ref="myFiles"
          accept=".csv"
        />
      </div>
      <div class="right">
        <div class="icon activated" @click="handleHelp">
          Help
          <font-awesome-icon style="margin-left: 4px" icon="circle-question" />
        </div>
        <div
          class="import"
          @click="handleImport"
          :class="{ activated: this.rows.length > 0 }"
        >
          Import Members
          <font-awesome-icon style="margin-left: 4px" icon="upload" />
        </div>
      </div>
    </div>
    <NewMemberBox :handleNew="handleNew" :handleNewCreate="handleNewCreate" />
    <table class="table">
      <thead>
        <!-- Blank Header for Checkmark -->
        <th>
          <font-awesome-icon
            style="margin: 4px; cursor: pointer"
            icon="check-circle"
            @click="selectAll"
          />
        </th>

        <!-- Static Column Headers -->
        <th :class="get_col_class(i)" v-for="(column, i) in columns" :key="i">
          {{ column }}
        </th>

        <!-- Custom Field Column Headers -->
        <th
          :class="get_col_class(i, true)"
          v-for="(column, i) in $store.state.customFields"
          :key="i"
        >
          {{ column.name }}
        </th>
      </thead>
      <tbody>
        <!-- Loop through rows for data -->
        <tr
          v-for="(row, i) in this.rows"
          :key="i"
          :class="{ family: isFamily(row.email, row.id).family }"
        >
          <td @click="check($event, row.id)">
            <input
              type="checkbox"
              name="rowCheck"
              :id="row.id"
              @change="singleCheck()"
            />
          </td>
          <td
            class="cell col-1"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 1),
            }"
          >
            <div>
              <input
                class="input"
                type="text"
                name=""
                id=""
                v-model="row.first"
              />
            </div>
          </td>
          <td
            class="cell col-2"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 2),
            }"
          >
            <div>
              <input
                class="input"
                type="text"
                name=""
                id=""
                v-model="row.last"
              />
            </div>
          </td>
          <td
            class="cell col-3"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 3),
            }"
          >
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: small;
                font-weight: bold;
              "
              v-if="isFamily(row.email, row.id).editable"
            >
              <input
                class="input"
                type="text"
                name=""
                id=""
                v-model="row.username"
              />
            </div>
            <div
              v-else
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: small;
                font-weight: bold;
              "
            >
              {{ isFamily(row.email, row.id).username }}
            </div>
          </td>
          <td
            class="cell col-4"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 4),
            }"
          >
            <div>
              <input
                class="input"
                type="text"
                name=""
                id=""
                v-model="row.email"
              />
            </div>
          </td>
          <td
            class="cell col-5"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 5),
            }"
          >
            <div class="">
              <select class="input" name="" id="" v-model="row.gender">
                <option value=""></option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </td>
          <td
            class="cell col-6"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 6),
            }"
          >
            <div class="date">
              <input
                class="input time"
                type="date"
                v-model="row.dob"
                name=""
                id=""
              />
            </div>
          </td>
        <td
            class="cell col-7"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 7),
            }"
          >
            <div>
              <input
                class="input"
                type="text"
                name=""
                id=""
                v-model="row.mobile"
              />
            </div>
          </td>
        <td
            class="cell col-8"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 8),
            }"
          >
            <div>
              <input
                class="input"
                type="text"
                name=""
                id=""
                v-model="row.address"
              />
            </div>
          </td>
        <td
            class="cell col-9"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 9),
            }"
          >
            <div>
              <input
                class="input"
                type="text"
                name=""
                id=""
                v-model="row.city"
              />
            </div>
          </td>
        <td
            class="cell col-10"
            :class="{
              error: this.errors.some(({ line, col }) => line == i && col == 10),
            }"
          >
            <div>
              <input
                class="input"
                type="text"
                name=""
                id=""
                v-model="row.postcode"
              />
            </div>
          </td>
          <!-- Custom Field Data -->
          <td
            class="cell"
            :class="get_col_class(i, true)"
            v-for="(column, j) in this.$store.state.customFields"
            :key="j"
          >
            <div v-if="column.type == 'text'">
              <input
                class="input"
                type="text"
                v-model="row.customFieldValues[j].value"
              />
            </div>
            <div v-else-if="column.type == 'options'">
              <select
                class="input"
                name=""
                id=""
                v-model="row.customFieldValues[j].value"
              >
                <option value=""></option>
                <option
                  v-for="(option, k) in column.options"
                  :key="k"
                  :value="option"
                >
                  {{ option }}
                </option>
              </select>
            </div>
            <div v-else class="date">
              <input
                class="input time"
                type="date"
                v-model="row.customFieldValues[j].value"
                name=""
                id=""
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import router from "@/router/index";
import { onBeforeMount, ref } from "vue";

import UploadingBox from "@/components/UploadingBox.vue";
import NewMemberBox from "@/components/member/NewMemberBox.vue";
import load_members from "@/requests/load_members";
import uploadMembers from "@/requests/members/UploadMembers";
import load_cf from "@/requests/members/load_cf";

import csvToArray from "@/csvToArray";

export default {
  name: "MemberView",
  setup() {
    const formatter = ref({
      date: "DD/MM/YYYY",
      month: "MMM",
    });

    return {
      formatter,
    };
  },
  components: {
    UploadingBox,
    NewMemberBox,
  },
  data() {
    return {
      selected: 0,
      rows: [
        {
          id: "default",
          first: "",
          last: "",
          username: "",
          email: "",
          gender: "",
          mobile: "",
          address: "",
          city: "",
          postcode: "",
          customFieldValues: [
            { id: "", value: "" },
            { id: "", value: "" },
            { id: "", value: "" },
            { id: "", value: "" },
            { id: "", value: "" },
            { id: "", value: "" },
            { id: "", value: "" },
            { id: "", value: "" },
            { id: "", value: "" },
            { id: "", value: "" },
            { id: "", value: "" },
            { id: "", value: "" },
            { id: "", value: "" },
            { id: "", value: "" },
            { id: "", value: "" },
            { id: "", value: "" },
          ],
        },
      ],
      columns: [
        "First",
        "Last",
        "Username",
        "Email",
        "Gender",
        "Date of Birth",
        "Mobile Phone",
        "Address",
        "City",
        "Postcode",
      ],
      column: {
        '0': { selected: "First", preview: [] },
        '1': { selected: "Last", preview: [] },
        '2': { selected: "Username", preview: [] },        
        '3': { selected: "Email", preview: [] },
        '4': { selected: "Gender", preview: [] },
        '5': { selected: "Date of Birth", preview: [] },
        '6': { selected: "Mobile Phone", preview: [] },
        '7': { selected: "Address", preview: [] },
        '8': { selected: "City", preview: [] },
        '9': { selected: "Postcode", preview: [] },
      },
      errors: [],
      assigning: false,
      convertedRows: [],
      columnDuplicates: new Set(),
      showHelp: false,
    };
  },

  computed: {},
  methods: {
    handleHelp() {
      this.showHelp = !this.showHelp;
    },
    isFamily(check_email, check_id) {
      // Check rows for duplicate emails to make a family account
      let object = {
        family: false,
        username: "",
        userid: "",
        editable: true,
      };
      if (check_email == "") {
        return object;
      }
      if (
        this.rows.some(({ id, email }) => {
          return (
            id != check_id && email.toLowerCase() == check_email.toLowerCase()
          );
        })
      ) {
        object.family = true;

        // Find which one is first in the list
        // Get current index
        let c_index = this.rows.findIndex((row) => row.id == check_id);

        // Get other index
        let n_index = this.rows.findIndex((row) => {
          return check_email == row.email && row.id != check_id;
        });

        // If current index first, let it edit
        if (c_index < n_index) {
          object.editable = true;
        } else {
          object.editable = false;
          if (this.rows[n_index]) {
            object.username = this.rows[n_index].username;
          }
        }
      }

      // Check Member Database to see if emails exist
      if (
        this.$store.state.members.some(({ email }) => {
          return email.toLowerCase() == check_email.toLowerCase();
        })
      ) {
        object.family = true;
        object.editable = false;

        // Get the Member related
        let member = this.$store.state.members.find((member) => {
          return (
            member.email.toLowerCase() == check_email.toLowerCase() &&
            member.username != ""
          );
        });

        object.username = member.username;
        object.userid = member.userid;
      }
      // If nothing exists return false

      return object;

      // return object with if true, username and user_id and whether username should be editable
    },
    get_col_class(i, custom = false) {
      if (custom) {
        return `custom-col-${i + 1}`;
      }
      return `col-${i + 1}`;
    },
    changeTeam(teamid, i) {
      let team = this.$store.state.teams.find((team) => team.id == teamid);
      this.rows[i].team = team.name;
    },
    handleCSV() {
      if (this.$refs.myFiles) {
        let file = this.$refs.myFiles.files[0];

        const reader = new FileReader();
        reader.onload = (e) => {
          let converted = [];
          let str = e.target.result;
          const result = csvToArray(str);
          // const result = str.split(/\r?\n/);

          result.forEach((row) => {
            // let row = line.split(",");

            for (let index = 0; row.length < 10; index++) {
              row.push("");
            }

            if (JSON.stringify(row) != '["","","","","",""]') {
              converted.push(row);
            }
          });

          this.handleAssign(converted);
          // converted.forEach((row) => this.processRow(row, order));
        };
        reader.readAsText(file);
      }

      document.getElementById("upload-file").value = null;
    },
    processRow(convertedRow) {
      let firstObj = convertedRow.find(el => el.order === 0)
      let first = firstObj ? firstObj.value.trim() : "";
      // if (convertedRow.length > 0) {
      //   var first = convertedRow[0].value.trim();
      // }

      let lastObj = convertedRow.find(el => el.order === 1)
      let last = lastObj ? lastObj.value.trim() : "";

      let usernameObj = convertedRow.find(el => el.order === 2)
      let username = usernameObj ? usernameObj.value.trim() : "";

      let emailObj = convertedRow.find(el => el.order === 3)
      let email = emailObj ? emailObj.value.trim() : "";

      let genderObj = convertedRow.find(el => el.order === 4)
      let gender = genderObj ? genderObj.value.trim() : "";


      let dateObj = convertedRow.find(el => el.order === 5)
      let dob = "";

      if (dateObj) {
        try {
          var parts = dateObj.value.split("/");
          var d1 = new Date(
            Number(parts[2]),
            Number(parts[1]) - 1,
            Number(parts[0])
          );
          var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
          dob = new Date(d1 - tzoffset).toISOString().split("T")[0];
        } catch (error) {
          dob = "";
        }
      }

      let mobileObj = convertedRow.find(el => el.order === 6)
      let mobile = mobileObj ? mobileObj.value.trim() : "";

      let addressObj = convertedRow.find(el => el.order === 7)
      let address = addressObj ? addressObj.value.trim() : "";

      let cityObj = convertedRow.find(el => el.order === 8)
      let city = cityObj ? cityObj.value.trim() : "";

      let postcodeObj = convertedRow.find(el => el.order === 9)
      let postcode = postcodeObj ? postcodeObj.value.trim() : "";

      let customFieldValues = [];

      for (let i = 0; i < this.$store.state.customFields.length; i++) {
        var value = "";

        let obj = convertedRow.find(el => el.order == 10 + i)
        if (obj) {
          value = obj.value
          if (this.$store.state.customFields[i].type == "date") {
            try {
              var cfParts = obj.value.split("/");
              var cfD1 = new Date(
                Number(cfParts[2]),
                Number(cfParts[1]) - 1,
                Number(cfParts[0])
              );
              var tz = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
              value = new Date(cfD1 - tz).toISOString().split("T")[0];
            } catch (error) {
              value = "";
            }
          } else {
            value = obj.value;
          }
        }


        customFieldValues.push({
          id: this.$store.state.customFields[i].id,
          value: value,
        });
      }
      let object = {
        first,
        last,
        username,
        email,
        gender,
        dob: ref(dob),
        mobile,
        address,
        city,
        postcode,
        customFieldValues,
      };

      this.createRow(object);
    },
    async handleImport() {
      // Time Regex
      const emailRegex = new RegExp(
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

      this.errors = [];
      // For Each Row

      for (let i = 0; i < this.rows.length; i++) {
        const row = this.rows[i];
      
        if (row.first.trim().length < 1) {
          this.errors.push({
            line: i,
            col: 1,
            message: `Please specify a first name on line ${i + 1}`,
          });
        }
        // Check Opponent
        if (row.last.trim().length < 1) {
          this.errors.push({
            line: i,
            col: 2,
            message: `Please specify a last name on line ${i + 1}`,
          });
        }

        // Check Date

        if (
          row.username.trim().length < 5 &&
          !this.isFamily(row.email, row.id).family
        ) {
          this.errors.push({
            line: i,
            col: 3,
            message: `Please specify a username on line ${i + 1}`,
          });
        }

        var usernameTestRegex = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

        if (usernameTestRegex.test(row.username)) {
          this.errors.push({
            line: i,
            col: 3,
            message: `Please specify a valid username on line ${i + 1}`,
          });
        }

        // Check Email
        if (!emailRegex.test(row.email)) {
          this.errors.push({
            line: i,
            col: 4,
            message: `You have entered an email in an incorrect format, please make sure to use a registered email on line ${i + 1}`,
          });
        }

        // Check Gender
        if (
          row.gender != "" &&
          row.gender != "Male" &&
          row.gender != "Female" &&
          row.gender != "Other"
        ) {
          this.errors.push({
            line: i,
            col: 5,
            message: `Please reselect the gender on line ${i + 1}`,
          });
        }
        

        // Check if row should be family
        let family = this.isFamily(row.email, row.id);
        row.family = family;
        if (!family.editable) {
          row.userid = family.userid;
          row.username = family.username;
        }
      }

      if (this.errors.length > 0) {
        alert(this.errors[0].message)
        return
      }

      if (this.errors.length == 0) {
        this.$store.state.uploading = true;
        let response = await uploadMembers(this.rows);
        if (response.error) {
          alert(`Error: ${response.message}`)
            this.$store.state.uploading = false;
        } else {
            this.$store.state.uploading = false;
            this.rows = [];
            let obj = {
              id: "default",
              first: "",
              last: "",
              username: "",
              email: "",
              gender: "",
              address: "",
              city: "",
              postcode: "",
              customFieldValues: [],
            };
            for (let i = 0; i < this.$store.state.customFields.length; i++) {
              obj.customFieldValues.push({
                id: this.$store.state.customFields[i].id,
                value: "",
              });
            }
  
            this.rows = [obj];

        }
      }
    },
    async handlePaste() {
      try {
        const clipboardContents = await navigator.clipboard.read();
        for (const item of clipboardContents) {
          if (!item.types.includes("text/html")) {
            alert("Paste only supports Copying from Excel");
            throw new Error("Clipboard contains non-image data.");
          }
          const blob = await item.getType("text/html");
          const str = await blob.text();

          var parser = new DOMParser();
          var doc = parser.parseFromString(str, "text/html");
          let table = doc.body;
          var rows = table.getElementsByTagName("table")[0].rows;
          // var last = rows[rows.length - 1];
          let converted = [];

          for (let r of rows) {
            let cells = r.getElementsByTagName("td");
            let convertedRow = [];
            for (let c of cells) {
              if (c.innerText) {
                convertedRow.push(c.innerText);
              } else {
                convertedRow.push("");
              }
            }

            for (let index = 0; convertedRow.length < 11; index++) {
              convertedRow.push("");
            }
            converted.push(convertedRow);
          }

          this.handleAssign(converted);

          // converted.forEach((row) => this.processRow(row, order));

          // var cell = last.cells[0];
          // var value = cell.innerHTML;

          // destinationImage.src = URL.createObjectURL(blob);
        }
      } catch (error) {
        if (error.message == "navigator.clipboard.read is not a function") {
          alert("This browser does not support Paste.");
        } else {
          console.log(error);
          console.error(error.message);
        }
      }
    },
    // Handle Paste/Upload > Send Rows to Handle Assign > Add puts them in table
    handleAssign(convertedRows) {
      let top = convertedRows.slice(0, 5);
      top.forEach((row) => {
        for (let i = 0; i < row.length; i++) {
          try {
            if (row[i]) {
              this.column[i].preview.push(row[i]);
            } else {
              this.column[i].preview.push("-");
            }
          } catch {
            //
          }
        }
      });

      this.assigning = true;
      this.convertedRows = convertedRows;
    },
    handleAdd() {
      this.columnDuplicates = new Set();
      // Make sure all columns are unique
      for (const key in this.column) {
        for (const secondaryKey in this.column) {

          if (
            key != secondaryKey &&
            this.column[key].selected == this.column[secondaryKey].selected
          ) {
            if (this.column[key].selected !== "") {
              this.columnDuplicates.add(this.column[key].selected);
            }
          }
        }
      }

      if (this.columnDuplicates.size > 0) {
        alert("All columns must be unique");
        return;
      }

      // Get Order Values for the row
      let arrayWithOrderValues = this.convertedRows.map((row) => {
        // Check max index
        var maxIndex = Object.keys(this.column).length - 1;
        
        row.forEach((value, index) => {
          if (index <= maxIndex) {
            let order = this.getOrderValue(index);
            row[index] = {
              value,
              order,
            };
          }
        });
        return row;
      });


      // Sort rows on the order values
      arrayWithOrderValues.forEach((row) => {
        row.sort(function (x, y) {
          if (x.order < y.order) {
            return -1;
          }
          if (x.order > y.order) {
            return 1;
          }
          return 0;
        });
      });

      arrayWithOrderValues.forEach((row) => this.processRow(row));
      this.assigning = false;
      this.convertedRows = [];
      this.resetColumn();
    },
    handleExit() {
      this.assigning = false;
      this.convertedRows = [];
      this.resetColumn();
    },
    resetColumn() {
      this.column = {
        '0': { selected: "First", preview: [] },
        '1': { selected: "Last", preview: [] },
        '2': { selected: "Username", preview: [] },
        '3': { selected: "Email", preview: [] },
        '4': { selected: "Gender", preview: [] },
        '5': { selected: "Date of Birth", preview: [] },
        '6': { selected: "Mobile Phone", preview: [] },
        '7': { selected: "Address", preview: [] },
        '8': { selected: "City", preview: [] },
        '9': { selected: "Postcode", preview: [] },
      };

      let coreCount = Object.keys(this.column).length

      for (let i = coreCount; i < this.$store.state.customFields.length + coreCount; i++) {

        this.column[i] = {
          // selected: this.$store.state.customFields[i].name,
          selected: "",
          preview: [],
        };
      }
    },
    getOrderValue(index) {

      return this.getColumnValue(this.column[`${index}`].selected);
    },
    getColumnValue(selected) {
      var index;

      this.columns.forEach((element, i) => {
        if (element == selected) {
          index = i;
        }
      });
      this.$store.state.customFields.forEach((element, i) => {
        if (element.name == selected) {
          index = i + 10;
        }
      });

      return index === undefined ? 999 : index;
    },
    handleNew() {
      this.$store.state.showNew = !this.$store.state.showNew;
    },
    handleDelete() {
      document.querySelectorAll(`input[name=rowCheck]:checked`).forEach((e) => {
        let index = this.rows.findIndex((row) => row.id == e.id);
        this.rows.splice(index, 1);
      });

      this.handleSelect();
    },
    handleSelect() {
      document
        .querySelectorAll(`input[name=rowCheck]`)
        .forEach((el) => (el.checked = false));

      this.selected = 0;
    },
    selectAll() {
      document
        .querySelectorAll(`input[name=rowCheck]`)
        .forEach((el) => (el.checked = true));
      var checkedBoxes = document.querySelectorAll(
        "input[name=rowCheck]:checked"
      );
      this.selected = checkedBoxes.length;
    },
    handleNewCreate() {
      if (!this.$store.state.memberNew.first) {
        alert("You must choose a first name");
        return;
      }
      if (!this.$store.state.memberNew.last) {
        alert("You must choose a last name");
        return;
      }

      if (!this.$store.state.memberNew.email) {
        alert("You must choose an email");
        return;
      }

      // Create rows with that team and amount
      let object = {
        first: this.$store.state.memberNew.first.trim(),
        last: this.$store.state.memberNew.last.trim(),
        username: this.$store.state.memberNew.username.trim(),
        email: this.$store.state.memberNew.email.trim(),
        gender: this.$store.state.memberNew.gender.trim(),
        dob: this.$store.state.memberNew.dob,
        mobile: "",
        address: "",
        city: "",
        postcode: "",
        customFieldValues: [],
      };

      for (let i = 0; i < this.$store.state.customFields.length; i++) {
        object.customFieldValues.push({
          id: this.$store.state.customFields[i].id,
          value: "",
        });
      }

      this.createRow(object);

      this.$store.state.memberNew = {
        first: "",
        last: "",
        username: "",
        email: "",
        gender: "",
        dob: ref(""),
      };

      this.handleNew();
    },
    createRow(object) {
      if (
        this.rows.length == 1 &&
        this.rows[0].id == "default" &&
        this.rows[0].username == "" &&
        this.rows[0].email == ""
      ) {
        this.rows = [];
      }

      var uuid = Date.now().toString(36) + Math.random().toString(36);

      this.rows.push({
        id: uuid,
        first: object.first,
        last: object.last,
        username: object.username,
        email: object.email,
        gender: object.gender,
        dob: object.dob,
        mobile: object.mobile,
        address: object.address,
        city: object.city,
        postcode: object.postcode,
        customFieldValues: object.customFieldValues,
      });
    },
    check(event, id) {
      if (event.target instanceof HTMLInputElement) return;
      document.getElementById(id).checked =
        !document.getElementById(id).checked;
      var checkedBoxes = document.querySelectorAll(
        "input[name=rowCheck]:checked"
      );
      this.selected = checkedBoxes.length;
    },
    singleCheck() {
      var checkedBoxes = document.querySelectorAll(
        "input[name=rowCheck]:checked"
      );
      this.selected = checkedBoxes.length;
    },
  },
  async mounted() {
    let cf_amount = await load_cf();
    let arr = [];
    for (let i = 0; i < cf_amount; i++) {
      arr.push({
        id: this.$store.state.customFields[i].id,
        value: "",
      });
      var char = (i + 10);
      this.column[char] = {
        // selected: this.$store.state.customFields[i].name,
        selected: '',
        preview: [],
      };
    }

    // Go through each occurance of column.a and figure out how to conform with CFs
    this.rows[0].customFieldValues = arr;
    load_members();
  },
  created() {
    onBeforeMount(async () => {
      if (!this.$store.state.login.logged) {
        router.replace("/login");
      }

      if (
        !this.$store.state.allowedRoles.match.includes(
          this.$store.state.login.role
        )
      ) {
        router.replace("/");
      }
    });
  },
};
</script>

<style scoped>
input:invalid,
.empty {
  outline: 2px solid red;
}
.error {
  border: 2px solid rgba(255, 0, 0, 0.6) !important;
  color: red;
}
.error input:focus {
  outline: 0px solid black;
}
input:focus:invalid {
  color: red;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.contain {
  font-family: "Roboto", sans-serif;
  width: 100vw;
  height: 100vh;
  overflow-x: auto;
}

.hide {
  display: none !important;
}

.menu {
  background: #f4f4f4;
  display: flex;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #dedede;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 5000;
  padding: 0px 10px;
  overflow-x: auto;
  overflow-y: hidden;
}

.menu .left,
.menu .right {
  display: flex;
  align-items: center;
}

.menu .import {
  margin: 5px 5px;
  align-items: center;
  padding: 5px 15px;
  background: var(--blue);
  color: white;
  opacity: 0.4;
  border-radius: 5px;
  height: 35px;
  display: flex;
  justify-content: center;
  white-space: nowrap;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.menu .icon {
  margin: 5px 5px;
  align-items: center;
  padding: 5px 15px;
  background: rgb(232, 232, 232);
  opacity: 0.4;
  border-radius: 5px;
  height: 35px;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.menu .new {
  cursor: pointer;
  opacity: 1;
}

.menu img {
  width: 35px;
  height: 35px;
}
.activated {
  opacity: 1 !important;
  cursor: pointer;
}

.menu .selection {
  cursor: pointer;
}
.menu .selection #select {
  display: inline-block;
  margin-right: 4px;
}

.menu .selection:hover #select {
  display: none;
}

.menu .selection #deselect {
  display: none;
}

.menu .selection:hover #deselect {
  display: inline-block;
  margin-right: 4px;
}

.hovering {
  background: red !important;
  color: white !important;
}

.pick {
  background: red !important;
  opacity: 1 !important;
}

.table {
  margin-top: 50px;
  width: 100%;
  overflow-y: scroll;
}

td div {
  margin: auto;
}

.col-1 {
}
.col-2 {
}
.col-3 {
}
.col-4 {
}
.col-5 {
}
.col-6 {
}

th {
  text-transform: uppercase;
  opacity: 0.4;
  font-weight: bold;
  padding: 5px;
  letter-spacing: -0.08em;
}

table,
th,
td {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  border-collapse: collapse;
}

tr {
  height: 43px;
}

tr:nth-child(odd) {
  background: #f9f9f9;
}

.cell div {
  width: fit-content;
  padding: 5px 10px;
}

input[type="checkbox"] {
  margin: 5px;
}

.buttons button {
  width: 100px;
  height: 40px;
  color: white;
  border-radius: 5px;
  font-size: large;
  margin: 10px;
}

.buttons .create {
  background: var(--blue);
}

.buttons .cancel {
  background: #c9c9c9;
}

td /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
td input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
td input[type="number"] {
  width: 50px;
  -moz-appearance: textfield;
}

td select {
  border-radius: 3px;
  padding: 2px 5px;
}

.input {
  border-radius: 5px;
  border: 1px solid rgb(209 213 219);
  padding: 0.5rem;
  font-size: 0.875rem;
}

select {
  /* reset */
  padding-right: 40px !important;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
}

.time {
  width: 120px;
}

.date {
  width: 155px !important;
  padding: 0px 0px !important;
}

#upload-file {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: #f4f4f49d;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.assigning {
  position: relative;
  padding: 30px 20px;
  border-radius: 5px;
  background: var(--blue);
  margin: 0px 10px;
  overflow: hidden;
}

.assigning .bar {
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}

.assigning .bar h3 {
  font-size: x-large;
  font-weight: bold;
}

.assigning .bar .buttons {
  display: flex;
}

.assigning .bar .buttons button {
  width: fit-content;
  padding: 0 10px;
  font-weight: 600;
}

.assigning .bar .buttons .add {
  background: white;
  color: var(--blue);
}
.assigning .bar .buttons .exit {
  border: 2px solid white;
}

.assigning .columns {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 15px;
  opacity: 0.98;
}

.assigning .columns h4 {
  color: white;
  font-size: larger;
  font-weight: 600;
  text-align: left;
  margin-bottom: 10px;
}

.assigning .columns select {
  margin: 0px 2px;
  font-size: 1em;
  font-weight: 600;
  max-width: 350px;
}

.assigning .columns .data-preview {
  color: #f4f4f4;
  font-weight: 600;
  text-align: left;
  margin-top: 10px;
  margin-left: 5px;
  font-size: 1em;
  border-right: 1px solid var(--grey);
  height: 125px;
  width: 100%;
  padding: 0px 5px;
  overflow: hidden;
}
.family {
  background: rgb(223, 235, 255) !important;
}

.contain .help {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.help .box {
  position: absolute;
  z-index: 1;
  max-width: 500px;
  background: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 30px;
}
.help .box .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.help .box .title {
  font-size: x-large;
  font-weight: 600;
}

.help .box .text {
  text-align: left;
}
.help .box .text ol {
  margin: 15px 0px;
}
.help .box .text ul {
  margin-left: 30px;
}
</style>
