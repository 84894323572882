<template>
  <div id="new_box" v-if="this.$store.state.showTeamSettings" class="grid-container">
    <div class="grid-1">
      <p class="description">When a member is added to a team, add them to all future match or training sessions for that specific team.</p>     
      <h4 @click="this.$store.state.teamSettings.addMatches = !addMatches" :class="{on: addMatches}"><font-awesome-icon icon="toggle-off" v-if="!addMatches"/> <font-awesome-icon icon="toggle-on" v-else /> Add to matches </h4>   
      <h4 @click="this.$store.state.teamSettings.addTraining = !addTraining" :class="{on: addTraining}"><font-awesome-icon icon="toggle-off" v-if="!addTraining"/><font-awesome-icon icon="toggle-on" v-else /> Add to training </h4>        
      <p class="description">When a member is remove from a team, remove them to all future match or training sessions for that specific team.</p>     
      <h4 @click="this.$store.state.teamSettings.deleteMatches = !deleteMatches" :class="{on: deleteMatches}"><font-awesome-icon icon="toggle-off" v-if="!deleteMatches"/> <font-awesome-icon icon="toggle-on" v-else /> Delete from matches </h4>        
      <h4 @click="this.$store.state.teamSettings.deleteTraining = !deleteTraining" :class="{on: deleteTraining}"><font-awesome-icon icon="toggle-off" v-if="!deleteTraining"/><font-awesome-icon icon="toggle-on" v-else />  Delete from training</h4>        
    </div>
    <div class="grid-4">
      <div class="buttons">
        <button class="cancel" @click="handleSettings">Close</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TeamSettingsBox",
  props: ["handleSettings"],
  data() {
    return {};
  },
  computed: {
    addMatches() {
      return this.$store.state.teamSettings.addMatches;
    },
    addTraining() {
      return this.$store.state.teamSettings.addTraining;
    },
    deleteMatches() {
      return this.$store.state.teamSettings.deleteMatches;
    },
    deleteTraining() {
      return this.$store.state.teamSettings.deleteTraining;
    },
  }
};
</script>

<style scoped>
#new_box h4 {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0px 5px;
  display: flex;
  align-items: center;
  opacity: 0.2;
  user-select: none;
  gap: 5px
}
#new_box h4:hover {
  cursor: pointer;
  opacity: 0.8;
}
#new_box h4.on {
  opacity: 1;
}

#new_box .buttons {
  width: 100%;
  padding: 10px 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.buttons button {
  width: 80px;
  height: 30px;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  margin: 10px;
}

.buttons .cancel {
  background: var(--blue);
}

.grid-1 {
  grid-area: left;
}

.grid-4 {
  grid-area: footer;
  display: flex;
}

.grid-container {
  position: absolute;
  z-index: 100;
  display: grid;
  grid-template-areas:
    "left middle right"
    "footer footer footer";
  gap: 10px;
  background-color: var(--grey);
  border-radius: 5px;
  text-align: left;
  padding: 10px;
  margin: 10px 30px 0px 550px;
  box-shadow: 0px 5px 10px rgb(0, 0, 0, 0.15);
}
.grid-container > div {
  text-align: center;
  padding: 0px 5px;
}
.description {
  max-width: 350px;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}
</style>