<template>
  <div id="dup_box" v-if="this.$store.state.showDuplicate">
    <h4>Multiply By</h4>
    <input type="number" name="" id="" v-model="amountDup" />
    <div class="buttons">
      <button class="create" @click="handleDuplicateCreate">Create</button>
      <button class="cancel" @click="handleDuplicate">Cancel</button>
    </div>
  </div>
</template>
<script>
export default {
  name: "DupChargeBox",
  data() {
    return {
      amountDup: "0",
    };
  },
  props: ["createRow", "handleSelect", "handleDuplicate", "rows"],
  methods: {
    handleDuplicateCreate() {
      document.querySelectorAll(`input[name=rowCheck]:checked`).forEach((e) => {
        let object = this.rows.find((row) => row.id == e.id);
        for (let index = 0; index < this.amountDup; index++) {
          this.createRow(object);
        }
      });

      this.handleSelect();
      // Create rows with that object and amount

      this.handleDuplicate();
    },
  },
};
</script>
<style scoped>
#dup_box {
  z-index: 1000;
  position: absolute;
  background: var(--grey);
  width: 200px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 10px;
  margin: 60px 230px;
}

#dup_box input {
  width: 100%;
  /* height: 30px; */
  border-radius: 5px;
  border: 1px solid rgb(209 213 219);
  padding: 0.5rem;
  font-size: 0.875rem;
}

#dup_box .buttons button {
  margin: 5px;
}

#dup_box h4 {
  text-align: left;
  font-size: large;
  font-weight: bold;
  margin: 10px 0px 5px;
}

#dup_box .buttons {
  width: 100%;
  padding: 10px 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.buttons button {
  width: 100px;
  height: 40px;
  color: white;
  border-radius: 5px;
  font-size: large;
  margin: 10px;
}

.buttons .create {
  background: var(--blue);
}

.buttons .cancel {
  background: #c9c9c9;
}
</style>