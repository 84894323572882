import axios from "axios";
import store from "@/store/index";

function load_categories() {
    let jsonData = {
        url: store.state.login.siteurl,
    };
    axios
        .post("api/load_categories", jsonData, {
            headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
            let categories = response.data.categories;
            categories.sort(function(a, b) {
                if (a["1"] < b["1"]) {
                    return -1;
                }
                if (a["1"] > b["1"]) {
                    return 1;
                }

                return 0;
            });

            store.state.categories = categories.map((category) => {
                // let members = category["members"].map((member) => {
                //     let d = new Date(member["dob"]);
                //     if (d != "Invalid Date") {
                //         d = d.toLocaleDateString('en-GB')
                //     } else {
                //         d = ""
                //     }
                //     return {
                //         id: member["id"],
                //         name: member["name"],
                //         dob: d,
                //         gender: member["gender"],
                //     };
                // });
                return {
                    id: category["id"],
                    name: category["1"],
                    members: []
                };
            });

            // Team Format - {id: "4",name: "Team 4", players: [{ id: "37", name: "4 Eye", dob: "08/08/1997", gender: "Male" }]}
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
}
export default load_categories;